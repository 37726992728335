import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import PageHeader from './PageHeader'

const Popup = ({ show, title, onClose, className, marginClass, global, customeStyle, fitContent, children }) => (
  <div
    className={`w-full h-full top-0 left-0 right-0 bottom-0 my-auto white flex flex-col justify-center items-center bg-gray-11 bg-opacity-50 z-10 ${!show ? 'hidden' : 'block'}  ${
      global ? 'fixed' : 'absolute'
    }`}
    style={{ backdropFilter: 'blur(5px)' }}
  >
    <div
      className={`absolute max-w-412 left-2 right-2 bg-white rounded-xl flex flex-col ${marginClass} mx-4 ${customeStyle ? 'p-6 overflow-auto' : customeStyle} ${className} `}
      style={{
        width: fitContent ? 'fit-content' : undefined,
      }}
    >
      {title ? <PageHeader label={title} /> : ''}
      {children}
      {onClose && (
        <div className="w-full flex justify-center mt-8 mb-6">
          <Button label="Okay" type="" textClass="px-12 text-white bg-black" onClick={() => onClose()} />
        </div>
      )}
    </div>
  </div>
)

Popup.defaultProps = {
  show: false,
  global: false,
  fitContent: false,
  title: 'Cancel my trade-in',
  className: '',
  customeStyle: '',
  marginClass: 'mt-48 mb-20',
  onClose: undefined,
}

Popup.propTypes = {
  show: PropTypes.bool,
  global: PropTypes.bool,
  fitContent: PropTypes.bool,
  title: PropTypes.string,
  customeStyle: PropTypes.string,
  className: PropTypes.string,
  marginClass: PropTypes.string,
  onClose: PropTypes.func,
}

export default Popup
