import React, { useState, useEffect } from 'react';
import Iconback from '../../Icons/icon-back'
import { getResource } from '../../utils/helper';
import { useHistory } from 'react-router-dom'

const TermsAndConditions = (props) => {
    const { resources, state, actions } = props
    const { termsAgreed } = state
    const history = useHistory();
    const navigateBack = () => {

        history.replace('/tradeinfo')
    }
 let point5 = "<p><span class='font-bold'>[for trade-in devices storing data]</span> agree to back up all files, photos and personal data on Your device, remove Your SIM card or memory card, and de-activate any passcodes, security locks and/or features before wiping Your device and posting it in to Asurion;</p>"
    return (
        <div className="px-p20">
            <div className="h-screen mx-auto">
                <div className="p-p14 mt-5 mx-auto max-w-3xl">
                    <p className="mt-1 inline-block float-left"><Iconback onClick={() => navigateBack()} /></p>
                    <p className=" leading-30 mt-4 text-black  font-bold pl-4 md:text-xl text-40 text-center">
                        <b>{getResource(resources, 'TERMS_AND_CONDITION')}</b>
                    </p>
                </div>
                <div className="bg-white mt-m42 border-white-50  mb-2 border rounded text-HN-Gray-Medium w-full max-w-sm pxs:max-w-2xl mx-auto border-white-50 md:mx-0 md:w-auto">
                    {/* <div className="w-305 mx-4"><p className="pt-4" dangerouslySetInnerHTML={{ __html: getResource(resources, 'TERMS_AND_CONDITION_FULL')?.replace('and', '&') }} /></div> */}
                    <div className="w-305 mx-4"><p className="pt-4">Please read the Trade-In Terms & Conditions in full, available <a href={process.env.TANDC_LINK} target="_blank" rel="noopener noreferrer" className=" text-HN-Purple-Dark underline">here</a>.</p></div>
                    <div className="w-305 h-200 mt-5 mx-4">
                        <p className="mb-5">{getResource(resources, 'TERMS_AND_CONDITION_ENTER')}</p>
                        <p className="mb-5"> {getResource(resources, 'TERMS_AND_CONDITION_ACCEPT')}</p>
                        <ol className="list-loweralpha ml-9 mr-2">
                            <li className="">agree to upload Your valid photo identification to the Asurion Trade-In Website;</li>
                            <li className="">represent that You are the legal and rightful owner of the device that You are trading in;</li>
                            <li className="">acknowledge that You will be charged a Security Charge, equivalent to the trade-in credit offer, which will be refunded once the final assessment of Your device is satisfactorily completed by Asurion;</li>
                            <li className="">will receive trade-in credit from Asurion for Your device that may be used by You at a participating Harvey Norman®, Domayne® or Joyce Mayne® store towards payment, in whole or in part, of the purchase price of a new device or available product in store;</li>
                            <li className="">agree to post Your device to Asurion within 7 days of using Your Trade ID at a participating Harvey Norman®, Domayne® or Joyce Mayne® store;</li>
                            <li className=""><p className="pt-4" dangerouslySetInnerHTML={{ __html:  point5}}/></li>
                            <li>
                            <p  className="mb-1">acknowledge that You may be charged:</p>
                            <p className="ml-3 mb-1">
                                <p>(i)	a late fee of up to $50 if You fail to post Your device to Asurion within 14 days of using Your Trade ID in store;</p>
                                <p>(ii) a Mis-Grading Device Fee if You have misrepresented the condition of Your device on the Asurion Trade-In Website and this fee will be deducted from your Security Charge refund;</p>
                                <p>(iii) an In-Eligible Device Fee if You post to Asurion a device that is ineligible for trade in, in which case Your Security Charge will not be refunded as it will be used to satisfy payment by You of the In-Eligible Device Fee; and</p>
                            </p>
                            </li>
                            <li className="md:mb-5 mb-10">agree that ownership of Your device will transfer to Asurion upon satisfactory completion of a final assessment of Your device at Asurion’s NSW warehouse.</li>
                        </ol>
                    </div>
                </div>

                <div className="flex flex-row justify-center text-center my-5 md:mt-4 mt-12 text-base text-HN-Gray-Medium">
                    <label className="myCheckbox mr-3 mt-0">
                        <input type="checkbox" className='cursor-pointer' name="termAndConditions"
                            onChange={(e) => actions.termsAgreed(e.target.checked)} checked={termsAgreed}
                        />
                        <span></span>
                    </label>
                    <span for="scales">{getResource(resources, 'ACCEPT')} <a href={process.env.TANDC_LINK} target="_blank" rel="noopener noreferrer" className=" text-HN-Purple-Dark underline"> {getResource(resources, 'TERMS_AND_CONDITION')}</a></span>
                </div>
                <div className='w-345 md:w-full mx-auto mb-m30'>
                    <button className={`${termsAgreed ? 'bg-HN-Purple-Dark' : 'opacity-25 pointer-events-none bg-HN-Gray-Dark'} w-345 md:w-full h-44 text-white text-center text-md  border-HN-Gray-Dark rounded mb-4`}
                        disabled={!termsAgreed}
                        onClick={() => history.replace('/uploadid')}>
                        {getResource(resources, 'COMMON_BTN_NEXT')}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default TermsAndConditions


