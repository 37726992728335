
import React from 'react'

const icon = ({ className, isSelected } ) => (
   <svg width="88" height="80" viewBox="0 0 88 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="13.7227" y="22.6172" width="60.7865" height="35.341" stroke="#333333" stroke-width="0.808451" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M53.8 34.668L42.1691 46.668L37 41.3349" stroke="#6B4E9F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.48145 20.3789C9.48145 19.2743 10.3769 18.3789 11.4814 18.3789H76.7497C77.8543 18.3789 78.7497 19.2743 78.7497 20.3789V62.2017H9.48145V20.3789Z" stroke="#333333" stroke-width="0.808451" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 62.1992H87.2319V62.1992C87.2319 65.3222 84.7003 67.8538 81.5774 67.8538H6.65457C3.53164 67.8538 1 65.3222 1 62.1992V62.1992Z" stroke="#333333" stroke-width="0.808451" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37.7549 62.1992H50.4776L49.064 65.0265H39.1685L37.7549 62.1992Z" stroke="#333333" stroke-width="0.808451" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)
export default icon