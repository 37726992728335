import React, { useContext, useEffect, useState, useRef } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import API from '../../services'
import types from '../../ActionTypes'
import { RenderAsyncSelectField } from '../../utils/custom-fields'
import { useForm } from 'react-hook-form'
import { getResource } from '../../utils/helper';

const PredectiveAddress = (props) => {
    let history = useHistory()
    const { register, errors, trigger, setFormValue, handleSubmit, formState, control, setValue } = useForm({ mode: 'onChange' })
    const { resources, state, actions } = props
    const { State, StreetName, StreetNo, Suburb, PostCode, GoBackClicked } = state
    let addr = `${state?.SearchAddressLine}`
    const [inputValue, setInputValue] = useState(addr)
    const selectInputRef = useRef();
    const [selectedValue, setSelectedValue] = useState('')
    let [invalidAddressAttempt, setInvalidAddressAttempt] = useState(0)
    let defaultAddr = `${StreetNo ? `${StreetNo},` : ''}${StreetName ? ` ${StreetName},` : ''}${Suburb ? ` ${Suburb},` : ''
        }${PostCode ? ` ${PostCode},` : ''}${State ? ` ${State}` : ''}`.trim()
    let addressStandardizationRequest = {
        QASParams: {
            ClientId: 'A10CCA8AB46B11EB8AC60AA01D6C824E',
            IsIntuitiveAddress: true,
            Address: {
                Address1: addr.replace('undefined', ''),
                Address2: '',
                Address3: '',
            },
        },
    }

    const availableAddress = (inputValue) => {
        if (inputValue.length >= 3) {
            return API[types.STANDARDIZE_ADDRESS_REQUEST](addressStandardizationRequest)
                .then((resp) => {
                    if (!resp) Promise.resolve({ options: [] })
                    if (resp?.data?.PickListAddress?.Picklist == 'No matches') {
                        actions.setPredectiveAddress({ matchfound: false, data: resp.data })
                        // handleAddressPredictionFailed()
                        return Promise.resolve({ options: state?.PredectiveAddress || [] })
                    }
                    actions.setPredectiveAddress({ matchfound: true, data: resp.data })
                    return Promise.resolve({
                        options: !resp?.data?.PickListAddress?.Moniker
                            ? resp.data.PickListAddress
                            : [resp.data?.PickListAddress] || [],
                    })
                })
                .catch((_) => {
                    //FAILURE ACTION
                    handleAddressPredictionFailed()
                })
        } else {
            return Promise.resolve(state?.PredectiveAddress || [])
        }
    }

    let handleAddressPredictionFailed = () => {
        if (invalidAddressAttempt <= maxAddressRetryAttempt) {
            setInvalidAddressAttempt(invalidAddressAttempt + 1)
        } else {
            setInvalidAddressAttempt(0)
        }
    }


    const promiseOptions = (inputValue) => {
        return availableAddress(inputValue).then((res) => {
            return res
        })
    }

    const handleInputChange = (value) => {
        actions.SearchAddressLine(value)
        setInputValue(value)
    }

    // handle selection
    const handleChange = (value) => {
        actions.getQASDoGetAddress({
            DoGetAddressQASParams: {
                attributes: {
                    FullAddress: value?.attributes?.FullAddress,
                },
                Moniker: value?.Moniker,
                Score: value?.Score,
            },
        })
        setSelectedValue(value)
    }
    let setError = !inputValue && !selectedValue && !defaultAddr && !GoBackClicked;
    return (
        <div >
            <RenderAsyncSelectField
                selectInputRef={selectInputRef}
                cacheOptions
                defaultOptions
                value={selectedValue}
                defaultInputValue={defaultAddr}
                isSearchable={true}
                getOptionLabel={(e) => e?.PartialAddress}
                getOptionValue={(e) => e?.PartialAddress}
                loadOptions={promiseOptions}
                debounceTimeout={500}
                onInputChange={handleInputChange}
                handleChange={handleChange}
                placeholder=""
                showIndicator={false}
                control={control}
                name="fullAddress"
                fieldName="fullAddress"
                schema="customerInfoDetails"
                setError={setError}
            />
            {setError && < p className={`error-text`}>
                {getResource(resources, 'TYPE_YOUR_ADDRESS_REQUIRED')}
            </p>}
        </div >
    )
}

export default PredectiveAddress
