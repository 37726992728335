import React from 'react'
import PropTypes from 'prop-types'
import Button from '../common/Button'

const RefundSuccess = ({ handleOk }) => (
  <div className="w-full">
    <div className="mt-4 px-4 text-center">The refund is processing. Your transaction will be processed within 5 business days.</div>
    <div className="w-full flex justify-center mt-8 mb-2">
      <Button label="Close" disabled={false} textClass="px-12 text-white bg-black" onClick={handleOk} />
    </div>
  </div>
)

RefundSuccess.defaultProps = {
  handleOk: undefined,
  responseMessage: '',
}

RefundSuccess.propTypes = {
  handleOk: PropTypes.func,
  responseMessage: PropTypes.string,
}

export default RefundSuccess
