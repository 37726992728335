import React from 'react';
import RefundFaq from "./RefundFAQ";

const FaqList = [
  {
    title:
      "Return your original device to us to receive a full refund of the Security Charge.",
    content: (
      <p>
        When your original device is received by us, we will confirm its condition. If your device matches the condition you declared to us, then you’ll get a full refund of the Security Charge, which may take up to 5 business days to appear in your account.      </p>
    ),
  },
  {
    title:
      "What happens if my device is not in the condition that was declared?",
    content: (
      <>
        <p>
          As soon as your device is sent in we will confirm its condition. If your device matches the condition that you declared then you'll receive a full refund of the Security Charge.
        </p>
        <p className='mt-4'>
          A Mis-Grading Device fee may apply if the actual condition of your device differs from the condition you declared to us. Where this is the case, you may only receive a partial refund of the Security Charge.
        </p>
      </>
    ),
  },
  {
    title: "What if I don’t return my device?",
    content: (
      <>
        <p>
        If you don’t return your device, you will not receive a refund of the Security Charge. If your device is not in the condition you declared, you may receive a partial refund of the Security Charge.        </p>
        <p className='mt-4'>
        After you confirm your trade-in at a participating store, you’ll have 7 days to return your traded in device to us. If we don’t receive it back within 14 days, you may also be charged a late return of up to $50 that is non-refundable.        </p>
      </>
    ),
  },
  {
    title: "What can change the amount of my refund?",
    content: <RefundFaq />,
  },
  {
    title: "What if I change my mind?",
    content: (
      <p>
If you change your mind and don't want to trade-in, then the Security Charge will be automatically refunded once your Trade ID expires. Alternatively, you can cancel the Trade ID to receive a full refund.      </p>
    ),
  },
];

export default FaqList;
