import React, { useEffect, useState } from 'react'
import axios from 'axios'
import QRCode from 'qrcode.react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import IconCheck from '../../Icons/icon-check'
import Iconmacbook from '../../Icons/icon-good-condition'
import { getResource } from '../../utils/helper'
import FrontLoader from '../../assets/front-loader-svg.svg'
import BackLoader from '../../assets/back-loader-svg.svg'
import IconWatch1 from '../../Icons/icon-watch1'
import IconTablet from '../../Icons/icon-tablet'
import IconSmartphone from '../../Icons/icon-smartphone'
import IconPhoneDevice from '../../Icons/icon-phone-device'

const Summary = (props) => {
  const { resources, state, actions } = props
  let {
    Eligibilty,
    CreateQuoteResponse,
    serialno,
    QRcodePresignUrlResult,
    PresignUrlResult,
    PresignUrlResult2,
    CustomerInfo,
    deviceEvalutionObject,
    deviceEvalutionObjectSurface,
    deviceEvalutionObjectWearables,
    DeviceType,
    selectedManufacturer,
    deviceEvalutionObjectLvd,
    SelectedDevice,
    collectpaymentdata,
    isBGBT, 
    paymentMethod
  } = state
  const isLvd =
    DeviceType === 'LVD Laptop' ||
    DeviceType === 'LVD Tablet' ||
    DeviceType === 'LVD Phone' ||
    DeviceType === 'LVD Watch'
  let isGoodWorking = Eligibilty === 'GWO' ? true : false
  let conditionArray = []
  const history = useHistory()

  let callImageData = PresignUrlResult2 && PresignUrlResult && QRcodePresignUrlResult ? true : false
  let formattedExpiryDate = moment(CreateQuoteResponse?.expiryDate?.split('T')[0]).format('LL')
  let QRCodeData = {
    tradeId: CreateQuoteResponse.tradeId,
    value: CreateQuoteResponse.quotePrice,
    imei: serialno,
  }
  let objJsonStr = JSON.stringify(QRCodeData)
  // let objJsonB64 = Buffer.from(objJsonStr).toString('base64')
  const [dataBack, setDataBack] = useState('')
  const [dataFront, setDataFront] = useState('')

  // let QRMounted = document?.getElementById('qrcodepng')

  // useEffect(() => {
  //   if (QRMounted) {
  //     QRpngUpload()
  //   }
  // }, [QRMounted ? true : false])

  useEffect(() => {
    if (callImageData) {
      PresignUrlResult2 && downloadFromS3(PresignUrlResult2, 'back-id')
      PresignUrlResult && downloadFromS3(PresignUrlResult, 'front-id')
    }
  }, [callImageData])

  // let sendQRCodeEmail = async () => {
  //   // QRpngUpload()
  //   let emailData = {
  //     SendWebEmailRequest: {
  //       Url: `${process.env.DOMAIN}`,
  //       TradeId: CreateQuoteResponse?.tradeId,
  //       TradeInValue: CreateQuoteResponse?.quotePrice?.toString(),
  //       ExpiryDate: CreateQuoteResponse?.expiryDate,
  //       DeviceCondition: Eligibilty,
  //       DeviceType: DeviceType,
  //       ConditionArray: conditionArray,
  //     },
  //   }
  //   actions.sendQRCodeEmail(emailData)
  // }
  // useEffect(() => {
  //     if (SendEmailResponse === 'success') {
  //         history.replace('/conclusion')
  //     }
  // }, [SendEmailResponse === 'success'])

  // const uploadToS3 = (url, document) => {
  //   return axios({
  //     method: 'put',
  //     url: url,
  //     headers: {
  //       'Content-Type': 'image/png',
  //     },
  //     data: document,
  //   })
  // }

  // const QRpngUpload = async () => {
  //   // var svgString = new XMLSerializer().serializeToString(document.getElementById("qrcodepng"));
  //   // var svg = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
  //   // await uploadToS3(QRcodePresignUrlResult, svg)
  //   const canvas = await document.getElementById('qrcodepng')
  //   const pngUrl = await canvas.toDataURL('image/png')
  //   let blobData = await (await fetch(pngUrl)).blob()
  //   await uploadToS3(QRcodePresignUrlResult, blobData)
  //   await sendQRCodeEmail()
  // }

  const downloadFromS3 = (getUrl, idType) => {
    fetch(getUrl, { method: 'GET' })
      .then((res) => {
        return res.blob()
      })
      .then((blob) => {
        const newUrl = URL.createObjectURL(blob)
        idType == 'back-id' ? setDataBack(newUrl) : setDataFront(newUrl)
        /*  return blobToBase64(blob).then(base64 => {
                     idType == 'back-id' ? setDataBack(base64) : setDataFront(base64)
                 }) */
      })
      .catch((err) => {
        console.error('err: ', err)
      })
  }

  
  let ios_lock_url = 'https://support.apple.com/en-au/guide/icloud/mmdc23b125f6/icloud'
  let android_lock_url = 'https://www.asurion.com/connect/tech-tips/how-to-turn-on-off-find-my-device-on-android/'

  return (
    <div className="px-5 mb-m30">
      <div className="flex pt-6 justify-between items-center md:justify-start w-1300 mx-auto 2xl:w-900 lg:w-auto">
        <div className="text-HN-Green-Light font-bold md:text-2xl text-40 mx-auto flex justify-center">
        Time to head into store
        </div>
        <div className="md:hidden block"></div>
      </div>
      <div className="w-700 md:w-full mx-auto">
        <p className="text-center mt-5 md:text-xl text-32">
          {' '}
          {getResource(resources, 'CONCLUSION_WELCOME_TEXT')}{' '}
          <span className="font-bold">{CustomerInfo?.EmailAddress}</span>
        </p>
      </div>
      <div className="flex flex-col justify-center mx-auto md:pt-5 pt-p50 ">
        <div className="items-center max-w-sm w-full justify-center pt-4 xl:min-h-0 min-h-500 mx-auto bg-white rounded-r4">
          <div className="text-center mt-m22 mb-4">
            <div className="font-bold text-HN-Gray-Medium text-xl">{getResource(resources, 'NEXT_STEP')}</div>
          </div>
          <div className="text-sm mx-m23 mb-4 md:mt-0 mt-m30">
            <ol className="list-decimal ml-2">
             <li dangerouslySetInnerHTML={{ __html: 'Save the <b>Trade ID</b> supplied below as you will need it to trade-in your device'}}></li>
              
               {/* <li dangerouslySetInnerHTML={{ __html: getResource(resources, 'CONCLUSION_SAVE_QR')?.replace('QR code', 'Trade ID') }}></li> */}
                           
              <li>Head to your nearest participating Harvey Norman, Domayne or Joyce Mayne store and show them your Trade ID</li>
              {DeviceType === 'MacBook' && (
                <li
                  dangerouslySetInnerHTML={{
                    __html:
                      getResource(resources, 'CONCLUSION_BACKUP') +
                      '. ' 
                      // getResource(resources, 'COMMON_CONCLUSION_BACKUP'),
                  }}
                ></li>
              )}
              
              {DeviceType === 'Surface' && (
                <li
                  dangerouslySetInnerHTML={{
                    __html:
                      getResource(resources, 'SURFACE_BACKUP') +
                      '. '
                      // getResource(resources, 'COMMON_CONCLUSION_BACKUP'),
                  }}
                ></li>
              )}
               {(DeviceType === 'Phone' || DeviceType === 'Tablet' || DeviceType === 'LVD Phone' ||  DeviceType === 'LVD Tablet') && (
                <li
                   dangerouslySetInnerHTML={{
                    __html:
                      `Perform a <b>full back-up</b> of your devices data and files and <a href = ${SelectedDevice?.[0]?.AssetMakeName?.toUpperCase() === 'APPLE' ? ios_lock_url : android_lock_url}
                      target='blank' rel='noopener noreferrer' style = 'color:#6B4E9F; text-decoration: underline;'>turn off any activation locks</a>`
                  }}
                ></li>
              )}
              {DeviceType === 'Watch' && selectedManufacturer === 'Apple' && (
                <li
                  dangerouslySetInnerHTML={{
                    __html:
                      getResource(resources, 'APPLE_WEARABLE_CONCLUSION_BACKUP') +
                      '. ' 
                      // getResource(resources, 'COMMON_CONCLUSION_BACKUP'),
                  }}
                ></li>
              )}
              {DeviceType === 'Watch' && selectedManufacturer === 'Samsung' && (
                <li
                  dangerouslySetInnerHTML={{
                    __html:
                      getResource(resources, 'SAMSUNG_WEARABLE_CONCLUSION_BACKUP') +
                      '. ' 
                      // getResource(resources, 'COMMON_CONCLUSION_BACKUP'),
                  }}
                ></li>
              )}
              {DeviceType === 'LVD Laptop' && SelectedDevice?.[0].AssetCatalogName === 'Apple Laptop' && (
                <>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: getResource(resources, 'APPLE_BACKUP'),
                    }}
                  ></li>
                  {/* <li> {getResource(resources, 'CONCLUSION_REMOVE_COVER')} </li> */}
                </>
              )}
              {(SelectedDevice?.[0].AssetCatalogName === 'Apple Watch' ||
                SelectedDevice?.[0].AssetCatalogName === 'Apple Tablet' ||
                SelectedDevice?.[0].AssetCatalogName === 'Apple Phone') && (
                <>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: getResource(resources, 'CONCLUSION_BACKUP_LVD_APPLE'),
                    }}
                  ></li>
                  {/* <li> {getResource(resources, 'CONCLUSION_REMOVE_COVER')} </li> */}
                </>
              )}
              {
                (SelectedDevice?.[0].AssetCatalogName != 'Apple Watch' ||
                  SelectedDevice?.[0].AssetCatalogName != 'Apple Tablet' ||
                  SelectedDevice?.[0].AssetCatalogName != 'Apple Phone' ||
                  SelectedDevice?.[0].AssetCatalogName != 'Apple Laptop') && (
                  <>
                    <li> {getResource(resources, 'CONCLUSION_REMOVE_COVER')} </li>
                  </>
                )}
              
              {/* {(DeviceType !== 'Surface' &&  DeviceType !== 'MacBook' &&  DeviceType !== 'Watch') && <li> {getResource(resources, 'REMOVE_CARD')} </li>} */}
              <li>Return your device at any Australia Post outlet</li>
            </ol>
          </div>

          {/* <div className="ml-m18 mr-m17 text-sm py-2 px-5 leading-19 text-left text-HN-Purple-Dark border border-gray-400 bg-purple-100 rounded-r4 ">
            <div className="w-260">
              <b>{getResource(resources, 'NOTE')}</b>
              <span>{getResource(resources, 'NOTE_COMPLETE')}</span>
            </div>
          </div> */}
          <div className="text-center mx-4 mt-m22 text-f22">
            <p className="text-center ">{getResource(resources, 'TRADE_IN_EXPIRY')}</p>
            <p className="text-center font-bold text-HN-Purple-Dark text-md">{formattedExpiryDate}</p>
          </div>
          <div className="text-center mt-6 font-bold text-f17 leading-22 mb-4">
            {getResource(resources, 'TAKE_NOTE')}
          </div>

          <div className="flex flex-row justify-center mt-m-2.5 mb-5 mx-m18">
            <div className="flex-col  mt-2 mr-5 justify-center border border-gray-400 rounded bg-white w-140 h-75 inline-block float-left">
              <p className="text-center text-HN-Subtext-Dark text-sm mt-2">
                {getResource(resources, 'TRADEIN_VALUE')}{' '}
              </p>
              <p className="text-HN-Purple-Dark text-f22 leading-7 mt-2 text-center font-bold ">
                ${CreateQuoteResponse?.quotePrice}
              </p>
            </div>
            <div className="flex-col mt-2 border border-gray-400 rounded bg-white w-140 h-75 inline-block float-right">
              <p className="text-center text-HN-Subtext-Dark text-sm mt-2 ">{getResource(resources, 'TRADE_ID')}</p>
              <p className="text-HN-Purple-Dark text-f22 leading-7  mt-2 text-center font-bold ">
                {CreateQuoteResponse?.tradeId}
              </p>
            </div>
            
          </div>

          { isBGBT &&
            <div className="mx-m23 mb-5">
              <div className="flex flex-row mb-3 justify-center">
                <b className="text-xl text-32">Payment Receipt</b>
              </div>
              <div className="">
                <p className="text-sm">
                  <span className='text-HN-Subtext-Light'>Date:{' '}</span>
                  <span>
                    <b>{moment().format("DD MMMM YYYY")}</b>
                  </span>
                </p>
                <p className="text-sm">
                <span className='text-HN-Subtext-Light'>Amount:{' '}</span>
                  <span>
                    <b>${CreateQuoteResponse?.quotePrice}</b>
                  </span>
                </p>
                <p className="text-sm">
                <span className='text-HN-Subtext-Light'>Transaction ID:{' '}</span>
                  <span>
                    <b>{collectpaymentdata && collectpaymentdata?.CollectPaymentResponse?.TransactionId}</b>
                  </span>
                </p>
                <p className="text-sm">
                <span className='text-HN-Subtext-Light'>Payment Method:{' '}</span>
                  <span>
                    <b>{paymentMethod === 'paypal' ? 'PayPal' : 'Credit Card'}</b>
                  </span>
                </p>
              </div>
            </div>
          }
          
        </div>
        <div className='flex flex-row items-center justify-center  '>
               <button
                onClick={() => {
                  // actions.clearData('selectedCategory'); 
                  // actions.clearState();   
                  // actions.getMasterData();
                  // history.replace("/");
                  window.location.reload();
                }}
                className={`primary-btn bg-HN-Purple-Dark text-white my-m50 max-w-xs mx-auto w-full`}
                >Back to home</button>
        </div>
        {/* <div className="w-400 md:w-auto xl:min-h-0 min-h-500 mx-auto md:mx-0 mt-m22 bg-white border md:border-HN-Gray-Dark border-white rounded-r4"> */}
          {/* <div className="text-center  text-HN-Gray-Medium font-bold md:mt-6 mt-10 ">
            {getResource(resources, 'ASURION_TRADEIN')}
          </div> */}
          {/* <div className="text-center  text-HN-Gray-Medium font-bold  mb-3">{CustomerInfo?.EmailAddress}</div> */}
          {/* <div className="flex flex-row justify-center mb-8">
            <div>
              {dataFront === '' || dataBack === '' ? (
                <div className="id-upload w-102 h-64 mr-4 loader">
                  {' '}
                  <FrontLoader />
                </div>
              ) : (
                <img src={dataFront} className="w-102 h-64 mr-4 id-upload"></img>
              )}
            </div>
            <div>
              {dataBack === '' ? (
                <div className="id-upload w-102 h-64 loader">
                  {' '}
                  <BackLoader />
                </div>
              ) : (
                <img src={dataBack} className="w-102 h-64 id-upload"></img>
              )}
            </div>
          </div> */}
          {/* <div className="text-center text-HN-Gray-Medium font-bold m18 mb-2 mt-4">
            {getResource(resources, 'TRADEIN_QR')}
          </div> */}
          {/* <div className="justify-center"> */}
            {/* <div className="">
              <QRCode
                // id={'qrcodepng'}
                value={objJsonB64}
                size={127}
                className="mx-auto"
                level={'Q'}
                // renderAs={"svg"}
              />
            </div>
            <div className="">
              <QRCode id={'qrcodepng'} value={objJsonB64} size={200} className="hidden mx-auto" level={'Q'} />
            </div> */}
            {/* <div className="text-center md:text-f17 text-xl m-4 md:mt-m23 mt-4 text-HN-Gray-Medium">
              {getResource(resources, 'TRADEIN_EXPIRES_ON') + formattedExpiryDate}
            </div>
            <div className="text-center md:text-lg text-xl mx-m20 md:mt-m23 mt-7 text-HN-Gray-Medium">
              {getResource(resources, 'EMAIL_CONTAINING_TRADE_DETAILS')}
            </div> */}
         
              {/* <div className="">
                <center>
                  <button
                    className="text-HN-Purple-Dark text-f17 w-full md:pb-3 "
                    onClick={() => {
                      sendQRCodeEmail()
                      // history.replace
                    }}
                  >
                    {getResource(resources, 'RESEND_EMAIL')}
                  </button>
                </center>
              </div> */}
            {/* </div> */}
          {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  )
}

export default Summary
