
import React from 'react'

const icon = ({ className, isSelected, onClick }) => (
   < svg onClick={onClick} width="22.49" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.99984 12H23.4858" stroke="#6B4E9F" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.9858 23L1.34284 12.751C1.12759 12.5612 1.00428 12.288 1.00428 12.001C1.00428 11.714 1.12759 11.4408 1.34284 11.251L12.9858 1" stroke="#6B4E9F" stroke-linecap="round" stroke-linejoin="round" />
   </svg>
)
export default icon
