import React from 'react'
import Logo from '../../assets/selfserve-assets/hn-trade-logo.svg'

const HeaderLogo = () => (
  <div className="text-center hn-header py-8">
    <Logo className="mx-auto" />
  </div>
)

export default HeaderLogo
