import React,{ useState, useEffect } from "react";
import UpIcon from "../../Icons/icon-up";
import DownIcon  from "../../Icons/icon-down";

const Accordian = ({ data, className }) => {
  const [selectedTitle, setSelectedTitle] = useState("");
  // useEffect(()=>{
  //   if(document.getElementById("refundAmount"))
  //   console.log(document.getElementById("refundAmount"))
  //   {
  //     document.getElementById("refundAmount").innerText= `$100`
  //   }
  // },[])
  return (
    <div className={className}>
      {data.map((d, index) => (
        <AccordianCard
          title={d.title}
          isOpen={selectedTitle === d.title}
          content={d.content}
          data={data}
          index={index}
          onSelect={() => setSelectedTitle(selectedTitle === d.title ? "" : d.title)}
        />
      ))}
    </div>
  );
};

const AccordianCard = ({ title, isOpen, content, onSelect , data ,index}) => {
  
  return (
    <div className={` ${data?.length-1 !== index && 'border-HN-Grey-Border border-b'} py-2 md:px-0 px-3`}>
      <div className="flex flex-auto justify-between items-center cursor-pointer" onClick={onSelect}>
        <div className="lg:text-base text-lg font-bold py-2">{title}</div>
        <div className="">{!isOpen ? <DownIcon width="22" height="22" /> : <UpIcon width="22" height="22" />}</div>
      </div>
      {isOpen && <div className="lg:text-base text-lg ">{content}</div>}
    </div>
  );
};

export default Accordian;
