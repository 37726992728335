/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */

import React from 'react'

const icon = ({ className, isSelected }) => (
    <svg width="79" height="73" viewBox="0 0 79 73" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M48.3715 31.6328L37.716 42.5828L32.9805 37.7164" stroke="#6B4E9F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.9873 19.3887C21.9873 18.2841 22.8827 17.3887 23.9873 17.3887H56.6327C57.7372 17.3887 58.6327 18.2841 58.6327 19.3887V51.8887C58.6327 52.9932 57.7372 53.8887 56.6327 53.8887H23.9873C22.8827 53.8887 21.9873 52.9932 21.9873 51.8887V19.3887Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M50.0089 17.3904H30.6084L32.764 10.9492H47.8533L50.0089 17.3904V17.3904Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.6084 53.8926H50.0089L47.8533 60.3338H32.764L30.6084 53.8926V53.8926Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


)

export default icon



