import React from 'react'
import Popup from 'reactjs-popup'
import Iconfinalcheck from "../../Icons/icon-finalcheck"
import { useHistory } from 'react-router-dom'
import { getResource } from '../../utils/helper';
import autoprefixer from 'autoprefixer';

const SuccessPopUp = (props) => {
    const { resources, state, actions } = props
    const { openSuccessPopup, closePopUp, fileNames } = props
    const { CreateQuoteResponse, PresignUrlResult, PresignUrlResult2 } = state
    let isMobile = window.innerWidth < 768
    const contentStyleForDesktopView = {

        padding: '0px',
        alignSelf: 'center',
        width: isMobile ? '80%' : 'auto',
        height: '220px',
        position: isMobile ? 'absolute' : 'relative',
        'margin-top': '150px',
        margin: 'auto',
        inset: 0
        // "border-radius": '20px 20px 1px 1px'
    }
    let history = useHistory();

    const nextclick = () => {
        closePopUp();
        // actions.getQrCodePresignUrl({ tradeId: CreateQuoteResponse?.tradeId, imageName: 'qrcode.png', method: 'put' })
        actions.getPresignUrl({ tradeId: CreateQuoteResponse?.tradeId, imageName: fileNames?.frontFile, method: 'get' })
        actions.getPresignUrl2({ tradeId: CreateQuoteResponse?.tradeId, imageName: fileNames?.backFile, method: 'get' })
        history.replace('./serialno')
    }
    return (
        <Popup
            contentStyle={contentStyleForDesktopView}
            open={openSuccessPopup}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            repositionOnResize={true}
            modal
        >
            <div className="bg-white w-330 md:w-full h-245 border border-gray-100 rounded-2xl -ml-4 md:-ml-0">

                <div className=" pt-4 flex items-center justify-center mr-18 my-3">
                    <Iconfinalcheck className="mx-auto mb-m30" />
                </div>
                <b> <p className='text-center text-xl text-HN-Green1-Dark text-bold'>{getResource(resources, 'SUCCESS')}</p></b>
                <div className="justify-center pt-2">
                    <p className="text-base text-center text-HN-Gray-Medium">{getResource(resources, 'ID_HAS_BEEN_CAPTURED')}</p>
                </div>
                <div className="flex items-center pb-8 justify-between">
                    <div className=" mt-6 mx-auto">
                        <button className="text-md text-white md:w-251 w-255 leading-5 rounded-md text-white bg-HN-Purple-Dark h-44"
                            onClick={() => nextclick()}
                        >
                            {getResource(resources, 'COMMON_BTN_NEXT')}
                        </button>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default SuccessPopUp