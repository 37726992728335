

import React from 'react'

const icon = ({ className }) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.4271 15.7059L6.73507 12.0552L7.77939 11.0228L10.4271 13.641L16.0283 8.10221L17.0724 9.13464L10.4271 15.7059ZM11.9998 4C7.582 4 4 7.58141 4 12C4 16.4186 7.582 20 11.9998 20C16.4186 20 19.9998 16.4186 19.9998 12C19.9998 7.58141 16.4186 4 11.9998 4Z" fill="#6B4EA0"/>
</svg>

)

export default icon;