import React, { useState, useEffect } from 'react';
import Iconback from '../../Icons/icon-back';
import { getResource } from '../../utils/helper';
import axios from 'axios'
import SuccessPopUp from './SuccessPopup'
import FailurePopUp from './FailurePopup'
import { useHistory } from 'react-router-dom'
import Icontop1 from '../../Icons/icontop1'
import Icontop2 from '../../Icons/icontop2'
import Iconbottom1 from '../../Icons/iconbottom1'
import Iconbottom2 from '../../Icons/iconbottom2'
import IconfrontID from '../../Icons/iconfrontID'
import IconbackID from '../../Icons/iconbackID'



const ID = (props) => {
    const history = useHistory();
    const { resources, state, actions } = props
    const { CreateQuoteResponse, PresignUrlResult, PresignUrlResult2 } = state
    const [dataURIFront, setDataURIFront] = useState("")
    const [dataURIBack, setDataURIBack] = useState("")
    const [frontFileObj, setFrontFileObj] = useState()
    const [backFileObj, setBackFileObj] = useState()
    const [successPopup, setSuccessPopup] = useState(false)
    const [failurePopup, setFailurePopup] = useState(false)
    const [frontFileSupportError, setFrontFileSupportError] = useState(false)
    const [backFileSupportError, setBackFileSupportError] = useState(false)
    let isMobile = window.innerWidth < 768;
    let [counter, setCounter] = useState(0)

    const checkFileExtension = (fileName) => {
        let ext = ['.PNG', '.JPG', '.JPEG', '.HELIC'];
        let isValidExt = ext.map(i => {
            return fileName.toUpperCase().endsWith(i) ? true : false;
        })
        return isValidExt.filter(i => i == true)[0] ? true : false;
    }

    const handleFrontCapture = (target) => {
        setDataURIFront("")
        if (target.files) {
            if (target.files.length !== 0) {
                if(!checkFileExtension(target.files[0].name)){
                    setFrontFileSupportError(true)
                }
                else {
                    setFrontFileSupportError(false)
                    let imageName = isMobile ? 'front-id.png' : target.files[0].name;
                    actions.getPresignUrl({ tradeId: CreateQuoteResponse?.tradeId, imageName: imageName, method: 'put' })
                    const file = target.files[0];
                    const newUrl = URL.createObjectURL(file);
                    setFrontFileObj(file)
                    setDataURIFront({newUrl: newUrl, type : imageName});
                }
            } else {
                setFailurePopup(true)
            }
        } else {
            setFailurePopup(true)
        }
    }

    const handleBackCapture = (target) => {
        setDataURIBack("")
        if (target.files) {
            if (target.files.length !== 0) {
                if(!checkFileExtension(target.files[0].name)){
                    setBackFileSupportError(true)
                }
                else {
                    setBackFileSupportError(false)
                    let imageName = isMobile ? 'back-id.png' : target.files[0].name;
                    actions.getPresignUrl2({ tradeId: CreateQuoteResponse?.tradeId, imageName: imageName, method: 'put' })
                    const file = target.files[0];
                    const newUrl = URL.createObjectURL(file);
                    setBackFileObj(file)
                    setDataURIBack({newUrl: newUrl, type :imageName});
                }
            } else {
                setFailurePopup(true)
            }
        } else {
            setFailurePopup(true)
        }
    }

    const uploadToS3 = (url, imageBody, formData) => {
        return axios({
            method: 'put',
            url: url,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            data: imageBody,
            // onUploadProgress: (progressEvent) => {
            //     const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            //     setProgress(progress)
            // },
            formData: formData,
        }).then((response) => {
            setCounter(++counter)
            if (counter == 2) {
                actions.unSetLoader()
                setSuccessPopup(true)
            }
        }).catch((error) => {
            actions.unSetLoader()
            setFailurePopup(true)
        })
    }

    const uploadIdToS3 = async () => {
        actions.setLoader()
        const formDataFront = new FormData();
        await formDataFront.append('Content-Type', frontFileObj.type);
        await uploadToS3(PresignUrlResult, frontFileObj, formDataFront)
        const formDataBack = new FormData();
        await formDataBack.append('Content-Type', backFileObj.type);
        await uploadToS3(PresignUrlResult2, backFileObj, formDataBack)
    }

    const closeFailurePopup = () => {
        setDataURIFront("")
        setDataURIBack("")
        setFailurePopup(false)
    }
    const navigateBack = () => {
        actions.clearData('PresignUrlResult2')
        actions.clearData('PresignUrlResult')
        actions.clearData('termsAgreed')
        history.replace('/terms')
    }
    return (
        <div>
            <div className="px-p15 md:px-p15 xl:w-700 md:w-auto mx-auto w-900">
                <div className="flex pt-6 pl-p5 md:pl-0">
                    <div className="md:mt-1 mt-4">
                        <Iconback onClick={() => navigateBack()} />
                    </div>
                    <div className="text-bold md:text-f22 text-40 mx-auto justify-center "><b><b>{getResource(resources, 'IDENTIFICATION')}</b></b>
                    </div>
                </div>
                <div className='w-700 mx-auto md:w-auto'>
                    
                    <div className="md:text-sm text-2xl text-HN-Subtext-Light mt-m20 mx-auto md:w-auto ">In compliance with our second-hand dealing obligations, you are required to provide a drivers licence, key card or proof of age card.</div>
                    <div className="md:text-sm text-2xl text-HN-Subtext-Light mt-m20 mx-auto md:w-auto "> Please upload a picture of the front and back of your identification below.</div>

                    <div className='flex flex-row  md:justify-center justify-between md:flex-col md:mt-4 mt-8 '>
                        <div className='md:mr-0'>
                            {!dataURIFront.newUrl &&
                                <div className='justify-center flex flex-col mx-auto md:w-full w-291' >
                                    <div className='flex justify-between  flex-row  '>
                                        <Icontop1 />
                                        <Icontop2 />
                                    </div>
                                    <div className='mx-auto' >
                                        <IconfrontID />
                                    </div>
                                    <div className=' justify-between flex flex-row '>
                                        < Iconbottom1 />
                                        < Iconbottom2 />
                                    </div>
                                </div>}
                            {dataURIFront.newUrl &&
                                <div className='mt-8 h-120'>
                                    <img src={dataURIFront.newUrl} alt="IDimageFailed"
                                        className='mx-auto drop-shadow-lg md:w-189 md:h-120 w-255 h-155' />
                                </div>
                            }
                            <div></div>
                            <label className="flex justify-center mx-auto md:w-full w-210 cursor-pointer pt-3 inline-block bg-HN-Purple-Dark text-white md:mt-4 mt-14 rounded-md text-center h-12 ">
                                <input
                                    accept=".png, .jpg, .jpeg,.helic,.pdf"
                                    id="id-upload-front"
                                    type="file"
                                    className='hidden'
                                    capture="environment"
                                    onChange={(e) => handleFrontCapture(e.target)}
                                />
                                {getResource(resources, 'TAKE_PHOTO_FRONT')}
                            </label>
                            {frontFileSupportError && <p className="flex justify-center text-red-600 pt-3 text-sm">{getResource(resources, 'UPLOADID_CONDITION_ERROR')}</p>}
                        </div>
                        <div>
                            <div>
                                {!dataURIBack.newUrl &&
                                    <div className='justify-center flex flex-col md:mt-4 mx-auto md:w-full w-291'>
                                        <div className='justify-between flex flex-row  '>
                                            <Icontop1 />
                                            <Icontop2 />
                                        </div>
                                        <div className='mx-auto' >
                                            <IconbackID />
                                        </div>
                                        <div className=' justify-between flex flex-row '>
                                            <Iconbottom1 />
                                            <Iconbottom2 />
                                        </div>
                                    </div>}
                                {dataURIBack.newUrl &&
                                    <div className='mt-8 h-120'>
                                        <img src={dataURIBack.newUrl} alt="IDimageFailed"
                                            className='mx-auto drop-shadow-idimage  md:w-189 md:h-120 w-255 h-155' />
                                    </div>
                                }
                            </div>
                            
                            <label className=" flex justify-center mx-auto md:w-full w-210 cursor-pointer pt-3 inline-block bg-HN-Purple-Dark text-white md:mt-4 mt-14 rounded-md text-center h-12 ">
                                <input
                                    accept=".png, .jpg, .jpeg,.helic,.pdf"
                                    id="id-upload-back"
                                    type="file"
                                    className='hidden'
                                    capture="environment"
                                    onChange={(e) => handleBackCapture(e.target)}
                                />
                                {getResource(resources, 'TAKE_PHOTO_BACK')}
                            </label>
                            {backFileSupportError && <p className="flex justify-center text-red-600 pt-3 text-sm">{getResource(resources, 'UPLOADID_CONDITION_ERROR')}</p>}
                        </div>

                    </div>
                    <div className=' text-HN-Gray-Dark mt-8 text-center md:mr-0 mx-auto text-f17 md:hidden block'>
                        {/* {getResource(resources, 'UPLOADID_CONDITION')} */}
                        You are able to upload your ID in the following formats:<br/> JPEG, PNG & PDF for best compatibility
                    </div>
                    <div className='w-345 md:ml-0 mx-auto md:w-auto'>
                        <button
                            className={`${dataURIBack.newUrl && dataURIFront.newUrl ? 'bg-HN-Purple-Dark' : 'opacity-25 pointer-events-none bg-HN-Gray-Dark'} rounded-md h-44 primary-btn bg-HN-Purple-Dark text-white md:w-full w-345 md:mt-4 mt-14`}
                            disabled={!(dataURIBack.newUrl && dataURIFront.newUrl)}
                            onClick={() => uploadIdToS3()}>
                            {getResource(resources, 'COMMON_BTN_NEXT')}
                        </button></div>
                    <div className='text-center mx-auto text-sm my-4 text-HN-Subtext-Dark'>{getResource(resources, 'ASURION_PRIVACY_POLICY')}
                        <a href={process.env.PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer" className='underline  text-sm  text-HN-Purple-Dark'>{getResource(resources, 'ASURION_POLICY')}
                        </a></div>
                </div>
            </div>
            {successPopup && <SuccessPopUp
                openSuccessPopup={successPopup}
                closePopUp={() => setSuccessPopup(false)}
                actions={actions}
                state={state}
                resources={resources}
                fileNames = {{backFile : dataURIBack.type, frontFile : dataURIFront.type}}
            />}
            {failurePopup && <FailurePopUp
                openFailurePopup={failurePopup}
                closePopUp={() => closeFailurePopup()}
                actions={actions}
                state={state}
                resources={resources}
            />}
        </div>
    )
}

export default ID
