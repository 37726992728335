import React, { useContext, useEffect } from 'react'
import Select, { components } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { Controller } from 'react-hook-form'
import Cleave from 'cleave.js/react'

import Validator from './schemas'
import { store } from '../Store'
import errorMessages from './error-messages'
import TooltipIcon from '../Icons/icon-tooltip'
import NameOnCardIcon from '../Icons/icon-nameoncard'
import CreditCardIcon from '../Icons/icon-creditcardnumber'
import DatePicker from 'react-datepicker'

const moment = require('moment')
const { ValueContainer, Placeholder } = components

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused} className="flotingSelectPlaceholder">
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => {
        return (child && child.type !== Placeholder ? child : null)
      })}
    </ValueContainer>
  )
}

const FloatLabel = (() => {
  // add active class and placeholder
  const handleFocus = (e) => {
    const target = e.target
    target.parentNode.classList.add('active')
    target.parentNode.classList.remove('hasValue')
    target.setAttribute('placeholder', target.getAttribute('data-placeholder'))
  }

  // remove active class and placeholder
  const handleBlur = (e) => {
    const target = e.target
    if (!target.value) {
      target.parentNode.classList.remove('active')
    }
    target.value && target.parentNode.classList.add('hasValue')
    target.removeAttribute('placeholder')
  }

  // remove active class and placeholder
  const keyUpHandler = ({ target }) => {
    target.parentNode.classList.add('active')
    !target.value && target.removeAttribute('placeholder') // removing placeholder when input is empty
  }

  // register events
  const bindEvents = (element) => {
    const floatField = element.querySelector('input,select,textarea')
    floatField.addEventListener('focus', handleFocus)
    floatField.addEventListener('blur', handleBlur)
    floatField.addEventListener('keyup', keyUpHandler) // this is a fix for existing issue, when emptying input will show both placeholders & label
  }

  // get DOM elements
  const init = () => {
    const floatContainers = document.querySelectorAll('#floatContainer1')

    floatContainers.forEach((element) => {
      if (element.querySelector('input,select,textarea').value) {
        //element.classList.add('active')
        element.classList.add('hasValue')
        element.removeAttribute('placeholder')
      }

      bindEvents(element)
    })
  }

  return {
    init: init,
  }
})()

// const LabeledInputField = ({
//   type,
//   name,
//   value,
//   label,
//   hideLabel,
//   placeholder,
//   disabled,
//   className,
//   onChange,
//   onClick,
//   register,
//   errors,
//   schema,
//   customeValidator,
//   touched,
//   dirtyFields,
//   Mandatory,
//   labelClass,
//   defaultValue,
//   restrictInput,
//   onKeyPress,
//   errorClass,
//   onBlur,
//   enableBlurEvent
// }) => {
//   const { state } = useContext(store)
//   const { resources } = state
//   const validator = customeValidator || Validator(schema, name)
//   return (
//     <>
//       {!hideLabel && (
//         <label htmlFor={name} className={`${labelClass} text-base leading-19  text-black`}>
//           {resources && resources[label] || label}
//           {Mandatory && <span className="text-red-600 ml-1">*</span>}
//         </label>
//       )}
//       <input
//         type={type}
//         name={name}
//         value={value}
//         disabled={disabled}
//         placeholder={resources && resources[placeholder] || placeholder}
//         className={`box-border border border-solid border-light-gray rounded  focus:border-purple-1 ${className} ${errors[name] ? 'input_box_error' : ''}`}
//         onChange={(e) => onChange(e.target.value)}
//         ref={register(validator)}
//         defaultValue={defaultValue}
//         onKeyPress={(e) => restrictInput && onKeyPress(e)}
//         onBlur={(e) => enableBlurEvent && onBlur(e.target.value)}
//       />
//       {errors[name] && errors[name].type == 'required' && <p className={`${errorClass ? errorClass : ''} error-text`}>{errorMessages('required', label, validator, resources)}</p>}
//     </>
//   )
// }

export const RadioField = ({
  id,
  type,
  name,
  value,
  label,
  hideLabel,
  placeholder,
  disabled,
  className,
  onChange,
  onClick,
  register,
  errors,
  schema,
  customeValidator,
  touched,
  dirtyFields,
  Mandatory,
  labelclassName,
  defaultChecked,
  restrictInput,
  onKeyPress,
  errorClass,
  onBlur,
  enableBlurEvent,
  checked,
  radioButtonLabel,
}) => {
  const { state } = useContext(store)
  const { resources } = state
  const validator = customeValidator || Validator(schema, name)
  return (
    <>
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        className={className}
        onChange={(e) => onChange(e.target.value)}
        ref={register(validator)}
        defaultChecked={defaultChecked}
        // onKeyPress={(e) => restrictInput && onKeyPress(e)}
        // onBlur={(e) => enableBlurEvent && onBlur(e.target.value)}
        hidden
      />
      <label className={labelclassName} htmlFor={id || name}>
        {radioButtonLabel}
      </label>
      {/* {errors[name] && errors[name].type == 'required' && <p className={`${errorClass ? errorClass : ''} error-text`}>{errorMessages('required', label, validator, resources)}</p>} */}
    </>
  )
}

export const FloatLabelInputField = ({
  type,
  name,
  value,
  label,
  hideLabel,
  placeholder,
  disabled,
  className,
  onChange,
  onClick,
  register,
  errors,
  schema,
  customeValidator,
  touched,
  dirtyFields,
  Mandatory,
  labelClass,
  defaultValue,
  restrictInput,
  onKeyPress,
  errorClass,
  onBlur,
  enableBlurEvent,
  maxLength,
  eyeIcon,
  imgOnCkick,
  singleLine,
  showInputIcon,
  inputImgPath,
  masked,
  errorClassName,
  customErrorMessage,
  showCustomErrorMessage,
  isAutoFocus = false
}) => {
  const { state } = useContext(store)
  const { resources } = state
  const validator = customeValidator || Validator(schema, name)
  useEffect(() => {
    FloatLabel.init()
  }, [])
  return (
    <>
      <div
        id="floatContainer1"
        className={`${errorClassName} ${className} ${singleLine ? 'float-container-line' : 'float-container'} h-14 ${errors && errors[name] && !defaultValue
          ? singleLine
            ? 'input_box_error_line h-14'
            : 'input_box_error h-14'
          : errors && errors[name] && defaultValue
            ? singleLine
              ? 'input_box_error_line active'
              : 'input_box_error active'
            : disabled === true
              ? singleLine
                ? 'disabled_box_line'
                : 'disabled_box'
              : defaultValue
                ? 'active'
                : ''
          }`}
      >
        {!hideLabel && (
          <label htmlFor={name} className={`${labelClass}`}>
            {(resources && resources[label]) || label}
            {Mandatory && <span className="text-red-600 ml-1">*</span>}
          </label>
        )}
        {showInputIcon && (
          <img
            className="absolute top-15 left-10"
            src={require(`../assets/${inputImgPath}`)}
          />
        )}
        <input
          id={name}
          type={type}
          name={name}
          //value={value}
          disabled={disabled}
          data-placeholder={(resources && resources[placeholder]) || placeholder}
          maxLength={maxLength}
          //className={`${className} ${errors[name] ? 'input_box_error' : ''}`}
          className={`${className} ${showInputIcon ? ' input-padding' : ''} ${masked ? 'mask' : ''}`}
          onChange={(e) => onChange(e.target.value)}
          ref={register(validator)}
          defaultValue={defaultValue}
          onKeyPress={(e) => restrictInput && onKeyPress(e)}
          onBlur={(e) => enableBlurEvent && onBlur(e.target.value)}
          autoFocus={isAutoFocus}
        />
        {eyeIcon && (
          <img
            className={imgOnCkick ? 'Password hidePassword' : 'Password showPassword'}
            src={imgOnCkick ? require('../assets/hide.png') : require('../assets/show.png')}
            onClick={(e) => onClick(e.target.value)}
          />
        )}
        {/* { errors[name] && errors[name].type == 'required' && <p className={`${errorClass ? errorClass : ''} error-text`}>{errorMessages('required', label, validator, resources)}</p>} */}
      </div>
      {errors && errors[name] && errors[name].type == 'required' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('required', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'maxLength' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('maxLength', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'onlySpace' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('onlySpace', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'onlyChar' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('onlyChar', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'alpha' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('alpha', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'validatehyphen' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('validatehyphen', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'email' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('email', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'numeric' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('numeric', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'minLength' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('minLength', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'invalidMobilenum' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('invalidMobilenum', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'numberlength' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('numberlength', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'invalidExpiryDate' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('invalidExpiryDate', label, validator, resources)}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'password' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {' '}
          {errorMessages('password', label, validator, resources)}{' '}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'emailormobile' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {' '}
          {errorMessages('emailormobile', label, validator, resources)}{' '}
        </p>
      )}
      {errors && errors[name] && errors[name].type == 'checkBatteryCapacity' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('checkBatteryCapacity', label, validator, resources)}
        </p>
      )}
      {showCustomErrorMessage && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {customErrorMessage}
        </p>
      )}
    </>
  )
}

export const FloatLabelTextAreaField = ({
  type,
  name,
  label,
  hideLabel,
  placeholder,
  disabled,
  className,
  onChange,
  onClick,
  register,
  errors,
  schema,
  customeValidator,
  touched,
  dirtyFields,
  Mandatory,
  labelClass,
  defaultValue,
  restrictInput,
  onKeyPress,
  errorClass,
  onBlur,
  enableBlurEvent,
  form,
  cols,
  rows,
  readonly,
  required,
  wrap,
  maxlength,
  value,
}) => {
  const { state } = useContext(store)
  const { resources } = state
  const validator = customeValidator || Validator(schema, name)
  return (
    <div className={`${className}`}>
      {!hideLabel && (
        <label htmlFor={name} className={`${labelClass}`}>
          {(resources && resources[label]) || label}
          {Mandatory && <span className="text-red-600 ml-1">*</span>}
        </label>
      )}
      <textarea
        id={name}
        name={name}
        value={value}
        rows={rows} //Specifies the visible number of lines in a text area
        cols={cols} //Specifies the visible width of a text area
        disabled={disabled}
        readOnly={readonly}
        required={required}
        maxLength={maxlength}
        wrap={wrap}
        placeholder={(resources && resources[placeholder]) || placeholder}
        // className={`focus:border-purple-1 ${className} ${errors[name] ? 'input_box_error' : ''}`}
        className={`${labelClass}`}
        onChange={(e) => onChange(e.target.value)}
        ref={register(validator)}
        defaultValue={defaultValue}
        onKeyPress={(e) => restrictInput && onKeyPress(e)}
        onBlur={(e) => enableBlurEvent && onBlur(e.target.value)}
      />
      {errors[name] && errors[name].type == 'required' && (
        <p className={`${errorClass ? errorClass : ''} error-text`}>
          {errorMessages('required', label, validator, resources)}
        </p>
      )}
    </div>
  )
}

// export const RenderSelectField = ({
//   options, removeDefaultOption, hideLabel, labelClass, optionsKey, optionsValue, register, onChange, label, className, name, Mandatory, disabled, multiple, value, errors, schema,
// }) => {
//   const { state } = useContext(store)
//   const resources = state.resources
//   const validator = Validator(schema, name)
//   return (
//     <>

//       <label htmlFor={name} className={`${labelClass} text-base leading-19 text-black`}>
//         {resources && resources[label] || label}
//         {' '}
//         {Mandatory && <span className="text-red-600 ml-1">*</span>}
//       </label>
//       <select
//         name={name}
//         value={value}
//         ref={register(validator)}
//         className={`box-border border border-solid border-grey-color rounded-5 mt-2 focus:ring-2 focus:border-purple focus:outline-none focus:border-transparent  ${className} ${errors[name] ? 'input_box_error' : ''}`}
//         disabled={disabled}
//         onChange={(e) => onChange(e.target.value)}
//       >
//         {!removeDefaultOption && <option value="">Select</option>}
//         {options.map((option, i) => (
//           <option key={i} selected={value === option[optionsKey]} value={option[optionsKey]}>
//             {option[optionsValue]}
//           </option>
//         ))}
//       </select>
//     </>
//   )
// }

// export const FloatLabelSelectField = ({
//   options, removeDefaultOption, hideLabel, labelClass, wrapperClass = "", optionsKey, optionsValue, register, onChange, label, className, name, Mandatory, disabled, multiple, value, errors, schema,
// }) => {
//   const { state } = useContext(store)
//   const resources = state.resources
//   const validator = Validator(schema, name)
//   useEffect(() => {
//     FloatLabel.init();
//   }, [])
//   return (
//     <div id="floatContainer1" className={`${className} ${errors[name] ? 'float-container input_box_error h-14 m-1' : 'float-container h-14 m-1'}`}>
//       <label htmlFor={name} className={`${labelClass}`}>
//         {resources && resources[label] || label}
//         {' '}
//         {Mandatory && <span className="text-red-600 ml-1">*</span>}
//       </label>
//       <select
//         name={name}
//         value={value}
//         ref={register(validator)}
//         //className={`focus:border-purple-1 ${className} ${errors[name] ? 'input_box_error' : ''}`}
//         disabled={disabled}
//         onChange={(e) => onChange(e.target.value)}
//       >
//         {<option value=""></option>}
//         {options.map((option, i) => (
//           <option key={i} selected={value === option[optionsKey]} value={option[optionsKey]}>
//             {option[optionsValue]}
//           </option>
//         ))}
//       </select>
//     </div>
//   )
// }
export const RenderReactSelectField = ({
  options,
  optionsKey,
  optionsValue,
  optionsDisabled,
  defaultValue,
  register,
  onChange,
  label,
  className,
  name,
  Mandatory,
  disabled,
  value,
  errors,
  schema,
  hideLabel,
  isSearchable,
  placeholder,
  labelClass,
  fieldName,
  control,
  onValueChange,
  required,
  isLoading,
  showIndicator = true,
  isOptionDisabled,
  stateData,
  placeholdercolor,
  menuPlacement,
  font,
  selectComponentKey = "selectComponentKey",
  isHidePlaceholderOnSelect = false
}) => {
  let refContainer = React.useRef(null)
  const state = stateData || useContext(store).state
  const { resources } = state
  const validator = Validator(schema, name)
  const displayOptions = []
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      bottom: 0,
      width: '-webkit-fill-available',
      marginBottom: 10,
      position: 'absolute',
      height: 20,
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      display: isHidePlaceholderOnSelect && state.hasValue ? 'none' : 'block',
      top: state.hasValue || state.selectProps.inputValue || state.isFocused ? '3px' : '50%',
      transition: state.hasValue === true ? '' : 'top 500ms, font-size 500ms',
      fontSize: (state.hasValue || state.selectProps.inputValue || state.isFocused) && 11,
      color:
        state.hasValue || state.selectProps.inputValue || state.isFocused
          ? placeholdercolor
            ? placeholdercolor
            : '#6E767D'
          : 'gray',
    }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: state.isFocused || state.hasValue ? '#4d0099' : 'white',
        color: 'white',
        font
      },
      backgroundColor: state.isSelected ? 'white' : 'white',
      color: 'black'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: 'visible',
    }),
    dropdownIndicator: (provided, state) => ({
      display: 'flex !important',
    }),
  }
  options &&
    options.map((d) => {
      displayOptions.push({
        label: d[optionsKey],
        value: d[optionsValue],
        disabled: d[optionsDisabled],
      })
    })

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img
            src={require('../assets/chevron-down.png')}
            className={props.selectProps.menuIsOpen ? 'transform rotate-180 transition-all pl-3' : 'transition-all pr-3'}
          />
        </components.DropdownIndicator>
      )
    )
  }

  const dropDownArrow = (indicator) => {
    if (!indicator) return { DropdownIndicator: () => null }
    else return { DropdownIndicator }
  }
  const handleBlur = () => {
    let containerEl = refContainer.current
    containerEl.classList.remove('active')
    containerEl.querySelector('.flotingSelectPlaceholder').style.color = 'gray'
  }
  const handleFocus = () => {
    let containerEl = refContainer.current
    if (![...containerEl.classList].includes('active')) containerEl.classList.add('active')
    containerEl.querySelector('.flotingSelectPlaceholder').style.color = 'gray'
  }
  return (
    <div
      id="floatContainer1"
      ref={refContainer}
      className={` selectDropdown ${className} ${errors[name]
        ? 'float-container input_box_error h-14'
        : value
          ? 'float-container pl-0 pr-0 active h-14'
          : 'float-container pl-0 pr-0 h-14'
        }`}
    >
      {!hideLabel && (
        <label htmlFor={name} className={`${labelClass}`}>
          {(resources && resources[label]) || label}
          {Mandatory && <span className="text-red-600 ml-1">*</span>}
        </label>
      )}
      <Controller
        name={fieldName}
        render={({ onChange, onBlur, value, name }) => (
          <Select
            className={`box-border rounded-lg  ${className}  ${errors[name] ? '' : ''}`}
            onBlur={(() => onBlur(), () => handleBlur())}
            onFocus={() => handleFocus()}
            isDisabled={disabled}
            isOptionDisabled={isOptionDisabled}
            onChange={((e) => onChange(fieldName, e), (e) => onValueChange(fieldName, e))}
            defaultValue={
              displayOptions && state && state[fieldName] && displayOptions.find((i) => i.value === state[fieldName])
            }
            value={
              displayOptions && state && state[fieldName] && displayOptions.find((i) => i.value === state[fieldName])
            }
            name={fieldName}
            styles={customStyles}
            options={displayOptions}
            placeholder={placeholder}
            isSearchable={isSearchable}
            openAfterFocus={true}
            menuPlacement={menuPlacement}
            components={{
              ValueContainer: CustomValueContainer,
              // DropdownIndicator:() => null,
              LoadingIndicator: null,
              ...dropDownArrow(showIndicator),
            }}
            isLoading={isLoading ? true : false}
          />
        )}
        control={control}
        rules={validator}
      />
      {/* <Select
        className={`box-border rounded-5 mt-3 ${className}  ${errors[name] ? 'input_box_error' : ''}`}
        classNamePrefix="select"
        isDisabled={disabled}
        isClearable={false}
        isSearchable={isSearchable}
        components={{
          ValueContainer: CustomValueContainer,
          DropdownIndicator:() => null
        }}
        name={name}
        value={value}
        styles={style}
        // menuIsOpen={true}
        placeholder={(resources && resources[placeholder]) || placeholder}
        options={displayOptions}
        onChange={(e) => onChange(e)}
        ref={register(validator)}
      /> */}
    </div>
  )
}

export const RenderCardField = ({
  containerClassName,
  labelClassName,
  label,
  name,
  labelClass,
  required,
  schema,
  fieldName,
  inputClassName,
  className,
  value,
  Mandatory,
  inputType,
  type,
  placeholder,
  onChange,
  register,
  errors,
  tooltip,
  hideLabel,
  defaultValue,
  values,
  tooltipInfo,
  disabled,
  isSearchable,
  displayOptions,
  showCreditCardNumberIcon,
  showNameOnCardIcon,
  resources,
  control,
  onBlur,
  onValueChange,
  onKeyPress,
  restrictType,
  maxLength,
  onTouchStart,
  cardOptions,
  showInputIcon,
  inputImgPath
}) => {
  const validator = Validator(schema, fieldName)
  const { state } = useContext(store)
  useEffect(() => {
    FloatLabel.init()
  }, [])
  if (state[fieldName]) {
    const floatContainers = document.querySelectorAll('.cardField')
    floatContainers.forEach((element) => {
      if (element.querySelector('input,select,textarea').value) {
        element.classList.add('hasValue')
        element.removeAttribute('placeholder')
      }
    })
  }
  return (
    <>
      <div
        id="floatContainer1"
        className={`${className} cardField float-container h-14 ${errors[name] && !defaultValue
          ? 'input_box_error h-14'
          : errors[name] && defaultValue
            ? 'input_box_error active'
            : defaultValue
              ? 'active'
              : ''
          }`}
      >
        {label ? (
          <>
            {!hideLabel && (
              <label htmlFor={name} className={`${labelClass}`}>
                {(resources && resources[label]) || label}
                {Mandatory && <span className="text-red-600 ml-1">*</span>}
              </label>
            )}
          </>
        ) : (
          <></>
        )}
        <Controller
          name={fieldName}
          render={({ onBlur, value, name }) => (
            <Cleave
              className={`w-full px-2 py-2 leading-tight text-gray-700 border
          ${errors && errors[fieldName] ? 'rounded-t-md border-error' : 'rounded-md focus:border-purple'}
          appearance-none md:pl-3 md:pr-2 md:py-3 focus:outline-none
          ${inputClassName || 'w-full'}`}
              onBlur={onBlur}
              onChange={((e) => onChange(fieldName, e.target.value), (e) => onValueChange(fieldName, e.target.value))}
              //onChange={(e) => onChange(e.target.value)}
              defaultValue={state && state[fieldName]} // to solve first keystroke is not working
              value={state && state[fieldName]}
              name={fieldName}
              data-placeholder={(resources && resources[placeholder]) || placeholder}
              options={cardOptions}
              id={name}
              type={type}
            />
          )}
          control={control}
          rules={validator}
        />
        {showInputIcon && (
          <img
            className="absolute top-10 right-10 h-30"
            src={require(`../assets/${inputImgPath}`)}
          />
        )}
      </div>
      {errors[fieldName] && errors[fieldName].type === 'required' && (
        <p className="error-text">{errorMessages('required', label, validator, resources)}</p>
      )}
      {errors[fieldName] && errors[fieldName].type === 'cardLength' && (
        <p className="error-text">{errorMessages('cardLength', label, validator, resources)}</p>
      )}
      {errors[fieldName] && errors[fieldName].type === 'creditCard' && (
        <p className="error-text">{errorMessages('creditCard', label, validator, resources)}</p>
      )}
      {errors[fieldName] && errors[fieldName].type == 'invalidExpiryDate' && (
        <p className={`error-text`}>
          {errorMessages('invalidExpiryDate', label, validator, resources)}
        </p>
      )}
    </>
  )
}

export const DateInputField = ({ value, onChange, placeholder, format, disabled, minDate, maxDate, className }) => {
  const { state } = useContext(store)
  const { resources } = state
  const range = (start, end, step = 1) => {
    const len = Math.floor((end - start) / step) + 1
    return Array(len)
      .fill()
      .map((_, idx) => start + idx * step)
  }

  const years = range(1900, new Date().getFullYear() + 1, 1)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const setDate = (date) => {
    let pDate
    if (date) pDate = moment(date).format('YYYY-MM-DD')
    onChange(pDate)
  }

  return (
    <>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {'<'}
            </button>
            <select value={date && date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[date && date.getMonth()]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {'>'}
            </button>
          </div>
        )}
        onChange={(date) => setDate(date)}
        placeholderText={resources && resources[placeholder]}
        selected={value ? moment(value, 'YYYY-MM-DD').toDate() : ''}
        dateFormat={format}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        className={className}
      />
    </>
  )
}

export const RenderAsyncSelectField = ({
  label,
  className,
  name,
  Mandatory,
  value,
  errors,
  schema,
  hideLabel,
  placeholder,
  labelClass,
  fieldName,
  control,
  isLoading,
  showIndicator = true,
  stateData,
  placeholdercolor,
  menuPlacement,
  defaultInputValue,
  getOptionLabel,
  getOptionValue,
  loadOptions,
  onInputChange,
  handleChange,
  isDisabled,
  debounceTimeout,
  setError
}) => {
  let refContainer = React.useRef(null);
  const state = stateData || useContext(store).state
  const { resources } = state
  const validator = Validator(schema, name)
  useEffect(() => {
    let containerEl = refContainer.current;
    containerEl.querySelector('.flotingSelectPlaceholder').innerHTML = `<div>Address <span style="color:red">*</span></div>`
  }, [])

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      bottom: 0,
      width: '-webkit-fill-available',
      marginBottom: 10,
      position: 'absolute',
      height: 20,
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue || state.isFocused ? "11px" : "50%",
      transition: state.hasValue === true ? "" : "top 500ms, font-size 500ms",
      fontSize: (state.hasValue || state.selectProps.inputValue || state.isFocused) && 11,
      color: (state.hasValue || state.selectProps.inputValue || state.isFocused) ? (placeholdercolor) ? placeholdercolor : '#6E767D' : 'black'
    }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: '#5E6F77',
        color: 'white'
      },
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? '#5E6F77' : 'white'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible",
      maxWidth: '90%'
    }),
    dropdownIndicator: (provided, state) => ({
      display: "flex !important"
    }),
    clearIndicator: (provided, state) => ({
      cursor: 'pointer',
      height: '30px',
      color: '#5E6F77',
      marginRight: '10px'
    })
  }

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img src={require('../assets/chevron-down.png')}
            className={props.selectProps.menuIsOpen ? "transform rotate-180 transition-all" : "transition-all"} />
        </components.DropdownIndicator>
      )
    );
  };

  const dropDownArrow = (indicator) => {
    if (!indicator)
      return { DropdownIndicator: () => null };
    else
      return { DropdownIndicator };
  }
  const handleBlur = () => {
    let containerEl = refContainer.current;
    containerEl.classList.remove("active");
    containerEl.querySelector(".flotingSelectPlaceholder").style.color = "#5E6F77";
  }
  const handleFocus = () => {
    let containerEl = refContainer.current;
    if (![...containerEl.classList].includes("active")) containerEl.classList.add("active");
    // containerEl.querySelector('. css-1rc1fn-placeholder').innerHTML = `<div>This text is set using innerHTML <span style="color:red">*</span></div>`
    containerEl.querySelector(".flotingSelectPlaceholder").style.color = "#5E6F77";
    containerEl.querySelector(".flotingSelectPlaceholder").style.fontSize = "11px";
  }
  return (
    <div id="floatContainer1" ref={refContainer} className={`asyncdropdown ${className} ${setError ? 'input_box_error' : ''} ${errors?.[name] ? 'float-container input_box_error h-44 bg-white' : value ? 'float-container pl-0 pr-0 active h-44  bg-white' : 'float-container pl-0 pr-0 h-44 bg-white'}`}>
      {!hideLabel && (
        <label htmlFor={name} className={`${labelClass}`}>
          {resources && resources[label] || label}
          {Mandatory && <span className="text-red-600 ml-1">*</span>}
        </label>
      )
      }
      <Controller
        name={fieldName}
        render={({ onChange, onBlur, value, name }) => (
          <AsyncPaginate
            name={fieldName}
            styles={customStyles}
            cacheOptions
            defaultOptions
            //isClearable={true}
            value={value}
            defaultInputValue={defaultInputValue}
            isSearchable={true}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loadOptions={loadOptions}
            onInputChange={onInputChange}
            onChange={handleChange}
            isDisabled={isDisabled}
            placeholder={placeholder}
            className={`box-border rounded-5  ${className}  ${errors?.[name] ? '' : ''}`}
            onBlur={() => onBlur(), () => handleBlur()}
            onFocus={() => handleFocus()}
            openAfterFocus={true}
            menuPlacement={menuPlacement}
            components={{
              ValueContainer: CustomValueContainer,
              // DropdownIndicator:() => null,
              LoadingIndicator: null,
              ...dropDownArrow(showIndicator)
            }}
            isLoading={isLoading ? true : false}
            debounceTimeout={debounceTimeout}
            autoComplete='disabled'
          />
        )}
        control={control}
        rules={validator}
      />
    </div>
  )
}
export default FloatLabelInputField
