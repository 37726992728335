import React, { useState, useEffect } from 'react';
import IconDownArrow from '../../Icons/icon-downwardarrow'
import IconExpand from '../../Icons/icon-expand'

const FAQList = (props) => {
    const { resources, state, actions } = props;
    const { FAQ } = state;
    const [clicked, setClicked] = useState(false);
    const toggle = (index, flag) => {
        const _FAQ = [...FAQ];
        _FAQ[index].isopen = flag;
        actions.setFAQ(_FAQ)
    };

    return (
        <div> <div className="flex p-p14 my-10 md:w-auto w-900 flex items-center justify-center mx-auto">
            <div className="font-extrabold md:text-2xl text-40 leading-54 flex justify-center text-center px-2.5">
                <div className='text-center'><b>Frequently Asked Questions</b></div>
            </div>
        </div>
        <div className='flex items-center justify-center mb-m30'>
            <div className='bg-white md:w-full w-820'>
                <hr className="w-auto border-HN-Gray-Dark"></hr>
                {FAQ && FAQ.map((section, index) => {
                   return (
                    <div className=''>
                        <div className='flex flex-row items-center justify-between p-p20 font-extrabold'><div className='md:w-270 w-745'>{section.Question}</div>
                            <div className='float-right'>{section.isopen === 1 ? <IconDownArrow onClick={() => toggle(index,0)} /> : <IconExpand onClick={() => toggle(index,1)} />} </div>
                        </div>
                        {section.isopen === 1 ? (
                            <div>
                                <div className='px-p20 pb-p20' dangerouslySetInnerHTML={{ __html: section.Answer }}></div>
                            </div>)
                            : null
                        }
                        <hr className="w-auto border-HN-Gray-Dark"></hr>


                    </div>
                    )
                })}
            </div>
            </div>
        </div>
    )
}
export default FAQList


