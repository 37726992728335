import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ label, disabled, type, onClick, bgColor, mainbgcolor, marginClass, paddingClass, borderClass, textClass, disabledBgColor, className }) => (
  <div className={`text-center ${marginClass} ${paddingClass} ${className}`}>
    <button
      type={type}
      className={`w-full font-SamsungOne-700  ${borderClass} ${mainbgcolor} text-center py-2 ${textClass}  ${
        disabled ? `cursor-not-allowed ${disabledBgColor}` : `cursor-pointer ${bgColor}`
      }`}
    // style={{backgroundColor: !disabledBgColor && '#6B4E9F'}}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </button>
  </div>
)

export default Button

Button.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
  marginClass: PropTypes.string,
  paddingClass: PropTypes.string,
  borderClass: PropTypes.string,
  textClass: PropTypes.string,
  className: PropTypes.string,
}

Button.defaultProps = {
  label: 'Submit',
  type: 'button',
  disabled: true,
  onClick: () => {},
  bgColor: '',
  paddingClass: '',
  marginClass: '',
  borderClass: 'b-2',
  textClass: 'text-white px-6',
  className: '',
}
