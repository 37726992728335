import React from 'react'
import DOMPurify from 'dompurify'

const FloatingLabelInput = ({ type, name, label, placeholder, className, onChange, value, required, labelClassName, id, errorMsg, error, ...props }) => {
  return (
    <>
      <div class={`${className}`}>
        <label for={id} class={` ${labelClassName} text-HN-Grey-Lighter text-sm ml-2`}>
          {placeholder}
        </label>
        <input
          name={name}
          value={value}
          onChange={(e) => onChange(DOMPurify.sanitize(e.target.value))}
          type={type}
          id={id}
          class={`appearance-none block w-full  border rounded py-4 px-4 mt-m3  leading-tight focus:outline-none bg-white`}
          autocomplete="off"
          placeholder=" "
          {...props}
        />
        
      </div>
      {error && <span className="font-SamsungOne-400 text-red-600 text-sm mt-1 ml-1">{errorMsg}</span>}
    </>
  )
}

export default FloatingLabelInput
