import React from 'react'
import Popup from 'reactjs-popup'
import IconRemove from "../../Icons/icon-remove-circle"
import Iconclose from "../../Icons/icon-closesmall"
import { getResource } from '../../utils/helper';


const FailurePopUp = (props) => {
    const { resources, state, actions } = props
    const { openFailurePopup, closePopUp } = props
    let isMobile = window.innerWidth < 768
    const contentStyleForDesktopView = {
        padding: '0px',
        alignSelf: 'center',
        width: isMobile ? '80%' : 'auto',
        height: '220px',
        position: isMobile ? 'absolute' : 'relative',
        'margin-top': '150px',
        margin: 'auto',
        inset: 0
    }

    const closeClick = () => {
        closePopUp()
    }


    return (
        <Popup
            contentStyle={contentStyleForDesktopView}
            open={openFailurePopup}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            repositionOnResize={false}
            modal
        >
            <div className="bg-white w-330 md:w-full border border-gray-100 rounded-2xl -ml-4 md:-ml-0">
                <div className='pl-60 md:ml-8 mt-2 ml-16'>
                    <Iconclose className="" onClick={() => closeClick()} />
                </div>
                <div className=" pt-2 flex items-center justify-center mr-18 my-3">
                    <IconRemove className="mx-auto mb-m30" onClick={() => closeClick()} />
                </div>
                <b> <p className='text-center text-xl text-HN-Red-Dark '>{getResource(resources, 'FAILED')}</p></b>
                <div className="justify-center  pt-2 px-2 pb-5">
                    <p className="text-base text-center">{getResource(resources, 'UNABLE_TO_CAPTURE')}</p>
                    <p className="text-base text-center">{getResource(resources, 'PLEASE_TRY_AGAIN')} </p>
                </div>
            </div>
        </Popup>
    )
}

export default FailurePopUp
