import axios from 'axios'
import types from './ActionTypes'

import { getHmacTokenAndTimestamp } from './helper/hmacHelper'

let hmacTokenAndTimestamp = getHmacTokenAndTimestamp()
// console.log('hmacTokenAndTimestamp---',hmacTokenAndTimestamp)
let _headers = {
  'Accept': 'application/json',
  'Asurion-channel': 'trade',
  'Asurion-enduser': 'HNWebsiteUser',
  'Asurion-lineofbusiness': 'MOBILITY',
  'Asurion-region': 'APAC-ANZ',
  'Content-Type': 'application/json',
  'x-api-key': process.env.API_KEY,
  'Asurion-client': 'harvey_norman',
  'Accept-Language': 'en-US',
  'Asurion-application' : process.env.REACT_APP_TYPE,
}

const http = axios.create({
  baseURL: '/',
  // baseURL: 'http://localhost:3000/dev/',
  responseType: 'json',
})

http.interceptors.request.use(async (config) => {
  if(config?.url?.includes("getsecuritytoken"))
  {
   _headers['Asurion-application'] = 'trade'
  }
  let sessionToken = localStorage.getItem("token")
  let headers = {
    ..._headers,
    Authorization: `Bearer ${sessionToken}`
  }
  config.headers = { ...config.headers, ...headers };
  return config;
}, (error) => {
  return Promise.reject(error);
});

const http1 = axios.create({
  baseURL: '/',
  // baseURL: 'http://localhost:3000/dev/',
  responseType: 'json',
})

http1.interceptors.request.use(async (config) => {
  let headers = {
    ..._headers,
    'x-ct-authorization': hmacTokenAndTimestamp.hmacToken,
    'x-ct-timestamp': hmacTokenAndTimestamp.hmacTimestamp,
  }
  config.headers = { ...config.headers, ...headers };
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const setAuthToken = token => {
  if (token) {
      axios.defaults.headers["Authorization"] = `Bearer ${token}`;
  }
  else
      delete axios.defaults.headers["Authorization"];
}

const API = {}

API[types.GET_MASTER_DATA_REQUEST] = (data) => http.get(`/api/web/v3/masterdata`)
API[types.SEND_OTP_REQUEST] = (data) => http.post(`/api/web/v3/sendotp`, data)
API[types.VALIDATE_OTP_REQUEST] = (data) => http.post(`/api/web/v3/verifyotp`, data)
API[types.CREATE_QUOTE_REQUEST] = (data) => http.post(`/api/web/v3/createquoteweb`, data)
API[types.UPDATE_CUSTOMER_REQUEST] = (data) => http.put(`/api/web/v3/updatecustomer`, data)
API[types.SEND_EMAIL_REQUEST] = (data) => http.post(`/api/web/v3/sendwebemail`, data)
API[types.GET_PRESIGN_URL_FOR_UPLOAD_REQUEST] = (data) => http.get(`/api/web/v3/getwebpresignurl/` + data.tradeId + `/` + data.imageName + '?method=' + data.method)
API[types.STANDARDIZE_ADDRESS_REQUEST] = (data) => http.post(`/api/web/v3/standardizeaddress`, data)
API[types.GET_DO_GET_ADDRESS] = (data) => http.post(`/api/web/v3/dogetaddressqas`, data)
API[types.UPDATE_IMEI_REQUEST] = (data) => http.post(`/api/web/v3/updatewebimei`, data)
API[types.GET_PRESIGN_URL_2] = (data) => http.get(`/api/web/v3/getwebpresignurl/` + data.tradeId + `/` + data.imageName + '?method=' + data.method)
API[types.GET_QR_CODE_PRESIGN_URL] = (data) => http.get(`/api/web/v3/getwebpresignurl/` + data.tradeId + `/` + data.imageName + '?method=' + data.method + '&documentType=Qr_Code')
API[types.SET_UPDATE_METHOD] = (data) => http.put(`/api/web/v3/trademethods`, data);
API[types.GET_PCITOKEN] = (data) => http.post(`/api/web/v3/getsecuritytoken/${data?.tradeInfoId}?cauth=MUVENjU&amount=${data?.amount}`);
API[types.COLLECT_PAYMENT_REQUEST] = (data) => http.post(`/api/web/v3/collectpayment`, data);
// Self-Serve API's
API[types.SEND_SS_OTP_REQUEST] = (data) => http.post(`/api/selfserv/v4/sendotp`, data);
API[types.VERIFY_SS_OTP_REQUEST] = (data) => http.post(`/api/selfserv/v4/verifyotp`, data);
API[types.Get_ALL_SS_TRADES] = (data) => http.post(`/api/selfserv/v4/getalltrades`, data);
API[types.GET_SS_TRADE_DETAILS]  = (data) => http.post(`/api/selfserv/v4/trade/details`, data);
API[types.CANCEL_SS_TRADE_DETAILS]  = (data) => http.put(`/api/selfserv/v4/trade/cancel`, data);
API[types.RETURN_SS_LABEL]  = (data) => http.get(`/api/selfserv/v4/returnlabel/` + data.tradeId);

API[types.CREATE_HYPER_WALLET_USER] = (data, token) => http.post('/api/selfserv/v1/createuser', data)
API[types.CREATE_TRANSFER_METHOD] = (data, token) => http.post('/api/selfserv/v1/createtransfermethod', data)
API[types.CREATE_PAYOUT] = (data, token) => http.post('/api/selfserv/v1/createpayout', data)


export default API