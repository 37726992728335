import React, { useState } from "react";
import Checkbox from "../Common/Checkbox";
import ActionTypes from "../../ActionTypes";
import API from "../../services";
import { useHistory } from "react-router-dom";
import Iconback from '../../Icons/icon-back'

const PaymentConfirmation = (props) => {
  const history = useHistory()
  const { state, actions } = props
  const { CreateQuoteResponse, QuotePhone, QuoteDevice, SelectedDevice, DeviceType, isBGBT, paymentMethod } = state
  const [confirmed, setConfirmed] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const handleCollectPayment = () => {
    actions.setLoader();
    API[ActionTypes.COLLECT_PAYMENT_REQUEST]({ tradeId: CreateQuoteResponse?.tradeInfoId, paymentMethod: paymentMethod })
      .then((response) => {
        actions.setCollectPayment(response?.data);
        history.replace("/summary")
        actions.unSetLoader();
        setPaymentError(false);
      })
      .catch((error) => {
        setPaymentError(true);
        actions.unSetLoader();
      });
  };
const navigateBack=()=>{
  isBGBT === true ? history.replace('/addcardnew') : history.replace('/addcard')
}
  return (
    <div className="flex flex-col items-center">
    <div className="flex pt-6 px-p20 max-w-3xl w-full mx-auto">
            <div className='md:pt-1 pt-3'>
                <Iconback onClick={() => navigateBack()} />
            </div>
            <div className="text-bold md:text-xl mx-auto text-3xl leading-54 text-center">
                <b>Security Charge</b>
            </div>
    </div>
    <div id="paymentConfirmation" className={`max-w-2xl mx-auto  w-full flex flex-col `}>
    
    
    <div className="bg-white p-p20 mx-m20 my-m42  flex flex-col">
    <p className="font-bold text-center pb-0 pt-p40 text-base">
    {SelectedDevice?.[0]?.AssetCatalogName}
      </p>
      <p className="text-center py-1 text-sm">
        Security Charge
      </p>
      <p
        className="font-medium text-center pb-2 pt-0 text-HN-Purple-Dark text-2xl"
      >
        ${CreateQuoteResponse?.quotePrice || 0}
      </p>
    <p className="text-center py-p15 pb-1 font-bold text-base" >
        Payment details confirmed
    </p>
      <Checkbox
        isChecked={confirmed}
        onChange={setConfirmed}
        label="I understand that by continuing I will be charged the Security Charge (set out above) and I authorise Asurion to process this payment."
        className="py-4 text-sm"
        btnClass="flex-grow flex-shrink-0"
      />
      {paymentError && 
      <p className="text-sm text-HN-Red-Dark pb-4 text-center">
        Payment failed. Try again, or use a different payment method.
      </p>
      }
      <button   className={`${
                confirmed ? 'primary-btn bg-HN-Purple-Dark' : 'disabled-primary-btn bg-HN-Gray-Dark '
            } text-white mb-m20 max-w-xs w-full mx-auto`}
       onClick={() => {
          handleCollectPayment();
        }}
        disabled={!confirmed}
        >
          Pay now
          </button>
    </div>
</div>
</div>
        );
};

export default PaymentConfirmation;
