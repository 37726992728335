import React, {useState} from 'react';
import { isEmpty } from "../../utils/helper";
// import sanitizeHtml from 'sanitize-html';


const Input = ({
  type,
  isMandatory,
  label,
  value,
  disabled,
  onChange,
  setFocus,
  focus,
  placeholder,
  className,
  wrapperClassName,
  labelClassName,
  error = false,
  errorMessage = "error",
  ...props
}) => {
  const [isFocused,setIsFocused] = useState(false)
  const handleInputChange = (e) => {
    // const santizedValue = sanitizeHtml(e.target.value);
    onChange(e.target.value, error);
  };
  return (
    <div className={`flex flex-col w-full ${wrapperClassName} relative`}>
      <label
        htmlFor={label}
        className={`absolute transition-all ${ (value || isFocused) && 'text-xs'}  ${
          (isEmpty(value) && !isFocused) && "pt-3"
        } text-HN-Grey-Light ${labelClassName}`}
      >
        {label}
        {isMandatory && <span className="pl-1">*</span>}
      </label>
      <input
        {...props}
        type={type}
        onWheel={() =>  false}
        name={label}
        id={label}
        value={value}
        onChange={handleInputChange}
        disabled={disabled}
        className={`relative focus: bg-transparent border-b ${
          error ? "border-b-brand-mandatory" : "border-b-brand-border"
        } pt-2 outline-none ${className}`}
        onFocus={()=>{setIsFocused(true)}}
        onBlur={()=>{setIsFocused(false)}}
      />
      {error && (
        <span className="text-HN-Red-Dark text-sm">{errorMessage}</span>
      )}
    </div>
  );
};

Input.defaultProps = {
  type: "text",
  isMandatory: false,
  disabled: false,
  value: "",
  label: "Please enter values",
  placeholder: "Write something here...",
  onChange: undefined,
  className: "",
  wrapperClassName: "",
  labelClassName: "",
};

export default Input;
