import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Arrow from '../../assets/Light.svg'
import { getResource } from '../../utils/helper'
import { FloatLabelInputField } from '../../utils/custom-fields'
import { useHistory } from 'react-router-dom'

const DeviceSerialNumber = (props) => {
  const { resources, state, actions } = props
  let { serialno, CreateQuoteResponse, DeviceType, SelectedDevice } = state

  const [invalidError, setInvalidError] = useState(false)
  const { register, errors } = useForm({ mode: 'onChange' })
  const history = useHistory()
  useEffect(() => {
    setInvalidError(false)
  }, [errors && Object.keys(errors).length === 0])

  const nextClicked = () => {
    if (errors && Object.keys(errors).length === 0 && serialno) {
      let updateData = {
        SerialNo1: serialno,
        AssetId: CreateQuoteResponse?.AssetId,
      }
      actions.updateImei(updateData)
      history.replace("/securitychargefaq")
      // history.replace('/summary')
    } else {
      setInvalidError(true)
    }
  }
  const navigateBack = () => {
    actions.clearData('setSerialNo')
    history.replace('/uploadid')
  }
  let maxLength, schemaName, minLength
  let isFormValid = false
  if (DeviceType === 'Laptop') {
    maxLength = 15
    minLength = 8
    schemaName = 'macImeiDetails'
    isFormValid = serialno && serialno.length >= 8 && serialno.length <= 15
  } else if (DeviceType === 'Watch') {
    if (SelectedDevice?.[0]?.AssetMakeName?.toUpperCase() === 'APPLE') {
      maxLength = 12
      minLength = 8
      schemaName = 'macImeiDetails'
      isFormValid = serialno && serialno.length >= 8 && serialno.length <= 12
    } else {
      maxLength = 15
      minLength = 8
      schemaName = 'macImeiDetails'
      isFormValid = serialno && serialno.length >= 8 && serialno.length <= 15
    }
  } else if (DeviceType === 'Phone') {
    maxLength = 15
    schemaName = 'phoneImeiDetails'
    isFormValid = serialno && serialno?.length == 15
  } else if (DeviceType === 'Tablet') {
    maxLength = 15
    minLength = 8
    schemaName = 'macImeiDetails'
    isFormValid = serialno && serialno.length >= 8 && serialno.length <= 15
  } else {
    maxLength = 15
    minLength = 8
    schemaName = 'macImeiDetails'
    isFormValid = serialno && serialno.length >= 8 && serialno.length <= 15
  }

  let findImeiInstructions = "You can find your IMEI Number by going to Settings > About Device > IMEI or call this number: *#06#"

  return (
    <>
      <div className="w-700 mx-auto md:w-auto md:mx-m15">
        <div>
          <div className=" flex pt-6 pl-p5 md:pl-0">
            <div className="md:mt-0 mt-3 ">
              <Arrow onClick={() => navigateBack()} />
            </div>
            <div className="font-bold md:text-xl text-40 mx-auto px-p30">
              {DeviceType === 'Phone' ? 'Device IMEI' : getResource(resources, 'DEVICE_SERIAL_NUMBER')}
            </div>
            <div className="md:hidden block"></div>
          </div>
        </div>

        {DeviceType === "Phone" ?
          <div className="text-center pt-4 text-base flex fle-row justify-center items-center">
            <p className="text-center max-w-xs text-HN-Subtext-Light">
              {findImeiInstructions}
            </p>
          </div>
          :
          <div className="text-center pt-4 md:text-base text-2xl">
            {getResource(resources, 'ABOUT_YOUR_DEVICE')}
            <p className="text-center">
              <span>See our </span>
              <span className="text-HN-Purple-Dark underline cursor-pointer" onClick={() => window.open('/faq')}>
                FAQs
              </span>{' '}
            </p>
          </div>
        }
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1" />
        <div className="pt-10 w-345 mx-auto md:w-auto">
          <form>
            <FloatLabelInputField
              type="text"
              name="serialno"
              id="serialno"
              label={DeviceType === 'Phone' || DeviceType === "LVD Phone" ? 'Enter IMEI' : getResource(resources, 'SERIAL_NUMBER')}
              labelClass=""
              defaultValue=""
              placeholder={DeviceType === 'Phone' || DeviceType === "LVD Phone" ? 'Enter IMEI' : getResource(resources, 'SERIAL_NUMBER')}
              register={register}
              schema={schemaName}
              className="bg-white"
              maxLength={maxLength}

              // errors={errors}
              onChange={actions.setSerialNo}
            />
          </form>
          {errors?.serialno?.type && (DeviceType === 'Phone' || DeviceType === "LVD Phone" ?
            <div className="text-xs text-HN-Red-Dark leading-4 mt-2 mx-auto">
              {
                errors?.serialno?.type === 'numeric' ? `Device IMEI should be numeric only.` :
                  errors?.serialno?.type === 'minLength' ? 'Device IMEI should be atleast 15 digits long.' : ''

              }
            </div>
            :
            <div className="text-xs text-HN-Red-Dark leading-4 mt-2 mx-auto">
              {
                errors?.serialno?.type === 'alpha' ? `Device Serial Number should contain only digits and characters.` :
                  errors?.serialno?.type === 'minLength' ? 'Device Serial Number should be atleast 8 charaters long.' :
                    errors?.serialno?.type === 'maxLength' ? 'Device Serial Number should be atmost 15 charaters long.' : ''

              }
            </div>
          )
          }
          {invalidError && DeviceType !== 'Phone' && DeviceType !== 'Tablet' && DeviceType !== "LVD Phone" && DeviceType !== "LVD Tablet" && (
            <div>
              <div className="text-xs text-HN-Red-Dark leading-4 mt-2 mx-auto">
                Please enter a valid serial number to finalise your trade-in.
              </div>
              {DeviceType === 'Watch' ? (
                <div className="text-xs leading-4 ">
                  Here's{' '}
                  <a
                    href={
                      SelectedDevice?.[0]?.AssetMakeName?.toUpperCase() === 'Apple' ? process.env.IPHONE_SPECS_LINK : process.env.SAMSUNG_SPECS_LINK
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-HN-Purple-Dark underline"
                  >
                    how to find your Watch's serial number
                  </a>
                  .
                </div>
              ) : (
                <div className="text-xs leading-4 ">
                  Here's{' '}
                  <a
                    href={process.env.MAC_SERIALNO_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-HN-Purple-Dark underline"
                  >
                    how to find your MacBook's serial number
                  </a>
                  .
                </div>
              )}
            </div>
          )}
        </div>

        <div className="md:w-full w-345 mx-auto mt-m15">
          <button
            id="btn-continue"
            className={`${isFormValid ? 'primary-btn bg-HN-Purple-Dark' : 'disabled-primary-btn bg-HN-Gray-Dark '
              } text-white mt-2`}
            type="button"
            disabled={!isFormValid}
            onClick={() => nextClicked()}
          >
            {getResource(resources, 'COMMON_BTN_CONTINUE')}
          </button>
        </div>
      </div>
    </>
  )
}
export default DeviceSerialNumber
