import React from 'react'
import PropTypes from 'prop-types'
import Button from '../common/Button'

const ShipTrade = ({ handleCancel, closePopup, model }) => (
  <div className="w-full">
    <div className="mt-4 px-4">
      You are not required to return your trade-in device until you have received your new device from Samsung. Please continue if you would like to return your device now and we will email a return
      label and instructions to send the {model} that you are trading-in.
    </div>
    <div className="mt-2 px-4">Please note: If you wish to return a new device which you purchased from Samsung, please reach out to online support at 1300 362 603</div>
    <div className="w-full flex justify-center mt-8 mb-2">
      <Button disabled={false} label="Yes" type="" textClass="px-12 bg-white text-black hover:bg-black hover:text-white border-black border-2" onClick={handleCancel} marginClass="mr-4" />
      <Button label="No" className="" disabled={false} type="" marginClass="ml-4" textClass="px-12 border-2 border-black text-black bg-white hover:bg-black hover:text-white" onClick={closePopup} />
    </div>
  </div>
)

ShipTrade.defaultProps = {
  handleCancel: undefined,
  closePopup: undefined,
  model: '',
}

ShipTrade.propTypes = {
  closePopup: PropTypes.func,
  handleCancel: PropTypes.func,
  model: PropTypes.string,
}

export default ShipTrade
