import React  from 'react'
import { useHistory } from 'react-router-dom'
import Iconback from '../../Icons/icon-back'
import Accordian from '../Common/Accordian'
import FaqList from './FAQList'
import API from "../../services";
import ActionTypes from "../../ActionTypes"
 
const SecurityChargeFAQ = (props) => {
  const { state, actions } = props
  const { CreateQuoteResponse, isBGBT } = state
  const history = useHistory()

  const nextClicked = () => {
      actions.setLoader()
      API[ActionTypes.GET_PCITOKEN]({tradeInfoId: CreateQuoteResponse?.tradeInfoId, amount: CreateQuoteResponse?.quotePrice })
      .then((tokenResponse) => {
          actions?.setPcitoken(tokenResponse?.data?.getSecurityTokenResponse)
          actions.unSetLoader()
          isBGBT === true ? history.replace('/addcardnew') : history.replace('/addcard')
      })
      .catch((error) => {   
          actions.unSetLoader()   
      });
    }
  const navigateBack = () => {   
    history.replace('/serialno')
  }

  return (
    <div className='flex flex-col items-center'>
      <div className="px-p10 md:px-p20 xl:w-700 md:w-auto mx-auto w-900 pb-10">
      <div className="flex pt-6 px-p20">
                    <div className='md:pt-1 pt-3'>
                        <Iconback onClick={() => navigateBack()} />
                    </div>
                    <div className="text-bold lg:text-xl mx-auto text-40 leading-54 text-center"
                    ><b>Security Charge</b>
                    </div>
        </div>
       
        <div>
          <div className="pl-p5 md:pl-0 flex flex-col lg:text-lg text-2xl font-normal">
            <div className='w-321 psm:w-468 mx-auto my-m42 '>
              <p className='text-HN-Subtext-Light'>
              To complete your trade-in you’ll need to provide a valid payment details for a Security Charge.
              </p>
              <p className='mt-m20 text-HN-Subtext-Light'> 
              The Security Charge is equal to your estimated trade-in value.
              </p>
            </div>
           <div className='px-p20 py-p30 bg-HN-Grey-BgGrey'>
              <p style={{fontWeight: 350}} className='text-center lg:text-xl text-40'>Security Charge FAQs</p>
              <Accordian className="py-4 md:px-6 bg-brand-neutral" data={FaqList} />
           </div>
          
          </div>
        </div>   
      </div>
          <button className="mb-m20 h-44 w-251 mx-auto bg-HN-Purple-Dark rounded-md text-white" onClick={() => nextClicked()}>
          Continue
          </button>     
    </div>
  )
}
export default SecurityChargeFAQ
