import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Button from '../common/Button'
import Checkbox from '../Common/Checkbox'
import { useActions } from '../../Action'
import { store } from '../../Store'

const CancelTrade = ({ handleCancel, closePopup }) => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const [allowCancel, setAllowCancel] = useState(false)

  return (
    <div className="w-full">
      <Checkbox 
        className="mx-auto text text-lg w-516 md:w-auto mt-6" 
        isChecked={allowCancel} 
        checkboxClass="" 
        onChange={setAllowCancel} 
        label="I understand that when I cancel my trade-in, it is final and irreversible. A refund of your security charge will be processed within 5 business days after cancellation." 
      />

      <div className="flex flex-col mt-8 mb-6">
        <Button
          label="Cancel Trade-in"
          disabled={!allowCancel}
          className="w-56 mx-auto mb-4"
          type=""
          marginClass="text-white mt-10 border rounded-md"
          borderClass="rounded-md"
          bgColor="bg-HN-Purple-Dark"
          disabledBgColor="bg-gray-400"
          textClass="px-12 text-white"
          onClick={handleCancel}
        />
        <Button 
          label="Go Back" 
          className="w-56 mx-auto" 
          disabled={false} 
          type="" 
          textClass="px-12 text-black" 
          borderClass="border border-gray-400 rounded-lg"
          onClick={closePopup} 
        />
      </div>
    </div>
  )
}

CancelTrade.defaultProps = {
  handleCancel: undefined,
  closePopup: undefined,
}

CancelTrade.propTypes = {
  closePopup: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default CancelTrade
