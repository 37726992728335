import React from 'react'

const icon = ({ className, isSelected } ) => (
   <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15" cy="15" r="15" fill="#00BF63"/>
<path d="M22 10L12.3075 20L8 15.5558" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)
export default icon
