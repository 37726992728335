/* eslint-disable global-require */
import React from 'react'
import Loader from '../assets/front-loader-svg.svg'
const ProgressBar = () => (
  <div className="loaderOverlay">
    <div className="loader">
      <Loader/>
    </div>
  </div>
)

export default ProgressBar