import React, { useEffect, useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { getResource } from '../../utils/helper'
import { FloatLabelInputField } from '../../utils/custom-fields'
import { useHistory } from 'react-router-dom'
import Iconback from '../../Icons/icon-back'
import PredictiveAddress from './PredictiveAddress'

const CustomerInfo = (props) => {
  const { resources, state, actions } = props
  let { CustomerInfo, OTPResponse, State, StreetName, StreetNo, Suburb, PostCode, DeviceType } = state
  // const { CustomerInfo, OTPResponse } = state
  CustomerInfo = CustomerInfo ? CustomerInfo : {}
  const history = useHistory()
  const { register, errors } = useForm({ mode: 'onChange' })
  let sendOTPMail = () => {
    let data = {
      firstName: CustomerInfo?.FirstName,
      lastName: CustomerInfo?.LastName,
      email: CustomerInfo.EmailAddress,
    }
    actions.sendOTPMail(data)
  }
  const isFormValid =
    errors &&
    Object.keys(errors).length === 0 &&
    CustomerInfo.EmailAddress &&
    State &&
    StreetNo &&
    Suburb &&
    PostCode &&
    CustomerInfo.MobileNo &&
    CustomerInfo.FirstName &&
    CustomerInfo.LastName
  useEffect(() => {
    if (OTPResponse === 'updated successfully') {
      history.replace('/otpverification')
    }
  }, [OTPResponse === 'updated successfully'])

  const navigateBack = () => {
    actions.clearData('CustomerInfo')
    actions.clearData('PostCode')
    actions.clearData('State')
    actions.clearData('StreetName')
    actions.clearData('StreetNo')
    actions.clearData('Suburb')
    actions.goBackClicked(true)
    history?.replace("/")
  }
  return (
    <>
      {' '}
      <div className="h-cover" >
        <div className="px-p15 md:px-p15 xl:w-700 md:w-auto mx-auto w-900">
          {/* <div className='mx-auto md:w-auto'> */}
          <div className="flex pt-6 pl-p5 md:pl-0">
            <div className="md:mt-1 mt-4">
              <Iconback onClick={() => navigateBack()} />
            </div>
            <div className="text-bold md:text-f22 text-40 mx-auto ">
              <b>{getResource(resources, 'GET_STARTED')}</b>
            </div>
          </div>
          <div className="w-700 mx-auto md:w-auto">
            <div className="md:text-sm text-2xl text-HN-Subtext-Dark mt-m20 mx-auto md:w-auto ">
              {getResource(resources, 'VALIDATION_USER')}
            </div>
            {/* <div> */}
            <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1" />
            {/* <div className="md:mr-0 mr-5"> */}
            <div className="flex flex-row md:flex-col justify-center mt-3 ">
              <div className="mb-m15 mr-5 md:mr-0 md:w-auto profile-input w-345">
                <FloatLabelInputField
                  type="text"
                  name="firstname"
                  id="firstname"
                  label={getResource(resources, 'FIRST_NAME')}
                  labelClass=""
                  defaultValue={CustomerInfo?.FirstName}
                  placeholder={getResource(resources, 'FIRST_NAME')}
                  register={register}
                  maxLength={50}
                  schema="customerInfoDetails"
                  className="bg-white h-345 w-56"
                  errors={errors}
                  onChange={actions.setFirstName}
                  Mandatory={true}
                />
              </div>
              <div className="mb-m15 md:w-auto profile-input w-345 md:w-full md:mt-1">
                <FloatLabelInputField
                  type="text"
                  name="lastname"
                  id="lastname"
                  label={getResource(resources, 'LAST_NAME')}
                  labelClass=""
                  defaultValue={CustomerInfo?.LastName}
                  placeholder={getResource(resources, 'LAST_NAME')}
                  register={register}
                  maxLength={50}
                  schema="customerInfoDetails"
                  className="bg-white h-345 w-56"
                  errors={errors}
                  onChange={actions.setLastName}
                  Mandatory={true}
                />
              </div>
            </div>

            {/* <div className='flex md:flex-col gap-2'> */}
            <div className="flex flex-row md:flex-col justify-center mt-1 md:mt-0">
              <div className="mb-m15 mr-5 md:mr-0 md:w-auto profile-input w-345 ">
                <FloatLabelInputField
                  type="text"
                  name="email"
                  id="email"
                  label={getResource(resources, 'EMAIL')}
                  labelClass=""
                  defaultValue={CustomerInfo?.EmailAddress}
                  placeholder={getResource(resources, 'EMAIL')}
                  register={register}
                  schema="customerInfoDetails"
                  className="bg-white h-345 w-56"
                  errors={errors}
                  onChange={actions.setEmailAddress}
                  Mandatory={true}
                />
              </div>
              <div className="mb-m15 md:w-auto profile-input w-345 md:w-full md:mt-1">
                <FloatLabelInputField
                  type="text"
                  name="mobileno"
                  id="mobileno"
                  label={getResource(resources, 'MOBILE_NO')}
                  labelClass=""
                  defaultValue={CustomerInfo?.MobileNo}
                  placeholder={getResource(resources, 'MOBILE_NO')}
                  register={register}
                  maxLength={50}
                  schema="customerInfoDetails"
                  className="bg-white h-345 w-56"
                  errors={errors}
                  onChange={actions.setMobileNo}
                  Mandatory={true}
                />
              </div>
            </div>
            <div className="mt-1 md:w-full w-700 mx-auto">
              <PredictiveAddress actions={actions} resources={resources} state={state} />
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="flex flex-col items-center md:px-p15">
          <button
            className={`${
              !isFormValid ? 'opacity-25 pointer-events-none bg-HN-Gray-Dark' : 'bg-HN-Purple-Dark'
            } w-345 h-44 primary-btn bg-HN-Purple-Dark text-white mt-8 md:w-full  `}
            disabled={!isFormValid}
            onClick={() => sendOTPMail()}
          >
            {getResource(resources, 'EMAIL_CODE')}
          </button>
          <div className="w-345 h-100 text-sm pt-5 items-center bg-white mt-4 text-HN-Gray-Medium md:w-full mb-3">
            <p className="ml-4">
              {getResource(resources, 'UNIQUE_OTP')}
              <span className="text-sm text-HN-Gray-Medium">
                <strong>{getResource(resources, 'EXPIRY_MINUTES')}</strong>
              </span>
            </p>
          </div>
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
        </div>
        {/* </div> */}
      </div>
    </>
  )
}
export default CustomerInfo
