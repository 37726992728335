import React from 'react'
import Iconback from '../../Icons/icon-back'
import { getResource } from '../../utils/helper'
import { useHistory } from 'react-router-dom'


const MySelectedDevice = (props) => {
  const { resources, state, actions } = props
  const {
    SelectedDevice,
  } = state


  const history = useHistory()

  const navigateBack = () => {
    history?.replace('/devicephysicalcondition')
  }


  return (
    <>
      <div className="md:mx-m15 md:w-auto mx-auto max-w-2xl pb-10">
        <div>
          <div className="pt-6 pl-p5 flex flex-row items-center">
            <div>
              <Iconback onClick={() => navigateBack()} />
            </div>
            <div className="text-bold text-f22 leading-30 pl-10 md:mr-5 text-center mx-auto">
              {getResource(resources, 'BASED_ON') +
                ' ' +
              SelectedDevice?.[0]?.AssetCatalogName}{' '}
              {getResource(resources, 'IS')} <br />{' '}
              <span className=" font-bold">{getResource(resources, 'NOT_ELIGIBLE')}</span>
              <span>{getResource(resources, 'TRADE-IN')}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center ml-3 mx-auto pt-4 items-center md:mx-0">
          <div className="text-base text-center pt-2 ml-5 md:ml-0">
            {
              'You may be eligible for bonus credit via promotional offers in store, subject to Harvey Norman, Domayne or Joyce Mayne.'
            }
          </div>
        </div>

        <div className=" flex justify-between md:mt-4 mt-20 text-white text-base w-345 mx-auto">
          <button className="bg-HN-Purple-Dark rounded-lg p-3 w-full mr-2" onClick={() => window.location.reload()}>
            {getResource(resources, 'COMMON_BTN_CLOSE')}
          </button>
        </div>
      </div>
    </>
  )
}
export default MySelectedDevice
