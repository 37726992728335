export default {
  SET_LOADING_INDICATOR: 'SET_LOADING_INDICATOR',
  UNSET_LOADING_INDICATOR: 'UNSET_LOADING_INDICATOR',

  // GET_RESOURCE_REQUEST: 'GET_RESOURCE_REQUEST',
  // GET_RESOURCE_SUCCESS: 'GET_RESOURCE_SUCCESS',
  // GET_RESOURCE_FAILURE: 'GET_RESOURCE_FAILURE',

  GET_MASTER_DATA_REQUEST: 'GET_MASTER_DATA_REQUEST',
  GET_MASTER_DATA_SUCCESS: 'GET_MASTER_DATA_SUCCESS',
  GET_MASTER_DATA_FAILURE: 'GET_MASTER_DATA_FAILURE',

  SET_PROCESSOR: 'SET_PROCESSOR',
  SET_DEVICE_INFORMATION_OBJECT: 'SET_DEVICE_INFORMATION_OBJECT',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_STORAGE: 'SET_STORAGE',
  SET_PHONE_STORAGE: 'SET_PHONE_STORAGE',
  SET_STORAGE_ARRAY: 'SET_STORAGE_ARRAY',
  SET_DEVICE_EVALUATION_OBJECT: 'SET_DEVICE_EVALUATION_OBJECT',
  SET_DEVICE_EVALUATION_OBJECT_SURFACE: 'SET_DEVICE_EVALUATION_OBJECT_SURFACE',
  SET_DEVICE_EVALUATION_OBJECT_WEARABLES: 'SET_DEVICE_EVALUATION_OBJECT_WEARABLES',
  SET_SUB_CATEGORY: 'SET_SUB_CATEGORY',
  SET_SELECTED_DEVICE: 'SET_SELECTED_DEVICE',
  SET_MANUFACTURER: 'SET_MANUFACTURER',
  
  SET_PHONE_MANUFACTURER: 'SET_PHONE_MANUFACTURER',
  SET_SELECTED_PHONE_DEVICE: 'SET_SELECTED_PHONE_DEVICE',


  SET_FIRST_NAME: 'SET_FIRST_NAME',
  SET_LAST_NAME: 'SET_LAST_NAME',
  SET_EMAIL_ADDRESS: 'SET_EMAIL_ADDRESS',
  SET_MOBILE_NO: 'SET_MOBILE_NO',
  SET_ADDRESS: 'SET_ADDRESS',

  SEND_OTP_REQUEST: 'SEND_OTP_REQUEST',
  SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
  SEND_OTP_FAILURE: 'SEND_OTP_FAILURE',

  VALIDATE_OTP_REQUEST: 'VALIDATE_OTP_REQUEST',
  VALIDATE_OTP_SUCCESS: 'VALIDATE_OTP_SUCCESS',
  VALIDATE_OTP_FAILURE: 'VALIDATE_OTP_FAILURE',
  CLEAR_OTP_RESPONSE: 'CLEAR_OTP_RESPONSE',

  SET_ELIGIBILTY: 'SET_ELIGIBILTY',
  SET_ELIGIBILTY_SUCCESS: 'SET_ELIGIBILTY_SUCCESS',
  SET_ELIGIBILTY_FAILURE: 'SET_ELIGIBILTY_FAILURE',
  IS_DEVICE_ON: 'IS_DEVICE_ON',
  IS_DEVICE_ON: 'IS_DEVICE_ON',
  SET_SERIAL_NO: 'SET_SERIAL_NO',
  TERMS_AGREED: 'TERMS_AGREED',
  SET_FORM_COMPLETE: 'SET_FORM_COMPLETE',
  SET_QUESTIONANSWER_JSON: 'SET_QUESTIONANSWER_JSON',
  SET_SURFACEQUESTIONANSWER_JSON: 'SET_SURFACEQUESTIONANSWER_JSON',
  SET_LVDQUESTIONANSWER_JSON:'SET_LVDQUESTIONANSWER_JSON',
  SET_QUESTIONANSWER_JSON1: 'SET_QUESTIONANSWER_JSON1',
  SET_SUBFORM_COMPLETE: 'SET_SUBFORM_COMPLETE',
  SET_CREATEQUOTE_TESTS_ARRAY: 'SET_CREATEQUOTE_TESTS_ARRAY',

  CREATE_QUOTE_REQUEST: 'CREATE_QUOTE_REQUEST',
  CREATE_QUOTE_SUCCESS: 'CREATE_QUOTE_SUCCESS',
  CREATE_QUOTE_FAILURE: 'CREATE_QUOTE_FAILURE',
  DEVICE_NAME: 'DEVICE_NAME',

  UPDATE_CUSTOMER_REQUEST: 'UPDATE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILURE: 'UPDATE_CUSTOMER_FAILURE',

  SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILURE: 'SEND_EMAIL_FAILURE',

  GET_PRESIGN_URL_FOR_UPLOAD_REQUEST: 'GET_PRESIGN_URL_FOR_UPLOAD_REQUEST',
  GET_PRESIGN_URL_FOR_UPLOAD_SUCCESS: 'GET_PRESIGN_URL_FOR_UPLOAD_SUCCESS',
  GET_PRESIGN_URL_FOR_UPLOAD_FAILURE: 'GET_PRESIGN_URL_FOR_UPLOAD_FAILURE',

  STANDARDIZE_ADDRESS_REQUEST: 'STANDARDIZE_ADDRESS_REQUEST',
  STANDARDIZE_ADDRESS_SUCCESS: 'STANDARDIZE_ADDRESS_SUCCESS',
  STANDARDIZE_ADDRESS_FAILURE: 'STANDARDIZE_ADDRESS_FAILURE',
  SET_SEARCH_ADDRESS_LINE: 'SET_SEARCH_ADDRESS_LINE',
  SET_PREDECTIVE_ADDRESS_SUCCESS: 'SET_PREDECTIVE_ADDRESS_SUCCESS',
  GET_DO_GET_ADDRESS: 'GET_DO_GET_ADDRESS',
  GET_DO_GET_ADDRESS_SUCCESS: 'GET_DO_GET_ADDRESS_SUCCESS',
  GET_DO_GET_ADDRESS_FAILURE: 'GET_DO_GET_ADDRESS_FAILURE',

  UPDATE_IMEI_REQUEST: 'UPDATE_IMEI_REQUEST',
  UPDATE_IMEI_FAILURE: 'UPDATE_IMEI_FAILURE',
  UPDATE_IMEI_SUCCESS: 'UPDATE_IMEI_SUCCESS',

  GET_PRESIGN_URL_2: 'GET_PRESIGN_URL_2',
  GET_PRESIGN_URL_2_SUCCESS: 'GET_PRESIGN_URL_2_SUCCESS',
  GET_PRESIGN_URL_2_FAILURE: 'GET_PRESIGN_URL_2_FAILURE',

  GET_QR_CODE_PRESIGN_URL: 'GET_QR_CODE_PRESIGN_URL',
  GET_QR_CODE_PRESIGN_URL__SUCCESS: 'GET_QR_CODE_PRESIGN_URL_SUCCESS',
  GET_QR_CODE_PRESIGN_URL_FAILURE: 'GET_QR_CODE_PRESIGN_URL_FAILURE',

  SET_FAQ: 'SET_FAQ',
  SET_FAQ_SUCCESS: 'SET_FAQ_SUCCESS',
  SET_FAQ_FAILURE: 'SET_FAQ_FAILURE',

  SET_SELECTED_ADDRESS_VALUE: 'SET_SELECTED_ADDRESS_VALUE',
  SET_IMAGE_NAME: 'SET_IMAGE_NAME',
  SET_QUOTE_DEVICE: 'SET_QUOTE_DEVICE',
  SET_QUOTE_PHONE: 'SET_QUOTE_PHONE',
  GO_BACK_CLICKED: 'GO_BACK_CLICKED',

  SET_SERIES: 'SET_SERIES',
  SET_PHONE_SERIES: 'SET_PHONE_SERIES',
  UNSET_PHONE_SERIES: 'UNSET_PHONE_SERIES',
  DEVICE_TYPE: 'DEVICE_TYPE',

  SET_SSP_PAGE: 'SET_SSP_PAGE',
  SET_SSP_TRADE_ID: 'SET_SSP_TRADE_ID',
  SET_SSP_EMAIL_ID: 'SET_SSP_EMAIL_ID',
  API_ERROR: 'API_ERROR',
  CREATE_INQUIRY_REQUEST: 'CREATE_INQUIRY_REQUEST',
  CREATE_INQUIRY_SUCCESS: 'CREATE_INQUIRY_SUCCESS',
  SET_SUBSCRIPTION_NUMBER: 'SET_SUBSCRIPTION_NUMBER',
  SET_HOW_CAN_I_HELP: 'SET_HOW_CAN_I_HELP',
  SET_NAME: 'SET_NAME',
  SET_CONTACT_NUMBER: 'SET_CONTACT_NUMBER',
  SET_NOTE: 'SET_NOTE',
  SET_CHAT_WINDOW: 'SET_CHAT_WINDOW',
  SET_CHAT_IS_LOGGED_IN: 'SET_CHAT_IS_LOGGED_IN',
  SET_SEARCH_INPUT: 'SET_SEARCH_INPUT',
  SET_SSP_OTP: 'SET_SSP_OTP',
  SET_TRADE_DETAILS: 'SET_TRADE_DETAILS',
  SET_TRADE_DETAILS_SUCCESS: 'SET_TRADE_DETAILS_SUCCESS',
  SET_TRADE_STATUS: 'SET_TRADE_STATUS',
  SET_TRADE_STATUS_SUCCESS: 'SET_TRADE_STATUS_SUCCESS',
  VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  SET_GENERATE_LABEL_REQUEST: 'SET_GENERATE_LABEL_REQUEST',
  SET_SHIPPING_LABEL_REQUEST: 'SET_SHIPPING_LABEL_REQUEST',
  SET_GENERATE_LABEL: 'SET_GENERATE_LABEL',
  GET_IMAGE_REQUEST: 'GET_IMAGE_REQUEST',
  GET_IMAGE_SUCCESS: 'GET_IMAGE_SUCCESS',
  SET_SHIPPING_LABEL_SUCCESS: 'SET_SHIPPING_LABEL_SUCCESS',
  CREATE_HYPER_WALLET_USER: 'CREATE_HYPER_WALLET_USER',
  CREATE_TRANSFER_METHOD: 'CREATE_TRANSFER_METHOD',
  CREATE_PAYOUT: 'CREATE_PAYOUT',
  SAVE_PAYOUT_DETAILS_SUCCESS: 'SAVE_PAYOUT_DETAILS_SUCCESS',
  SAVE_PAYOUT_DETAILS_FAILUER: 'SAVE_PAYOUT_DETAILS_FAILUER',
  SUBMIT_PAYOUT_SUCCESS: 'SUBMIT_PAYOUT_SUCCESS',
  SUBMIT_PAYOUT_FAILURE: 'SUBMIT_PAYOUT_FAILURE',
  CLEAR_PAYOUT_DETAILS: 'CLEAR_PAYOUT_DETAILS',

  SEND_SS_OTP_REQUEST: 'SEND_SS_OTP_REQUEST',
  SEND_SS_OTP_REQUEST_SUCCESS: 'SEND_SS_OTP_REQUEST_SUCCESS',
  SEND_SS_OTP_REQUEST_FAILURE: 'SEND_SS_OTP_REQUEST_FAILURE',

  VERIFY_SS_OTP_REQUEST: 'VERIFY_SS_OTP_REQUEST',
  VERIFY_SS_OTP_REQUEST_SUCCESS: 'VERIFY_SS_OTP_REQUEST_SUCCESS',
  VERIFY_SS_OTP_REQUEST_FAILURE: 'VERIFY_SS_OTP_REQUEST_FAILURE',

  Get_ALL_SS_TRADES: 'Get_ALL_SS_TRADES',
  Get_ALL_SS_TRADES_SUCCESS: 'Get_ALL_SS_TRADES_SUCCESS',
  Get_ALL_SS_TRADES_FAILURE: 'Get_ALL_SS_TRADES_FAILURE',

  GET_SS_TRADE_DETAILS: 'GET_SS_TRADE_DETAILS',
  GET_SS_TRADE_DETAILS_SUCCESS: 'GET_SS_TRADE_DETAILS_SUCCESS',
  GET_SS_TRADE_DETAILS_FAILURE: 'GET_SS_TRADE_DETAILS_FAILURE',

  SET_UPDATE_METHOD: 'SET_UPDATE_METHOD',

  GET_PCITOKEN: 'GET_PCITOKEN',
  SET_PCITOKEN: 'SET_PCITOKEN',

  SET_COLLECT_PAYMENT: 'SET_COLLECT_PAYMENT',
  COLLECT_PAYMENT_REQUEST: 'COLLECT_PAYMENT_REQUEST',

  CLEAR_STATE: 'CLEAR_STATE',
  CLEAR_DATA: 'CLEAR_DATA',

  CANCEL_SS_TRADE_DETAILS: 'CANCEL_SS_TRADE_DETAILS',
  CANCEL_SS_TRADE_DETAILS_SUCCESS: 'CANCEL_SS_TRADE_DETAILS_SUCCESS',
  CANCEL_SS_TRADE_DETAILS_FAILURE: 'CANCEL_SS_TRADE_DETAILS_FAILURE',

  RETURN_SS_LABEL: 'RETURN_SS_LABEL',
  RETURN_SS_LABEL_SUCCESS: 'RETURN_SS_LABEL_SUCCESS',
  RETURN_SS_LABEL_FAILURE: 'RETURN_SS_LABEL_FAILURE',
  
  SET_DATA_SET : 'SET_DATA_SET',
  SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD'
}

export const ServicePortalPages = {
  GetEmailOtp: 'GetEmailOtp',
  VerifyOtp: 'VerifyOtp',
  TradeDetails: 'TradeDetails',
  CancelQuoteResponse: 'CancelQuoteResponse',
  Refund: 'Refund'
}

export const TradeStatusCode = {
  SUBMITTED: 'Submitted',
  OPEN: 'Open',
  CANCELLED: 'Cancelled',
  SHIPPED: 'Shipped',
  CONFIRMED: 'Confirmed',
  CANCELED: 'Cancelled',
  BATCHED: 'Used',
}

