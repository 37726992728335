import React from 'react'

const icon = ({ className, onClick } ) => (

    <svg className = {className} onClick={onClick} width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 1L11 11L1 0.999998" stroke="#6B4E9F" stroke-width="1.5"/>
</svg>


)
export default icon