/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */

import React from 'react'

const icon = ({ className, isSelected }) => (
    <svg width="59" height="54" viewBox="0 0 59 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 21.0479H56.7619" stroke="#6B4E9F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35.3333 11.5244H54.381C55.6959 11.5244 56.7619 12.5904 56.7619 13.9054V49.6197C56.7619 50.9346 55.6959 52.0006 54.381 52.0006H4.38095C3.06599 52.0006 2 50.9346 2 49.6197V13.9054C2 12.5904 3.06599 11.5244 4.38095 11.5244H23.4286" stroke="#6B4E9F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.3813 6.76172C29.7101 6.76172 29.9766 7.02822 29.9766 7.35696C29.9766 7.6857 29.7101 7.95219 29.3813 7.95219C29.0526 7.95219 28.7861 7.6857 28.7861 7.35696C28.7861 7.02822 29.0526 6.76172 29.3813 6.76172" stroke="#6B4E9F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11 33H47" stroke="#6B4E9F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11 40H32" stroke="#6B4E9F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.3335 15.0952C35.3335 15.7527 34.8005 16.2857 34.1431 16.2857H24.6192C23.9618 16.2857 23.4288 15.7527 23.4288 15.0952V7.95238C23.4288 4.66497 26.0937 2 29.3812 2C32.6686 2 35.3335 4.66497 35.3335 7.95238V15.0952Z" stroke="#6B4E9F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)

export default icon

