/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */

import React from 'react'

const icon = ({ className, isSelected }) => (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.4348 12.3047L19.2608 28.8264L10.5652 21.8699" stroke="#00BF63" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41Z" stroke="#00BF63" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)

export default icon
