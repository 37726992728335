import React from 'react';
import { isEmpty } from '../../utils/helper';

const Dropdown = ({
    label,
    options,
    value,
    disabled,
    onChange,
    placeholder,
    className,
    wrapperClassName,
    labelClassName
  }) => (
    <div className={`flex flex-col w-full ${!isEmpty(value) && 'pt-1'} ${wrapperClassName}`}>
      { value && <label htmlFor={label} className={`text-xs text-HN-Grey-Light ${labelClassName}`}>{label}</label> }
      
      <select
        name={label}
        id={label}
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        className={`${!disabled && "cursor-pointer"} ${!isEmpty(value) && "pt-p7"} bg-transparent text-HN-Grey-Light border-b border-b-brand-border outline-none ${className}`}
      >
        {placeholder && <option value="" disabled className='text-HN-Grey-Light'>{placeholder}</option>}
        {options.map((o) => (
          <option className='text-HN-Grey-Light' value={o.value}>{o.key}</option>
        ))}
      </select>
    </div>
  );
  
  Dropdown.defaultProps = {
    label: "Please select",
    value: "",
    disabled: false,
    placeholder: "",
    onChange: undefined,
    options: [],
    className: "",
    wrapperClassName: "",
    labelClassName: "",
  };
  
  export default Dropdown;
  