/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
  <svg className={`h-6 m-1 ${className}`} viewBox="0 0 102 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.1357 19.6061L20.6486 5.47144H24.5749L22.1406 19.6061H18.1357Z" fill="#3C58BF" />
    <path d="M18.1357 19.6061L21.3553 5.47144H24.5749L22.1406 19.6061H18.1357Z" fill="#293688" />
    <path
      d="M36.3575 5.62821C35.5722 5.3141 34.3158 5 32.7453 5C28.819 5 25.9921 6.96315 25.9921 9.79008C25.9921 11.9103 27.9552 13.0096 29.5257 13.7164C31.0962 14.4231 31.5674 14.8943 31.5674 15.5225C31.5674 16.4648 30.311 16.9359 29.2116 16.9359C27.6411 16.9359 26.7773 16.7004 25.4424 16.1507L24.8927 15.9151L24.343 19.1347C25.2853 19.5273 27.0129 19.9199 28.819 19.9199C32.9809 19.9199 35.7293 17.9568 35.7293 14.9728C35.7293 13.3237 34.7084 12.0673 32.3527 11.0465C30.9392 10.3398 30.0754 9.94713 30.0754 9.2404C30.0754 8.61219 30.7821 7.98398 32.3527 7.98398C33.6876 7.98398 34.6299 8.21956 35.3366 8.53366L35.7293 8.69071L36.3575 5.62821Z"
      fill="#3C58BF"
    />
    <path
      d="M36.3575 5.62821C35.5722 5.3141 34.3158 5 32.7453 5C28.819 5 26.6988 6.96315 26.6988 9.79008C26.6988 11.9103 27.9552 13.0096 29.5257 13.7164C31.0962 14.4231 31.5674 14.8943 31.5674 15.5225C31.5674 16.4648 30.311 16.9359 29.2116 16.9359C27.6411 16.9359 26.7773 16.7004 25.4424 16.1507L24.8927 15.9151L24.343 19.1347C25.2853 19.5273 27.0129 19.9199 28.819 19.9199C32.9809 19.9199 35.7293 17.9568 35.7293 14.9728C35.7293 13.3237 34.7084 12.0673 32.3527 11.0465C30.9392 10.3398 30.0754 9.94713 30.0754 9.2404C30.0754 8.61219 30.7821 7.98398 32.3527 7.98398C33.6876 7.98398 34.6299 8.21956 35.3366 8.53366L35.7293 8.69071L36.3575 5.62821Z"
      fill="#293688"
    />
    <path
      d="M43.1115 5.47144C42.1692 5.47144 41.4625 5.54996 41.0698 6.49227L35.1804 19.6061H39.4208L40.2061 17.2503H45.2317L45.7028 19.6061H49.4721L46.174 5.47144H43.1115ZM41.3054 14.8945C41.541 14.1878 42.8759 10.7327 42.8759 10.7327C42.8759 10.7327 43.19 9.86888 43.4256 9.3192L43.6612 10.6541C43.6612 10.6541 44.4464 14.1878 44.6035 14.9731H41.3054V14.8945Z"
      fill="#3C58BF"
    />
    <path
      d="M44.0538 5.47144C43.1115 5.47144 42.4048 5.54996 42.0121 6.49227L35.1804 19.6061H39.4208L40.2061 17.2503H45.2317L45.7028 19.6061H49.4721L46.174 5.47144H44.0538ZM41.3054 14.8945C41.6195 14.1093 42.8759 10.7327 42.8759 10.7327C42.8759 10.7327 43.19 9.86888 43.4256 9.3192L43.6612 10.6541C43.6612 10.6541 44.4464 14.1878 44.6035 14.9731H41.3054V14.8945Z"
      fill="#293688"
    />
    <path
      d="M10.918 15.3655L10.5254 13.3238C9.81864 10.9681 7.54139 8.37673 5.02856 7.12032L8.56223 19.6844H12.8026L19.1632 5.5498H14.9228L10.918 15.3655Z"
      fill="#3C58BF"
    />
    <path
      d="M10.918 15.3655L10.5254 13.3238C9.81864 10.9681 7.54139 8.37673 5.02856 7.12032L8.56223 19.6844H12.8026L19.1632 5.5498H15.7081L10.918 15.3655Z"
      fill="#293688"
    />
    <path
      d="M0 5.47144L0.706733 5.62849C5.73239 6.80638 9.18752 9.79036 10.5225 13.324L9.109 6.64932C8.87342 5.70701 8.16669 5.47144 7.30291 5.47144H0Z"
      fill="#FFBC00"
    />
    <path
      d="M0 5.47144C5.02566 6.64932 9.18752 9.71183 10.5225 13.2455L9.18753 7.67016C8.95195 6.72785 8.16669 6.17817 7.30291 6.17817L0 5.47144Z"
      fill="#F7981D"
    />
    <path
      d="M0 5.47144C5.02566 6.64932 9.18752 9.71183 10.5225 13.2455L9.58015 10.183C9.34458 9.24068 9.03047 8.29837 7.93111 7.90574L0 5.47144Z"
      fill="#ED7C00"
    />
    <path
      d="M14.8429 14.8951L12.173 12.2252L10.9166 15.2092L10.6025 13.246C9.89579 10.8902 7.61854 8.29889 5.10571 7.04248L8.63938 19.6066H12.8798L14.8429 14.8951Z"
      fill="#051244"
    />
    <path d="M22.1454 19.6067L18.7688 16.1516L18.1406 19.6067H22.1454Z" fill="#051244" />
    <path
      d="M31.175 14.7388C31.4891 15.0529 31.6462 15.2885 31.5676 15.6026C31.5676 16.5449 30.3112 17.016 29.2119 17.016C27.6413 17.016 26.7776 16.7804 25.4426 16.2308L24.8929 15.9952L24.3433 19.2147C25.2856 19.6074 27.0131 20 28.8192 20C31.3321 20 33.3737 19.2933 34.5516 18.0369L31.175 14.7388Z"
      fill="#051244"
    />
    <path
      d="M35.7314 19.6065H39.4222L40.2074 17.2507H45.2331L45.7042 19.6065H49.4735L48.1385 13.8741L43.427 9.31958L43.6626 10.576C43.6626 10.576 44.4478 14.1097 44.6049 14.8949H41.3068C41.6209 14.1097 42.8773 10.733 42.8773 10.733C42.8773 10.733 43.1914 9.86926 43.427 9.31958"
      fill="#051244"
    />
    <path
      d="M100.667 12.5C100.667 19.3783 95.1112 25 88.1667 25C81.2884 25 75.6667 19.3783 75.6667 12.5C75.6667 5.62169 81.2223 0 88.1006 0C95.1112 0 100.667 5.62169 100.667 12.5Z"
      fill="#FFB600"
    />
    <path
      d="M88.1667 0C95.0451 0 100.667 5.62169 100.667 12.5C100.667 19.3783 95.1112 25 88.1667 25C81.2884 25 75.6667 19.3783 75.6667 12.5"
      fill="#F7981D"
    />
    <path d="M88.1667 0C95.0451 0 100.667 5.62169 100.667 12.5C100.667 19.3783 95.1112 25 88.1667 25" fill="#FF8500" />
    <path
      d="M71.3677 0C64.5556 0.0661376 59 5.62169 59 12.5C59 19.3783 64.5556 25 71.5 25C74.7407 25 77.6508 23.7434 79.8995 21.7593C80.3624 21.3624 80.7593 20.8995 81.1561 20.4365H78.5767C78.246 20.0397 77.9153 19.5767 77.6508 19.1799H82.082C82.3466 18.7831 82.6111 18.3201 82.8095 17.8571H76.9233C76.7249 17.4603 76.5265 16.9974 76.3942 16.5344H83.2725C83.6693 15.2778 83.9339 13.955 83.9339 12.5661C83.9339 11.6402 83.8016 10.7804 83.6693 9.92063H75.9974C76.0635 9.45767 76.1958 9.06085 76.328 8.59788H83.2063C83.0741 8.13492 82.8757 7.67196 82.6772 7.27513H76.8571C77.0556 6.81217 77.3201 6.41534 77.5847 5.95238H82.0159C81.7513 5.48942 81.4206 5.02645 81.0238 4.62963H78.5767C78.9735 4.16667 79.3704 3.76984 79.8333 3.37302C77.6508 1.32275 74.6746 0.132275 71.4339 0.132275C71.4339 -1.28118e-07 71.4339 0 71.3677 0Z"
      fill="#FF5050"
    />
    <path
      d="M59 12.5C59 19.3784 64.5556 25 71.5 25C74.7407 25 77.6508 23.7434 79.8995 21.7593C80.3624 21.3625 80.7593 20.8995 81.1561 20.4366H78.5767C78.246 20.0397 77.9153 19.5768 77.6508 19.1799H82.082C82.3466 18.7831 82.6111 18.3202 82.8095 17.8572H76.9233C76.7249 17.4604 76.5265 16.9974 76.3942 16.5344H83.2725C83.6693 15.2778 83.9339 13.9551 83.9339 12.5662C83.9339 11.6403 83.8016 10.7805 83.6693 9.92068H75.9974C76.0635 9.45772 76.1958 9.0609 76.328 8.59793H83.2063C83.0741 8.13497 82.8757 7.67201 82.6772 7.27518H76.8571C77.0556 6.81222 77.3201 6.41539 77.5847 5.95243H82.0159C81.7513 5.48947 81.4206 5.0265 81.0238 4.62968H78.5767C78.9735 4.16672 79.3704 3.76989 79.8333 3.37307C77.6508 1.3228 74.6746 0.132324 71.4339 0.132324H71.3677"
      fill="#E52836"
    />
    <path
      d="M71.5004 25C74.7412 25 77.6512 23.7434 79.8999 21.7593C80.3629 21.3625 80.7597 20.8995 81.1565 20.4366H78.5772C78.2465 20.0397 77.9158 19.5768 77.6512 19.1799H82.0824C82.347 18.7831 82.6115 18.3202 82.81 17.8572H76.9237C76.7253 17.4604 76.5269 16.9974 76.3946 16.5344H83.2729C83.6698 15.2778 83.9343 13.9551 83.9343 12.5662C83.9343 11.6403 83.802 10.7805 83.6697 9.92068H75.9978C76.0639 9.45772 76.1962 9.0609 76.3285 8.59793H83.2068C83.0745 8.13497 82.8761 7.67201 82.6777 7.27518H76.8576C77.056 6.81222 77.3205 6.41539 77.5851 5.95243H82.0163C81.7518 5.48947 81.4211 5.0265 81.0242 4.62968H78.5772C78.974 4.16672 79.3708 3.76989 79.8338 3.37307C77.6512 1.3228 74.675 0.132324 71.4343 0.132324H71.3682"
      fill="#CB2026"
    />
    <path
      d="M75.9314 15.6746L76.1298 14.5503C76.0637 14.5503 75.9314 14.6164 75.7991 14.6164C75.3362 14.6164 75.27 14.3518 75.3362 14.2196L75.733 11.9048H76.4605L76.6589 10.6481H75.9976L76.1298 9.85449H74.8071C74.8071 9.85449 74.0134 14.2196 74.0134 14.7487C74.0134 15.5423 74.4764 15.873 75.0716 15.873C75.4685 15.873 75.7991 15.7407 75.9314 15.6746Z"
      fill="white"
    />
    <path
      d="M76.3945 13.5583C76.3945 15.4101 77.6511 15.8731 78.7093 15.8731C79.7014 15.8731 80.0982 15.6747 80.0982 15.6747L80.3628 14.4181C80.3628 14.4181 79.6353 14.7488 78.9739 14.7488C77.5189 14.7488 77.7834 13.6906 77.7834 13.6906H80.4951C80.4951 13.6906 80.6935 12.8308 80.6935 12.5001C80.6935 11.6403 80.2305 10.5821 78.7755 10.5821C77.3866 10.4498 76.3945 11.9049 76.3945 13.5583ZM78.7093 11.6403C79.4369 11.6403 79.3046 12.5001 79.3046 12.5662H77.8496C77.8496 12.5001 77.9818 11.6403 78.7093 11.6403Z"
      fill="white"
    />
    <path
      d="M87.109 15.6749L87.3736 14.2198C87.3736 14.2198 86.7122 14.5505 86.2492 14.5505C85.3233 14.5505 84.9265 13.823 84.9265 13.0294C84.9265 11.4421 85.7201 10.5823 86.646 10.5823C87.3074 10.5823 87.8365 10.9791 87.8365 10.9791L88.0349 9.59021C88.0349 9.59021 87.2413 9.25952 86.5138 9.25952C84.9926 9.25952 83.4714 10.5823 83.4714 13.0955C83.4714 14.7489 84.2651 15.8733 85.8524 15.8733C86.3815 15.8733 87.109 15.6749 87.109 15.6749Z"
      fill="white"
    />
    <path
      d="M68.6556 10.4502C67.7296 10.4502 67.0683 10.7147 67.0683 10.7147L66.8698 11.8391C66.8698 11.8391 67.4651 11.5745 68.3249 11.5745C68.7878 11.5745 69.1847 11.6407 69.1847 12.0375C69.1847 12.3021 69.1185 12.3682 69.1185 12.3682C69.1185 12.3682 68.7217 12.3682 68.5233 12.3682C67.3989 12.3682 66.1423 12.8312 66.1423 14.3523C66.1423 15.5428 66.936 15.8073 67.3989 15.8073C68.3249 15.8073 68.7217 15.2121 68.7878 15.2121L68.7217 15.7412H69.9122L70.4413 12.1036C70.4413 10.5163 69.1185 10.4502 68.6556 10.4502ZM68.9201 13.4264C68.9201 13.6248 68.7878 14.683 67.9942 14.683C67.5974 14.683 67.4651 14.3523 67.4651 14.1539C67.4651 13.8232 67.6635 13.3603 68.6556 13.3603C68.854 13.4264 68.9201 13.4264 68.9201 13.4264Z"
      fill="white"
    />
    <path
      d="M71.6984 15.8071C72.0291 15.8071 73.6825 15.8732 73.6825 14.0875C73.6825 12.4341 72.0952 12.7647 72.0952 12.1034C72.0952 11.7727 72.3598 11.6404 72.8227 11.6404C73.0212 11.6404 73.7487 11.7065 73.7487 11.7065L73.9471 10.5161C73.9471 10.5161 73.4841 10.3838 72.6905 10.3838C71.6984 10.3838 70.7063 10.7806 70.7063 12.1034C70.7063 13.6245 72.3598 13.4923 72.3598 14.0875C72.3598 14.4843 71.8968 14.5505 71.5661 14.5505C70.9709 14.5505 70.3757 14.352 70.3757 14.352L70.1772 15.5425C70.2434 15.6748 70.5741 15.8071 71.6984 15.8071Z"
      fill="white"
    />
    <path
      d="M98.086 9.39185L97.8215 11.1776C97.8215 11.1776 97.2924 10.5162 96.5648 10.5162C95.3744 10.5162 94.3162 11.9712 94.3162 13.6908C94.3162 14.749 94.8453 15.8733 95.9696 15.8733C96.7633 15.8733 97.2262 15.3442 97.2262 15.3442L97.1601 15.8072H98.4828L99.4749 9.45798L98.086 9.39185ZM97.4908 12.8971C97.4908 13.6246 97.1601 14.5506 96.4326 14.5506C95.9696 14.5506 95.705 14.1538 95.705 13.4924C95.705 12.4342 96.168 11.7728 96.7632 11.7728C97.2262 11.7728 97.4908 12.1035 97.4908 12.8971Z"
      fill="white"
    />
    <path
      d="M61.4468 15.7411L62.2405 10.9791L62.3727 15.7411H63.2987L65.0182 10.9791L64.2907 15.7411H65.6796L66.7378 9.39185H64.5553L63.2325 13.294L63.1664 9.39185H61.2484L60.1902 15.7411H61.4468Z"
      fill="white"
    />
    <path
      d="M81.9497 15.741C82.3465 13.5585 82.4126 11.7728 83.3385 12.1034C83.4708 11.2437 83.6692 10.913 83.8015 10.5823C83.8015 10.5823 83.7354 10.5823 83.537 10.5823C82.9417 10.5823 82.4788 11.3759 82.4788 11.3759L82.611 10.6484H81.3544L80.4946 15.8072H81.9497V15.741Z"
      fill="white"
    />
    <path
      d="M90.1497 10.4502C89.2238 10.4502 88.5624 10.7147 88.5624 10.7147L88.364 11.8391C88.364 11.8391 88.9592 11.5745 89.819 11.5745C90.282 11.5745 90.6788 11.6407 90.6788 12.0375C90.6788 12.3021 90.6127 12.3682 90.6127 12.3682C90.6127 12.3682 90.2158 12.3682 90.0174 12.3682C88.8931 12.3682 87.6365 12.8312 87.6365 14.3523C87.6365 15.5428 88.4301 15.8073 88.8931 15.8073C89.819 15.8073 90.2158 15.2121 90.282 15.2121L90.2158 15.7412H91.4063L91.9354 12.1036C92.0016 10.5163 90.6127 10.4502 90.1497 10.4502ZM90.4804 13.4264C90.4804 13.6248 90.3481 14.683 89.5545 14.683C89.1576 14.683 89.0254 14.3523 89.0254 14.1539C89.0254 13.8232 89.2238 13.3603 90.2158 13.3603C90.4143 13.4264 90.4143 13.4264 90.4804 13.4264Z"
      fill="white"
    />
    <path
      d="M93.0612 15.741C93.4581 13.5585 93.5242 11.7728 94.4501 12.1034C94.5824 11.2437 94.7808 10.913 94.9131 10.5823C94.9131 10.5823 94.8469 10.5823 94.6485 10.5823C94.0533 10.5823 93.5903 11.3759 93.5903 11.3759L93.7226 10.6484H92.466L91.6062 15.8072H93.0612V15.741Z"
      fill="white"
    />
    <path
      d="M73.8816 14.7487C73.8816 15.5423 74.3446 15.873 74.9398 15.873C75.4028 15.873 75.7996 15.7407 75.9319 15.6746L76.1303 14.5503C76.0641 14.5503 75.9319 14.6164 75.7996 14.6164C75.3366 14.6164 75.2705 14.3518 75.3366 14.2196L75.7334 11.9048H76.461L76.6594 10.6481H75.998L76.1303 9.85449"
      fill="#DCE5E5"
    />
    <path
      d="M77.0554 13.5583C77.0554 15.4101 77.6507 15.8731 78.7089 15.8731C79.7009 15.8731 80.0977 15.6747 80.0977 15.6747L80.3623 14.4181C80.3623 14.4181 79.6348 14.7488 78.9734 14.7488C77.5184 14.7488 77.7829 13.6906 77.7829 13.6906H80.4946C80.4946 13.6906 80.693 12.8308 80.693 12.5001C80.693 11.6403 80.23 10.5821 78.775 10.5821C77.3861 10.4498 77.0554 11.9049 77.0554 13.5583ZM78.7089 11.6403C79.4364 11.6403 79.5686 12.5001 79.5686 12.5662H77.8491C77.8491 12.5001 77.9813 11.6403 78.7089 11.6403Z"
      fill="#DCE5E5"
    />
    <path
      d="M87.1085 15.6749L87.3731 14.2198C87.3731 14.2198 86.7117 14.5505 86.2487 14.5505C85.3228 14.5505 84.926 13.823 84.926 13.0294C84.926 11.4421 85.7196 10.5823 86.6456 10.5823C87.3069 10.5823 87.836 10.9791 87.836 10.9791L88.0344 9.59021C88.0344 9.59021 87.2408 9.25952 86.5133 9.25952C84.9921 9.25952 84.1323 10.5823 84.1323 13.0955C84.1323 14.7489 84.2646 15.8733 85.8519 15.8733C86.381 15.8733 87.1085 15.6749 87.1085 15.6749Z"
      fill="#DCE5E5"
    />
    <path
      d="M66.8698 11.905C66.8698 11.905 67.4651 11.6405 68.3249 11.6405C68.7878 11.6405 69.1847 11.7066 69.1847 12.1034C69.1847 12.368 69.1185 12.4341 69.1185 12.4341C69.1185 12.4341 68.7217 12.4341 68.5233 12.4341C67.3989 12.4341 66.1423 12.8971 66.1423 14.4182C66.1423 15.6087 66.936 15.8733 67.3989 15.8733C68.3249 15.8733 68.7217 15.278 68.7878 15.278L68.7217 15.8071H69.9122L70.4413 12.1696C70.4413 10.6484 69.1185 10.5823 68.5894 10.5823L66.8698 11.905ZM69.5815 13.4262C69.5815 13.6246 68.7878 14.6828 67.9942 14.6828C67.5974 14.6828 67.4651 14.3521 67.4651 14.1537C67.4651 13.823 67.6635 13.36 68.6556 13.36C68.854 13.4262 69.5815 13.4262 69.5815 13.4262Z"
      fill="#DCE5E5"
    />
    <path
      d="M70.2439 15.6748C70.2439 15.6748 70.6407 15.8071 71.7651 15.8071C72.0957 15.8071 73.7492 15.8732 73.7492 14.0875C73.7492 12.4341 72.1619 12.7647 72.1619 12.1034C72.1619 11.7727 72.4264 11.6404 72.8894 11.6404C73.0878 11.6404 73.8153 11.7065 73.8153 11.7065L74.0137 10.5161C74.0137 10.5161 73.5508 10.3838 72.7571 10.3838C71.7651 10.3838 71.4344 10.7806 71.4344 12.1034C71.4344 13.6245 72.4264 13.4923 72.4264 14.0875C72.4264 14.4843 71.9635 14.5505 71.6328 14.5505"
      fill="#DCE5E5"
    />
    <path
      d="M97.8223 11.1776C97.8223 11.1776 97.2932 10.5162 96.5657 10.5162C95.3753 10.5162 94.9784 11.9712 94.9784 13.6908C94.9784 14.749 94.8462 15.8733 95.9705 15.8733C96.7641 15.8733 97.2271 15.3442 97.2271 15.3442L97.161 15.8072H98.4837L99.4758 9.45801L97.8223 11.1776ZM97.7562 12.8972C97.7562 13.6247 97.161 14.5506 96.4334 14.5506C95.9705 14.5506 95.7059 14.1538 95.7059 13.4924C95.7059 12.4342 96.1689 11.7728 96.7641 11.7728C97.2271 11.7728 97.7562 12.1035 97.7562 12.8972Z"
      fill="#DCE5E5"
    />
    <path
      d="M61.4468 15.7411L62.2405 10.9791L62.3727 15.7411H63.2987L65.0182 10.9791L64.2907 15.7411H65.6796L66.7378 9.39185H65.0844L63.2325 13.294L63.1664 9.39185H62.4389L60.1902 15.7411H61.4468Z"
      fill="#DCE5E5"
    />
    <path
      d="M80.5596 15.741H81.9485C82.3453 13.5585 82.4114 11.7728 83.3373 12.1034C83.4696 11.2437 83.668 10.913 83.8003 10.5823C83.8003 10.5823 83.7342 10.5823 83.5358 10.5823C82.9405 10.5823 82.4776 11.3759 82.4776 11.3759L82.6098 10.6484"
      fill="#DCE5E5"
    />
    <path
      d="M88.364 11.905C88.364 11.905 88.9592 11.6405 89.819 11.6405C90.282 11.6405 90.6788 11.7066 90.6788 12.1034C90.6788 12.368 90.6127 12.4341 90.6127 12.4341C90.6127 12.4341 90.2158 12.4341 90.0174 12.4341C88.8931 12.4341 87.6365 12.8971 87.6365 14.4182C87.6365 15.6087 88.4301 15.8733 88.8931 15.8733C89.819 15.8733 90.2158 15.278 90.282 15.278L90.2158 15.8071H91.4063L91.9354 12.1696C91.9354 10.6484 90.6127 10.5823 90.0836 10.5823L88.364 11.905ZM91.0756 13.4262C91.0756 13.6246 90.282 14.6828 89.4883 14.6828C89.0915 14.6828 88.9592 14.3521 88.9592 14.1537C88.9592 13.823 89.1576 13.36 90.1497 13.36C90.4143 13.4262 91.0756 13.4262 91.0756 13.4262Z"
      fill="#DCE5E5"
    />
    <path
      d="M91.6714 15.741H93.0603C93.4571 13.5585 93.5232 11.7728 94.4492 12.1034C94.5814 11.2437 94.7798 10.913 94.9121 10.5823C94.9121 10.5823 94.846 10.5823 94.6476 10.5823C94.0523 10.5823 93.5894 11.3759 93.5894 11.3759L93.7216 10.6484"
      fill="#DCE5E5"
    />
    {/* <path
      d="M113.174 0H143.607C144.807 0 145.781 0.973826 145.781 2.17487V22.8262C145.781 24.0262 144.807 25 143.607 25H113.174C111.973 25.0001 111 24.0262 111 22.8263V2.17487C111 0.973826 111.974 0 113.174 0Z"
      fill="#26A6D1"
    />
    <path
      d="M116.609 8.69531L113.174 16.2961H117.286L117.795 15.0843H118.961L119.47 16.2961H123.996V15.3712L124.399 16.2961H126.741L127.144 15.3516V16.2961H136.557L137.701 15.1157L138.773 16.2961L143.607 16.3059L140.162 12.5169L143.607 8.69531H138.848L137.734 9.85391L136.696 8.69531H126.456L125.577 10.6572L124.677 8.69531H120.574V9.58879L120.117 8.69531C120.117 8.69531 116.609 8.69531 116.609 8.69531ZM117.404 9.77464H119.408L121.687 14.9288V9.77464H123.882L125.642 13.4701L127.264 9.77464H129.448V15.2287H128.119L128.108 10.9549L126.17 15.2287H124.981L123.032 10.9549V15.2287H120.297L119.779 14.006H116.978L116.461 15.2277H114.995L117.404 9.77464ZM130.664 9.77464H136.07L137.723 11.5604L139.429 9.77464H141.083L138.571 12.5159L141.083 15.2255H139.354L137.701 13.419L135.986 15.2255H130.664V9.77464ZM118.379 10.6974L117.456 12.8756H119.301L118.379 10.6974ZM131.999 10.9039V11.8996H134.948V13.0093H131.999V14.0962H135.307L136.844 12.4952L135.372 10.903H131.999V10.9039Z"
      fill="white"
    /> */}
  </svg>
)

export default icon
