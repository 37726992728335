import React from 'react'
import PropTypes from 'prop-types'
import Button from '../common/Button'

const ShipQuoteSuccess = ({ handleOk, responseMessage }) => (
  <div className="w-full">
    <div className="mt-4 px-4 text-center">
      {responseMessage === 'updated successfully' ? (
        'We have sent you an email with the return Label. Please return your device within 7 days to avoid a late return fee.'
      ) : (
        <>
          <div>An error occurred while getting return label.</div>
          <div>Please try again!</div>
        </>
      )}
    </div>
    <div className="w-full flex justify-center mt-8 mb-2">
      <Button label="Okay" disabled={false} textClass="px-12 text-white bg-black" onClick={handleOk} />
    </div>
  </div>
)

ShipQuoteSuccess.defaultProps = {
  handleOk: undefined,
  responseMessage: '',
}

ShipQuoteSuccess.propTypes = {
  handleOk: PropTypes.func,
  responseMessage: PropTypes.string,
}

export default ShipQuoteSuccess
