import React from 'react'
import ReactDOM from 'react-dom';
import App from './components/App'
import './appstyle.css'
import './style.css'
import { StateProvider } from './Store'

ReactDOM.render(
  <StateProvider>
    <App />
  </StateProvider>,
  document.getElementById('root')
)