export const isEmpty = (input) => {
  if (typeof input === 'undefined' || input === 'null') {
    return true
  }
  if (typeof input === 'function') {
    return false
  }
  if (Array.isArray(input)) {
    return input.length === 0
  }
  return !input || Object.keys(input).length === 0
}

export const toPascalCase = () => this.replace(/\w\S*/g, (t) => t.charAt(0).toUpperCase() + t.substr(1).toLowerCase())

export const convertToCamelCase = (text) => text.replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase())

export const processFileDownload = (b64, name = 'file.pdf') => {
  const link = document.createElement('a')
  link.innerHTML = 'Download PDF file'
  link.download = name
  link.href = `data:application/pdf;base64,${b64}`
  link.click()
}
