import React from 'react'
import PropType from 'prop-types'

const Hyperlink = ({ linkText, linkUrl, colorClass, textClass, className, containerClass, onClick, disabled }) => {
  return (
    <div className={containerClass}>
      {linkUrl && (
        <a href={linkUrl} target="_blank" rel="noreferrer" className={`underline ${disabled ? 'text-border' : colorClass} ${textClass} ${className}`}>
          {linkText}
        </a>
      )}
      {!linkUrl && (
        <div onClick={disabled ? undefined : onClick} className={`underline ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${disabled ? 'text-border' : colorClass} ${textClass} ${className}`}>
          {linkText}
        </div>
      )}
    </div>
  )
}

Hyperlink.propTypes = {
  linkText: PropType.string,
  linkUrl: PropType.string,
  textclass: PropType.string,
  colorclass: PropType.string,
  className: PropType.string,
  onClick: PropType.func,
  disabled: PropType.bool,
}

Hyperlink.defaultProps = {
  linkText: 'Click Me',
  linkUrl: '',
  colorClass: 'text-primaryText hover:text-active',
  textClass: 'text-base lg:text-lg',
  className: '',
  containerClass: 'w-full text-center',
  onClick: undefined,
  disabled: false,
}

export default Hyperlink
