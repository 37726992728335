/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */

import React from 'react'

const icon = ({ className }) => (
  <svg className={`${className}`} viewBox="0 0 18 18" fill="none">
    <circle cx="9" cy="9" r="8.25" fill="white" stroke="#6E767D" strokeWidth="1.5" />
    <path
      d="M8.24691 7.08642H9.94787V14H8.24691V7.08642ZM9.09739 6.19479C8.78647 6.19479 8.52583 6.08962 8.3155 5.87929C8.10517 5.66895 8 5.40832 8 5.09739C8 4.78647 8.10517 4.52583 8.3155 4.3155C8.52583 4.10517 8.78647 4 9.09739 4C9.40832 4 9.66895 4.10517 9.87929 4.3155C10.0896 4.52583 10.1948 4.78647 10.1948 5.09739C10.1948 5.40832 10.0896 5.66895 9.87929 5.87929C9.66895 6.08962 9.40832 6.19479 9.09739 6.19479Z"
      fill="#6E767D"
    />
  </svg>
)

export default icon
