import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import IconGWO from '../../Icons/icon-phone-gwo'
import IconNGWO from '../../Icons/icon-phone-ngwo'
import IconPhoneIneligible from '../../Icons/icon-phone-ineligible'

const DevicePhysicalCondition = (props) => {
  const { state, actions } = props
  const { CustomerInfo } = state
  const history = useHistory()
  let isMobile = window.innerWidth < 768

  const {
    PhoneQuestionAnswersJson,
    Eligibilty,
    isFormComplete,
    CreateQuoteTestsArray,
    CreateQuoteResponse,
    SelectedDevice,
    deviceEvalutionObjectPhone
  } = state
  let eligibility = Eligibilty === 'Ineligible' ? 'Recycle' : Eligibilty
  const getArray = (code) => {
    const i = CreateQuoteTestsArray.findIndex((_element) => _element.testTag === code.testTag)
    if (i > -1) CreateQuoteTestsArray[i] = code
    else CreateQuoteTestsArray.push(code)
    actions.CreateQuoteTestsArray(CreateQuoteTestsArray)
  }

  const setAnswer = (ans, no, code) => {
    getArray({ testTag: code.questioncode, result: ans })
    setQuestionAnswerJson(no, ans)
  }

  const setQuestionAnswerJson = (key, ans) => {

    const _PhoneQuestionAnswersJson = [...PhoneQuestionAnswersJson]
    _PhoneQuestionAnswersJson[key].answer = ans
    const isAnswer = _PhoneQuestionAnswersJson.every((data) => data.answer != '')
    actions.setQuestionAnswerJson(_PhoneQuestionAnswersJson)
    actions.setFormComplete(isAnswer)
    if (_PhoneQuestionAnswersJson[8]?.answer === 'Y' || _PhoneQuestionAnswersJson[4]?.answer === 'N'
      || _PhoneQuestionAnswersJson[5]?.answer === 'Y' || _PhoneQuestionAnswersJson[6]?.answer === 'Y'
      || _PhoneQuestionAnswersJson[7]?.answer === 'N') {
      actions.setEligibilty('Ineligible')
    } else if (_PhoneQuestionAnswersJson[0]?.answer === 'N'
      || _PhoneQuestionAnswersJson[1]?.answer === 'N'
      || _PhoneQuestionAnswersJson[2]?.answer === 'N'
      || _PhoneQuestionAnswersJson[3]?.answer === 'Y') {
      actions.setEligibilty('NGWO')
    } else {
      actions.setEligibilty('GWO')
    }
  }


  useEffect(() => {
    actions.setFormComplete(false);
    const _deviceEvalutionObject = [...deviceEvalutionObjectPhone]
    const _PhoneQuestionAnswersJson = [...PhoneQuestionAnswersJson]
    _deviceEvalutionObject.forEach((item) => {
      item.ans = ''
      item.WO = ''
    })
    _PhoneQuestionAnswersJson.forEach((item) => {
      item.answer = ''
      item.WO = ''
    })
  }, [])

  useEffect(() => {
    if (CreateQuoteResponse && CreateQuoteResponse?.tradeId) {
      if (CreateQuoteResponse?.quotePrice > 0) {
        history.replace('/tradeinfo')
      } else {
        history.replace('/ineligible')
      }
    }
  }, [CreateQuoteResponse && CreateQuoteResponse?.tradeId])

  const createQuote = () => {
    if (Eligibilty != 'Ineligible') {
      const createQuoteRequest = {
        Make: SelectedDevice[0]?.AssetMakeName,
        Model: SelectedDevice[0]?.AssetModelNumber,
        Storage: SelectedDevice[0]?.ClientMemoryCapacity,
        AssetCatalogId: SelectedDevice[0]?.AssetCatalogId,
        FinalResult: Eligibilty === 'GWO' ? 'Pass' : 'Fail',
        FinalPrice: '',
        Tests: CreateQuoteTestsArray,
      }
      const updateCustomerRequest = {
        firstName: CustomerInfo?.FirstName,
        lastName: CustomerInfo?.LastName,
        address1: state?.StreetNo,
        address2: state?.StreetName,
        address3: '',
        city: state?.Suburb,
        state: state?.State,
        country: 'AUS',
        zipCode: state?.PostCode,
        mobile: CustomerInfo?.MobileNo,
        EmailId: CustomerInfo?.EmailAddress,
      }
      console.log("createQuoteRequest>>>>",createQuoteRequest)
      actions.createQuote({ createQuoteRequest, updateCustomerRequest })
    } else {
      history.replace('/ineligible')
    }
  }

  let Icon =
    Eligibilty === 'GWO' ?
      IconGWO
      :
      Eligibilty === "NGWO" ?
        IconNGWO
        :
        IconPhoneIneligible

  return (
    <div>
      <div className="flex flex-row lg:flex-col items-center justify-center mb-5">
        <div id="fullinfo" className="2xl:px-p15 rounded-md lg:w-auto 2xl:p-10  lg:p-0 lg:mr-0">
          <div>
            <div className="lg:flex md:px-16 mt-8">
              <div className="md:text-f22 md:leading-30 text-40 leading-54 font-normal text-center mx-auto">
                <b>Tell us about the condition of your device</b>
              </div>
            </div>
          </div>
          <div className="md:mt-5 mt-10 bg-white w-auto md:mx-m15 justify-center px-p5 mx-auto pt-2">
            {PhoneQuestionAnswersJson &&
              PhoneQuestionAnswersJson.map((section, index) => {
                return (
                  <div className="mx-auto">
                    <div className="flex pt-2 w-full mx-auto justify-between pb-p30 md:text-sm text-base">
                      <div className="flex flex-row px-5">
                        <div
                          className='items-center'
                          dangerouslySetInnerHTML={{ __html: section.question }}
                        ></div>
                        <div className="absolute"></div>
                      </div>
                      <div className="flex items-center justify-between">
                        <button
                          onClick={() => setAnswer('N', index, section)}
                          className={`${section.answer === 'N' ? 'bg-HN-Purple-Dark text-white' : 'bg-white text-HN-Purple-Dark'
                            } md:rounded-full rounded-md border border-HN-Purple-Dark md:w-7 w-120 mr-3 md:h-7 h-60`}
                        >
                          {isMobile ? 'N' : 'No'} 
                        </button>
                        <button
                          onClick={() => setAnswer('Y', index, section)}
                          className={`${section.answer === 'Y' ? 'bg-HN-Purple-Dark text-white' : 'bg-white text-HN-Purple-Dark'
                            } md:rounded-full rounded-md border border-HN-Purple-Dark md:w-7 w-120 mr-3 md:h-7 h-60`}
                        >
                          {isMobile ? 'Y' : 'Yes'}                
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}

          </div>
          {Eligibilty && isFormComplete && (
            <div
              className={`justify-between mb-3 mx-auto`}
            >
              <div className="flex justify-center">
                <div className="">
                  <Icon />
                </div>
              </div>
            </div>

          )}
          <div className="flex flex-row justify-center md:w-auto mb-m30 md:mx-m15 mx-auto">
            <button
              className={`${isFormComplete && Eligibilty
                ? 'primary-btn bg-HN-Purple-Dark text-white'
                : 'disabled-primary-btn bg-HN-Gray-Dark text-white'
                }
          border text-center h-44 md:w-174 w-350 rounded-md mt-4 md:text-sm text-base`}
              type="button"
              disabled={!isFormComplete}
              onClick={() => createQuote()}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  )
}
export default DevicePhysicalCondition
