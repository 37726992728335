import React from 'react'

const icon = ({ className, width="203", height="203" } ) => (
<svg  className={className} width={width} height={height} viewBox="0 0 203 203" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M88.8115 164.938H114.187" stroke="#333333" stroke-width="0.808451" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M67.6675 181.857C60.6604 181.857 54.98 176.176 54.98 169.169V33.8359C54.98 26.8289 60.6604 21.1484 67.6675 21.1484H135.334C142.341 21.1484 148.022 26.8289 148.022 33.8359V169.169C148.022 176.176 142.341 181.857 135.334 181.857H67.6675Z" stroke="#333333" stroke-width="0.808451" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)

export default icon;
