import React, { useState, useEffect } from 'react'
import { getResource, getMaskedEmail } from '../../utils/helper'
import { useHistory } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import Iconback from '../../Icons/icon-back'

const Otp = (props) => {
  let history = useHistory()
  const [OTP, setOTP] = useState('')
  const isFormValid = OTP && OTP.length == 6
  const { resources, state, actions } = props
  const { ValidateResponse, CustomerInfo, DeviceType, selectedCategory } = state

  let validateOTP = () => {
    let data = {
      email: CustomerInfo.EmailAddress,
      pin: OTP,
    }
    actions.validateOTP(data)
  }

  let sendOTPMail = () => {
    let data = {
      firstName: CustomerInfo?.FirstName,
      lastName: CustomerInfo?.LastName,
      email: CustomerInfo.EmailAddress,
    }
    actions.clearOtpValidation();
    actions.sendOTPMail(data);
    setOTP("");
  }
  useEffect(() => {
    if (ValidateResponse === 'success') {
      history?.replace('/devicephysicalcondition')
    }
  }, [ValidateResponse === 'success'])

  const navigateBack = () => {
    actions.clearData('OTPResponse')
    actions.clearData('ValidateResponse')
    history.replace('/customerinfo')
  }

  return (
    <>
      <div className="px-p20 xl:w-700 md:w-auto mx-auto w-900 pb-10 ">
        <div className="align-middle">
          {' '}
          <div className="flex pt-6 pl-p5 md:pl-0">
            <div className="md:mt-2 mt-6">
              <Iconback onClick={() => navigateBack()} />
            </div>
            <div className="text-bold text-HN-Subtext-Dark md:text-f22 text-40 mx-auto m-1">
              <b>{getResource(resources, 'VALIDATE_EMAIL')}</b>
            </div>
            <div className="pt-4"></div>
          </div>
          <div className="w-700 mx-auto md:w-full">
            {/* <div className='md:text-sm text-2xl text-HN-Subtext-Dark mt-m20 mx-auto md:w-auto  '>{getResource(resources, 'CODE_SENT')} </div> <br /> */}
            <div
              className="md:text-sm text-2xl text-HN-Subtext-Dark mt-m20 mx-auto md:w-auto mb-6"
              dangerouslySetInnerHTML={{ __html: getResource(resources, 'CODE_SENT') }}
            ></div>
            <div className="pt-1 pb-2 bg-white md:w-full w-345 mx-auto h-50">
              <center>
                <p className="align-middle mt-2 text-HN-Gray-Medium font-bold">{CustomerInfo && getMaskedEmail(CustomerInfo.EmailAddress)}</p>
              </center>
            </div>
            <div className="text-bold text-f22 justify-center mt-10">
              <center>
                <b>{getResource(resources, 'ENTER_CODE')}</b>
              </center>
            </div>
            <div className="justify-center flex gap-2 mt-5">
              <OtpInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                numInputs={6}
                OTPLength={6}
                inputStyle={{
                  width: '40px',
                  height: '40px',
                  margin: '0 5px',
                  fontSize: '1rem',
                  borderRadius: 4,
                  border: '1px solid rgba(0,0,0,0.3)',
                }}
                isInputNum
                separator={<span>&nbsp;&nbsp;</span>}
              />
            </div>
            {ValidateResponse && ValidateResponse == 'Incorrect OTP' && (
              <div className="justify-center flex  text-HN-Red-Dark mt-5 text-xs leading-4">
                {getResource(resources, 'INCORRECT_OTP_ERROR')}
              </div>
            )}
            <div className="mt-3 pb-2 align-middle md:w-full w-345 mx-auto h-50 ">
              <button
                id="submit"
                className={`${isFormValid ? 'bg-HN-Purple-Dark' : 'bg-HN-Gray-Dark'
                  } rounded-md center h-11  w-full mt-5  `}
                type="button"
                onClick={() => validateOTP()}
                disabled={!isFormValid}
              >
                <p className="text-white">{getResource(resources, 'VALIDATE')}</p>
              </button>
            </div>

            <div className="pt-2 mt-3">
              <center>
                <button className="text-HN-Purple-Dark w-full mt-3" onClick={() => sendOTPMail()}>
                  {getResource(resources, 'RESEND_CODE')}
                </button>
              </center>
            </div>
            <div className="mt-4 py-2 px-3 bg-white text-HN-Gray-Medium align-middle mx-auto md:w-full w-345">
              <p className="center w-full h-45 text-sm ">
                {getResource(resources, 'CODE_EXPIRY')}
                <span className="text-sm">
                  <strong>{getResource(resources, 'EXPIRY_MINUTES')}</strong>
                </span>
              </p>
            </div>
            <div className="mt-4 pt-2 px-3 pb-2 text-text-HN-Gray-Medium bg-white align-middle mx-auto md:w-full w-345">
              <p className="center w-315  text-sm mt-2">
                {getResource(resources, 'CODE_DIDNOT_RECIEVED')}
                <span className="text-sm">
                  <strong>{getResource(resources, 'RESEND_CODE')}</strong>.
                </span>
              </p>
              <p className="text-sm mt-6">{getResource(resources, 'CHECK_EMAIL')}</p>
            </div>
          </div>
        </div>
        <div className="md:block hidden "></div>
      </div>
    </>
  )
}
export default Otp
