import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { store } from '../../Store'
import { useActions } from '../../Action'
import Text from '../common/Text'
import Button from '../common/Button'
import PageHeader from '../common/PageHeader'
import DataMapper from './dataMaper'
import { isEmpty, processFileDownload } from '../../helper/helper'
import CancelTrade from './CancelTrade'
import CancelledTrade from './CancelledTrade'
import Popup from '../common/PopUp'
import { TradeStatusCode } from '../../ActionTypes'
import ShipTrade from './ShipTrade'
import ShipQuoteSuccess from './ShipQuoteSuccess'
import { ServicePortalPages } from "../../ActionTypes"
import IconLogo from '../../assets/selfserve-assets/TradeNSave.svg'
import { useHistory } from 'react-router-dom'

const TradeProperties = {
  TradeQuoteNumber: 'Trade ID',
  MakeModelName: 'Trade-in device',
  IMEI: 'Device IMEI or serial',
  ChargedAmount: 'Trade-in credit value',
  TradeInStatusCode: 'Current status',
  TradeInExpiryDate: 'Expiry date',
  TradeCreatedDate: 'Trade-in created on',
  ChargedDate: 'Security charge date',
  TradeConfirmedDate: 'Trade-in confirmed date',
  getTradeDetailsByTradeId: 'Shipping to Asurion',
  ReceivedDate: 'Received at Asurion date',
  ConsignmentId: 'Security charge refund initiated on',
}

const GradingProperties = {
  GradedStatus: 'Graded Status',
  DeviceImages: 'Device Images',
}

const TradeDetails = () => {
  const { state, dispatch } = useContext(store)
  const { getSSTradeDetails, cancelMessage, GetDAXImageResponse, shipmentLabelResponse } = state
  const actions = useActions(state, dispatch)
  const [showCancelPopup, setShowCancelPopup] = useState(false)
  const [cancelledPopup, setShowCancelledPopup] = useState(false)
  const [showConfirmPopup, setShowConfirmPopup] = useState(false)
  const [showShipPopup, setShowShipPopup] = useState(false)
  const [disableShipmentButton, setDisableShipmentButton] = useState(false)
  const { sendOtpResponse, selfServicePortal, isLoading } = state
  const { tradeid, returnLabel } = selfServicePortal
  // const { TradeAccess: token, TradeId } = sendOtpResponse
  // const { TradeStatus: tradeStatus, RedemptionOrder, ExpiryDate: ETA, DeviceModel: ModelName } = getSSTradeDetails
  const shippingId = ''
  const requestedBy = 'PROACTIVE-BATCHING'
  const history = useHistory()

  const getParcedTradeValues = (key, value, image) => {
    //included LateFeeChargeAmount as its of number type and isEmpty will return true here.
    if (key !== 'DeviceImages' && key !== 'LateFeeChargeAmount' && key !== 'ChargedAmount' && isEmpty(value)) {
      return '-'
    }
    switch (key) {
      case 'ExpiryDate':
        return moment.utc(value).format('DD MMM YYYY')
      case 'TradeCreated':
      case 'PrechargeDate':
      case 'RefundDate':
        return moment(value).tz('Australia/Sydney').format('DD MMM YYYY')
      case 'TradeStatus':
        return TradeStatusCode[value]
      case 'DeviceImages':
        return image
      case 'LateFeeChargeAmount':
        return value ? `$${value}` : '-'
      case 'LateFeeChargeDate':
        return value ? moment(value).tz('Australia/Sydney').format('DD MMM YYYY') : '-'
      case 'ConsignmentId':
        return value ? (
          <a href={`https://auspost.com.au/mypost/track/#/details/${value}`} target="_blank" rel="noopener noreferrer" className="text-blue-1 underline">
            {value}
          </a>
        ) : (
          '-'
        )
      case 'ChargedAmount':
          return value ? `$${value?.toString()}` : '-'
      case 'ChargedDate':
      case 'TradeCreatedDate':
      case 'TradeConfirmedDate':
      case 'ReceivedDate':
        return moment(value).format('DD MMMM YYYY');
      case 'TradeInExpiryDate':
        return moment(value).subtract(1, 'days').format('DD MMMM YYYY');
      default:
        return value || '-'
    }
  }
  const tradeSummaryData = Object.keys(TradeProperties).map((k) => ({
    Key: TradeProperties[k],
    Value: getParcedTradeValues(k, getSSTradeDetails[k]),
  }))

  const handlePdfProcessing = () => {
    processFileDownload(returnLabel, `${tradeid}_Return_Label.pdf`)
  }

  const handleCancel = () => {
    const cancelpayload = {
      tradeId: getSSTradeDetails?.TradeInfoId,
      status: 'canceled',
      reason: 'Cancellation of trade from Self Service Protal',
    }
    
    const payload1 = {
      tradeId: getSSTradeDetails?.TradeInfoId,
    }

    const gettradepayload ={
      payload: payload1,
      fromOtp: false
    }
    const payload ={gettradepayload: gettradepayload, cancelpayload: cancelpayload}
  
    actions.cancelSSTrade(payload)
    setShowCancelledPopup(true)
    setShowCancelPopup(false)
  }

  const handleCancelledTrade = () => {
    setShowCancelledPopup(false)
  }

  const handleLabelButton = () => {
    if (returnLabel) {
      handlePdfProcessing()
    } else {
      actions.returnSSLabelTrade({tradeId: getSSTradeDetails?.TradeInfoId})
    } 
  } 

  useEffect(() => {
    if (returnLabel) {
      handlePdfProcessing()
    }
  }, [returnLabel])
  return (
    <div className="m-15 flex flex-col justify-center items-center">
      <div className="px-p20 pt-10 mx-auto mb-10">
        <IconLogo className="" />
      </div>
      {isEmpty(getSSTradeDetails?.TradeId) && <DataMapper dataArray={tradeSummaryData} pageHeader="Trade-in Support" pageText="Summary" />}
      {isEmpty(getSSTradeDetails?.TradeId) && (
        <>
          <PageHeader label="Manage your Trade-in" />
          {<div className="mt-6 sm:flex sm:flex-col sm:justify-center sm:max-w-500 flex flex-row justify-between mx-auto mb-10">
           <Button
              type="button"
              className="w-240"
              onClick={() => setShowCancelPopup(true)}
              disabledBgColor="text-black border-gray-600 rounded-lg opacity-50"
              marginClass="mt-4 mx-2"
              bgColor="bg-white "
              label="Cancel Trade-in"
              borderClass=" border border-gray-600 rounded-lg border-gray-600 rounded-lg "
              textClass="text-black px-6 ps:py-2 ps:px-20"
              disabled={getSSTradeDetails?.TradeInStatusCode !== 'OPEN'}
            />
            
              <Button
                type="button"
                className="w-240"
                onClick={handleLabelButton}
                marginClass="mt-4 mx-2"
                textClass="text-black px-6 ps:py-2 ps:px-4"
                bgColor="bg-white"
                label="Download Return Label"
                borderClass="border border-gray-600 rounded-lg"
                disabledBgColor=" text-black border-gray-600 rounded-lg text-black opacity-50"
                disabled={getSSTradeDetails?.TradeInStatusCode !== 'BATCHED'}
              />
          </div>
          }
           {
          getSSTradeDetails?.processRefund &&
          <div className="sm:flex sm:flex-col sm:justify-center sm:max-w-500 flex flex-row justify-between mx-auto mb-12">
          <Button
              type="button"
              className="w-240"
              onClick={() =>  actions.setSspPage(ServicePortalPages.Refund)}
              disabledBgColor=" border-gray-600 rounded-lg opacity-50"
              marginClass="mx-2"
              bgColor="bg-white"
              label="Update Refund Details"
              borderClass="bg-black border border-gray-600 rounded-lg border-gray-600 rounded-lg "
              textClass="text-black px-6 ps:py-2 ps:px-20"
              disabled={false}
            />
            </div>
          }
        </>
      )}
      <div className='pb-4'>
        <p className="font-SamsungOne-400 text-sm text-center">
        For enquiries about the Asurion Trade-In Program, please<a href={"https://corporate.asurion.com.au/contact-us"} target="_blank" rel="noreferrer" className='underline ml-1'>contact us</a>.
        </p>
      </div>
      {showShipPopup && !isLoading && (
        <Popup
          show
          global
          title={shipmentLabelResponse?.message === 'updated successfully' ? 'All done!' : 'Oops!'}
          customeStyle="px-4 py-2"
          marginClass="mt-[0.5rem] ps:mt-4"
          className="flex justify-center items-center rounded max-w-lg"
        >
          <ShipQuoteSuccess
            responseMessage={shipmentLabelResponse?.message}
            handleOk={() => {
              setShowShipPopup(false)
            }}
          />
        </Popup>
      )}
      {showCancelPopup && (
        <Popup show global title="Cancel my trade-in" customeStyle="px-4 py-2" marginClass="mt-[0.5rem] ps:mt-4" className="flex justify-center items-center rounded bottom-0" style={{ position: 'fixed', bottom: 0, width: '100%' }}>
          <CancelTrade handleCancel={handleCancel} closePopup={() => setShowCancelPopup(false)} />
        </Popup>
      )}

      {cancelledPopup && (
        <Popup show global title="Trade-in Cancelled" customeStyle="px-4 py-2" marginClass="mt-[0.5rem] ps:mt-4" className="w-full flex justify-center items-center rounded bottom-0" style={{ position: 'fixed', bottom: 0, width: '100%' }}>
          <CancelledTrade handleCancelledTrade={handleCancelledTrade} closePopup={() => {setShowCancelledPopup(false), setShowCancelPopup(false)}} />
        </Popup>
      )}
    </div>
  )
}
export default TradeDetails
