import { v4 as uuid } from 'uuid'

let returnDAXSupportedDevices = (modelsByMake, devices) => {
  return modelsByMake.filter((model) => devices.some((device) => model.AssetCatlogName.indexOf(device.name) > -1))
}

let uniqFilter = (targetArray, compareKey) => {
  let filterdData = targetArray.map((currentObj) => currentObj[compareKey])
  return uniq(filterdData)
}

let find = (targetArray = [], compareKey, compareValue) => {
  let filterdData = targetArray.filter((currentObj) => currentObj[compareKey] === compareValue)
  return uniq(filterdData)
}

let uniq = (targetArray = []) => {
  return [...new Set(targetArray)]
}

let createKeyValuePair = (targetArray) => {
  return targetArray.map((currentValue) => {
    return { key: currentValue, value: currentValue }
  })
}

const formatFee = (fee) => {
  if (fee) {
    return fee.split('.')[0]
  }
  return ''
}



function toPascalCase() {
  return this.replace(/\w\S*/g, (t) => {
    return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase()
  })
}

const getResource = (resources, key) => {
  return resources[key] || key
}

let formatCapacity = (capacity = '') => {
  return capacity.toUpperCase().indexOf('GB') > -1 ? capacity : `${capacity}GB`
}

const formatMobile = (phoneNo) => {
  if (!phoneNo) {
    return ''
  }
  switch (phoneNo.length) {
    case 10:
      return phoneNo.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
    case 11:
      return phoneNo.replace(/(\d{4})(\d{3})(\d{4})/, '$1 $2 $3')
    case 9:
      return phoneNo.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')
    case 8:
      return phoneNo.replace(/(\d{4})(\d{4})/, '$1 $2')
    default:
      return phoneNo
  }
}

let sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

let createMessage = (type, author, data) => {
  const messageAuthor = author.toLowerCase() === 'user' ? 'User' : 'System'
  let source = author.toLowerCase() === 'user' ? 'User' : author.toLowerCase() === 'agent' ? 'Agent' : 'System'

  return {
    messageId: uuid(),
    type,
    author: messageAuthor,
    data: data ? data : null,
    source: source,
    time: new Date().toISOString(),
  }
}

let createUserMessage = (type, data) => {
  return createMessage(type, 'User', data)
}

let createSystemMessage = (type, data) => {
  return createMessage(type, 'System', data)
}

let createAgentMessage = (type, data) => {
  return createMessage(type, 'Agent', data)
}

let isEmptyObject = (obj) => {
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    obj.constructor === Object
  )
}

let generateArrayOfObjects = (start, end) => {
  let arr = [];
  for (var i = start; i <= end; i++) {
    arr.push({ key: i, value: i });
  }
  return arr;
};

let isEmpty = (input) => {
  if (typeof input === 'undefined' || input === 'null') {
    return true
  }
  if (typeof input === 'function') {
    return false
  }
  if (Array.isArray(input)) {
    return input.length === 0
  }
  if (typeof input === 'string' && input.trim().length === 0) {
    return true
  }
  return !input || Object.keys(input).length === 0
}

let chunkArray = (arr, val) => {
  let finalArr = [];
  for (var i = 0; i < arr.length; i += val) {
    finalArr.push(arr.slice(i, val + i));
  }
  return finalArr
}

export const getUniqueValuesFromArray = (array, key) => {
  return array.filter(
    (asp, index, self) => self.findIndex((a) => a[key] === asp[key]) === index
  );
};

let validateCardNumber = (value) => {
  // const validateCreditCardNumber = (valuev) => {
  if (/[^0-9-\s]+/.test(value)) return false;

  // The Luhn Algorithm.
  let nCheck = 0,
    bEven = false;
  value = value.replace(/\D/g, "");

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

    nCheck += nDigit;
    bEven = !bEven;
  }
  if (value.length < 16) {
    return false;
  }

  return nCheck % 10 == 0;
};

let validateCardName = (value) => {
  let name = value?.trim();
  if (name === '') {
    return false;
  } else if (!name.match(/^([a-zA-Z-]+\s)*[a-zA-Z-]+$/i)) {
   return false;
  }
 else if (!name || name?.length < 1 || name?.length > 40) return false;
  return /^[A-Za-z\s]*$/.test(name);
};

let validateCvv = (value) => {
  let checkCVV = /^\d+$/;
  if (!value || value?.length < 1 || value?.length > 3) return false;
  else if(!checkCVV.test(value))
  {
    return false;
  }
  else{
    return true;
  }
};

const covertToUTCDate = (date = new Date()) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  ).toString();
};

const getDateFromUtcTimeZone = (offset, date = new Date()) => {
  const offsetMultiplier = 3600000;
  const utcMs = new Date(covertToUTCDate(date)).getTime();
  const returnDate = new Date(utcMs + offsetMultiplier * offset);
  return returnDate;
};

const isValidExpiryDate = (month, year) => {
  const givenMonth = parseInt(month);
  const givenYear = parseInt(year);
  const today = getDateFromUtcTimeZone(8);
  const currentMonth = parseInt(today.getMonth()) + 1;
  const currentYear = parseInt(String(today.getFullYear()));
  const restrictionMonth = currentMonth + 3;
  
  return (
    givenMonth > 0 &&
    givenMonth < 13 &&
    (givenYear  > currentYear+1 ||  ( currentMonth > 9 ? (givenYear-1 === currentYear && (currentMonth === 10 && givenMonth > 1 || currentMonth === 11 && givenMonth > 2 || currentMonth === 12 && givenMonth > 3)) : givenYear  > currentYear ) ||
      (givenMonth > currentMonth && givenYear === currentYear && givenMonth > restrictionMonth))
  );
};

const getMaskedEmail = (email) => {
	let maskedEmail = email.replace(/([^@\.])/g, "*").split('');
	let previous	= "";
  let i;
	for(i=0;i<maskedEmail.length;i++){
		if (i%3 == 2 || previous == "." || previous == "@"){
			maskedEmail[i] = email[i];
		}
		previous = email[i];
	}
	return maskedEmail.join('');
}


export {
  uniqFilter,
  uniq,
  createKeyValuePair,
  find,
  formatFee,
  returnDAXSupportedDevices,
  toPascalCase,
  getResource,
  formatCapacity,
  formatMobile,
  sleep,
  createAgentMessage,
  createUserMessage,
  createMessage,
  createSystemMessage,
  isEmptyObject,
  isEmpty,
  chunkArray,
  generateArrayOfObjects,
  validateCardNumber,
  validateCvv,
  validateCardName,
  isValidExpiryDate,
  getMaskedEmail
}
