import Input from "../Common/Input";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom'
import { PaymentClient, Env } from "@backoffice/fast-payments-client-js-sdk";
import Dropdown from "../Common/DropDown";
import {
  generateArrayOfObjects,
  isEmpty,
  validateCardName,
  validateCvv,
  isValidExpiryDate
} from "../../utils/helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import Iconback from '../../Icons/icon-back'
import TrustIcon from '../../Icons/icon-trust-pay'


export const ErrorTypes = {
    INVALID_CARD_NUMBER: "Credit Card Number is invalid. Please verify and try again.",
    INVALID_CARD_NAME: "Invalid cardholder name. Please verify and try again.",
    INVALID_CVV: "CVV should be 3 digits long.",
    };

const PaymentDetails = (props) => {
const history = useHistory()
const msgRef = useRef(null)

const { resources, state, actions } = props
const { CreateQuoteResponse, token, CustomerInfo, DeviceType, SelectedDevice } = state

  const [cardNumber, setCardNumber] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvv, setCvv] = useState("");
  const d = new Date();
  let currentYear = d.getFullYear();
  let currentMonth = d.getMonth() + 1;
  const [IsValidCardNO, setIsValidCardNO] = useState(true);
  const [IsValidCardName, setIsValidCardName] = useState(true);
  const [IsValidCvv, setIsValidCvv] = useState(true);
  const [isDisable, setIsDisable] = useState(true);
  const [paymentError, setPaymentError] = useState(false);
  const [isValidCard, setValidCard] = useState(true);
  const [isValidExpiry, setIsValidExpiry] = useState(true);
  const [cardValidate, setCardValidate] = useState(true);

  let months = (
    year === currentYear.toString()
      ? generateArrayOfObjects(currentMonth, 12)
      : generateArrayOfObjects(1, 12)
  ).map((m) => {
    m.value = m.value.toString();
    if (m.value.length === 1)
      return { key: "0" + m.value, value: "0" + m.value };
    return m;
  });

  useEffect(() => {
    if (msgRef.current) {
      msgRef.current.scrollTo(0, msgRef.current.scrollHeight + 100);
    }
  }, [true]);

  const handleCapture = () => {
    if (
      isEmpty(cardNumber) ||
      isEmpty(nameOnCard) ||
      isEmpty(month) ||
      isEmpty(year) ||
      isEmpty(cvv) ||
      !validateCreditCardNumber(cardNumber) ||
      !validateCardName(nameOnCard) ||
      !validateCvv(cvv) ||
      cvv?.length !== 3 ||
      !isValidExpiry
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    handleCapture();
  }, [cardNumber, nameOnCard, month, year, cvv, isValidExpiry]);
 

  useEffect(()=>{
    actions.setLoader()
    API[ActionTypes.GET_PCITOKEN]({tradeInfoId: CreateQuoteResponse?.tradeInfoId })
    .then((tokenResponse) => {
        actions?.setPcitoken(tokenResponse?.data?.getSecurityTokenResponse)
        actions.unSetLoader()
    })
    .catch((error) => {   
        actions.unSetLoader()   
    });
  },[])

  async function validateCC() {
    
    actions.setLoader();
    const paymentClient = new PaymentClient({
      env: process.env.BG_TRANSACTION_ENV === 'PROD' ? Env['prod-apac'] : Env["qa-apac"]
    });
    const billingContactInfo = {
      name: {
        first: CustomerInfo?.FirstName || "test",
        last: CustomerInfo?.LastName || "test",
      },
      address: {
        country: "AU",
        zip: 3000,
      }
    };
    const creditCardInfo = {
      number: cardNumber,
      expiration: {
        year: year?.toString(),
        month: month?.toString(),
      },
      securityCode: cvv?.toString(),
      nameOnCard: nameOnCard?.toString(),
    };

    return Promise.all([
      paymentClient.addSession(token?.token, {
        appName: "ENRPORTAL",
        encryptionKey: token?.EncryptionKey,
        currency: "AUD",
      }),
      paymentClient.addBillingContactInfo(billingContactInfo),
      paymentClient.addCreditCardInfo(creditCardInfo),
    ])
      .then((resp) => {
        return paymentClient.processPayment();
      })
      .then((processPaymentResponse) => {
        const updateMethodRequest = {
          tradeId: CreateQuoteResponse?.tradeInfoId,
          method: "ONLINE",
          token: token?.token
        };

        API[ActionTypes.SET_UPDATE_METHOD]({ ...updateMethodRequest })
          .then((updateMethodResponse) => {
            setPaymentError(false)
      
            history.replace("/confirmpayment")
            actions?.unSetLoader();
          })
          .catch((error) => {
            setPaymentError(true)
            actions?.unSetLoader();
          });
      })
      .catch((error) => {
        setPaymentError(true)
        actions?.unSetLoader();
      })

      .catch((error) => {
        setPaymentError(true)
        actions?.unSetLoader();
        return error;
      });
  }

useEffect(()=>{

  if(month && year)
  {
    const isValid = isValidExpiryDate(month, year);
    if (!isValid) {
      setIsValidExpiry(false);
    } else {
      setIsValidExpiry(true);
    }
  }
},[month,year])
    

  function luhnCheck(val) {
    var sum = 0;
    for (var i = 0; i < val.length; i++) {
      var intVal = parseInt(val.substr(i, 1));
      if (i % 2 == 0) {
        intVal *= 2;
        if (intVal > 9) {
          intVal = 1 + (intVal % 10);
        }
      }
      sum += intVal;
    }
    return sum % 10 == 0;
  }

  const validateCreditCardNumber = (cardNumber) => {
    var regex = new RegExp("^[0-9]{16}$");
    if (!regex.test(cardNumber)) return false;

    return luhnCheck(cardNumber);
  };

  const validateCreditCardLength = (cardNumber) => {
    cardNumber = cardNumber.replace(/\s/g, "");
    if (cardNumber === 16) {
      return true;
    } else {
      return false;
    }
  };
  const setCard = (e) => {
    if (/[^0-9-\s]+/.test(e)) return false;
    setCardNumber(e);
  };

  const navigateBack = () => {
    // actions.clearData('selectStorage')
   
    history.replace('/securitychargefaq')
  }

  return (
    <div className="">
        <div className="flex flex-row items-center pt-6 px-p20 max-w-3xl w-full mx-auto">
                <div className=''>
                            <Iconback onClick={() => navigateBack()} />
                </div>
                <div className="text-bold  text-3xl plg:text-40 mx-auto leading-54 text-center">
                    <b>Security Charge</b>
                </div>
        </div>
        <div id="paymentDetails" className={`max-w-xl mx-auto  w-full flex flex-col `}>
        <div className="bg-white px-p40 py-p8 plg:py-p20 mx-m20 my-m42">
            <p className="text-center text-2xl md:text-xl">
            Payment details
        </p>
        <p className="font-bold text-center pb-0 pt-p37 text-base">
        {SelectedDevice?.[0]?.AssetCatalogName}
        </p>
        <p className="text-center py-1 text-sm">
          Security Charge
        </p>
        <p
          className="font-medium text-center pb-p37 text-HN-Purple-Dark text-2xl"
        >
          ${CreateQuoteResponse?.quotePrice || 0}
        </p>
        <div  className="flex md:flex-col flex-row">
            <Input
            type="tel"
            wrapperClassName="pb-4  pmb:pr-3"
            label="Card number"
            value={cardNumber}
            onChange={(e) => {
                setCard(e);
                setValidCard(validateCreditCardNumber(e));
                setValidCard(validateCreditCardLength(e));
            }}
            error={!validateCreditCardNumber(cardNumber) && !isEmpty(cardNumber)}
            errorMessage={ErrorTypes?.INVALID_CARD_NUMBER}
            className={`${"text-HN-Grey-Dark"}`}
            maxLength={16}
            />

            <Input
            type="text"
            wrapperClassName="pb-4 pmd:pl-3 "
            label="Name on card"
            value={nameOnCard}
            onChange={(e) => {
                setNameOnCard(e);
                !isEmpty(e)
                ? setIsValidCardName(validateCardName(e))
                : setIsValidCardName(true);
            }}
            error={!IsValidCardName}
            errorMessage={ErrorTypes?.INVALID_CARD_NAME}
            className={`${"text-HN-Grey-Dark"}`}
            />
        </div>
        <div className="flex pb-8 sm:pb-4 flex-row">
            <Dropdown
            options={months || []}
            value={month}
            onChange={(e=>{
              setMonth(e)
            })}
            placeholder="MM"
            label="MM"
            wrapperClassName={!month && "mt-4"}
            />
            <Dropdown
            options={generateArrayOfObjects(currentYear, 2099) || []}
            onChange={(e=>{
              setYear(e)
            })}
            value={year}
            placeholder="YY"
            label="YY"
            wrapperClassName={`pl-3 ${!year && "mt-4"}`}
            />
            <Input
            type="password"
            wrapperClassName="pl-3 sm:hidden block pt-2"
            label="CVV"
            value={cvv}
            onChange={(e) => {
                if (e.length <= 3 && !/[^0-9\s]+/.test(e)) {
                setCvv(e?.trim());
                !isEmpty(e) ? setIsValidCvv(validateCvv(e)) : setIsValidCvv(true);
                }
            }}
            error={!IsValidCvv}
            errorMessage={ErrorTypes?.INVALID_CVV}
            className={`${"text-HN-Grey-Dark"}`}
            />
            
        </div>
        <Input
            type="password"
            wrapperClassName="pb-8 hidden sm:block w-full"
            label="CVV"
            value={cvv}
            onChange={(e) => {
                if (e.length <= 3) {
                setCvv(e);
                !isEmpty(e) ? setIsValidCvv(validateCvv(e)) : setIsValidCvv(true);
                }
            }}
            error={!IsValidCvv}
            errorMessage={ErrorTypes?.INVALID_CVV}
            className={`${"text-HN-Grey-Dark w-full"}`}
            />
        {paymentError && <p className="text-center text-red-600 mb-4">Authentication failed.</p>}
        {!isValidExpiry && <p className="text-center text-red-600">Your credit card is due to expire, please enter another payment method.</p>}
        <div ref={msgRef}  className="py-p20 flex items-center justify-center">
          <TrustIcon/>
        </div>
        </div>
            <button
            onClick={() => {
                validateCC();
            }}
            className={`${
                !isDisable ? 'primary-btn bg-HN-Purple-Dark' : 'disabled-primary-btn bg-HN-Gray-Dark '
            } text-white mb-m20 max-w-xs w-full mx-auto`}
            disabled={isDisable}>Proceed to payment</button>
        </div>
    </div>
  );
};

export default PaymentDetails;
