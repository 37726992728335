import React from "react";
import { useAppState } from "../../Store";

const RefundFaq = () => {
  const state = useAppState();
  const { SelectedDevice, Eligibilty } = state
  let simtype = SelectedDevice && SelectedDevice[0] && SelectedDevice[0]?.SimType
  let isLvd = simtype && simtype.includes('LVD')


  const refundAmount = SelectedDevice?.[0]?.GwoPrice - SelectedDevice?.[0]?.NgwoPrice;

  return (
    <div>
      <div className="border-b border-HN-Grey-Border pb-p4">
        <div className="flex flex-row justify-between">
          <p className="">Device is not returned</p>
          <span className="px-p4 py-p4  w-fit my-2 text-white font-semibold rounded-[2px] lg:text-sm text-base bg-black">
            No refund
          </span>
        </div>
        <p>You may also be charged a late return fee.</p>
      </div>
      <div className="border-b border-HN-Grey-Border">
        <div className="flex flex-row justify-between pt-p4">
          <p className="font-bold">Device is not eligible</p>
          <span className="px-p4 py-p4 w-fit my-2 text-white rounded-[2px] lg:text-sm text-base bg-black font-semibold">
            No refund
          </span>
        </div>
        <ul className="pb-p12">
          <li class="list-disc ml-7">Security features are enabled.</li>
          <li class="list-disc ml-7">AMTA blocked.</li>
          <li class="list-disc ml-7">Incorrect Device.</li>
        </ul>
      </div>
      <div className="flex flex-row justify-between pt-p4">
        <p className="font-bold">Device Mis-graded</p>
        <div style={{ minWidth: '60px' }} className="text-center px-p4 py-p4 bg-HN-Purple-Dark text-white text-base w-fit my-2 p-white rounded-[2px] min-w-max p-sm bg-brand-primary font-semibold">
          {isLvd ? "No refund" : Eligibilty !== 'GWO' ? "No refund" : Eligibilty === 'GWO' && SelectedDevice?.[0]?.NgwoPrice < 1 ? "No refund" : refundAmount > 1 ? `$${refundAmount?.toFixed(2)}` : "$1"}
        </div>
      </div>
      <p>
        If your device has unexpected damage, but is still eligible to trade-in,
        you may receive a partial refund of the Security Charge.
      </p>
    </div>
  );
};

export default RefundFaq;
