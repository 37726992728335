export default function errorMessageGenerator(validatorId, label, opts, resources) {
    const _label = (resources && resources[label]) || label || opts.label;
    const localOpts = opts || {}

    switch (validatorId) {
        case 'required':
            return resources.VALIDATION_ERROR_REQUIRED_DEFAULT.replace('{label}', _label)
        case 'maxLength':
            return resources.VALIDATION_ERROR_INT_MAX_DEFAULT.replace('{label}', _label).replace('{max}', localOpts.maxLength)
        case 'minLength':
            return resources.VALIDATION_ERROR_INT_MIN_DEFAULT.replace('{label}', _label).replace('{min}', localOpts.minLength)
        case 'onlySpace':
            return resources.VALIDATION_ERROR_REQUIRED_DEFAULT.replace('{label}', _label)
        case 'onlyChar':
            return resources.VALIDATION_ERROR_ALPHA_DEFAULT.replace('{label}', _label)
        case 'validatehyphen':
            return resources.VALIDATION_ERROR_REQUIRED_DEFAULT.replace('{label}', _label)
        case 'alpha':
            return resources.VALIDATION_ERROR_ALPHA_DEFAULT.replace('{label}', _label)
        case 'email':
            return resources.VALIDATION_ERROR_EMAIL_DEFAULT.replace('{label}', _label)
        case 'numeric':
            return resources.VALIDATION_ERROR_NUMERIC_DEFAULT.replace('{label}', _label)
        case 'invalidMobilenum':
            return resources.VALIDATION_ERROR_LENGTH_DEFAULT.replace('{label}', _label)
        case 'numberlength':
            return resources.VALIDATION_ERROR_LENGTH_DEFAULT.replace('{label}', _label)
        case 'invalidExpiryDate':
            return resources.VALIDATION_ERROR_EXPIRY_DATE.replace('{label}', _label)
        case 'cardLength':
            return resources.VALIDATION_ERROR_CARD_LENGTH.replace('{label}', _label)
        case 'creditCard':
            return resources.VALIDATION_ERROR_CARD_VALIDATE.replace('{label}', _label)
        case 'password':
            return resources.VALIDATION_ERROR_PASSWORD.replace('{label}', _label)
        case 'emailormobile':
            return resources.VALIDATION_ERROR_EMAIL_OR_MOBILE.replace('{label}', _label)
        default:
            return resources.VALIDATION_ERROR_REQUIRED_VALIDATE.replace('{label}', _label)
    }
}
