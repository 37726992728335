
import React from 'react'

const icon = ({ className }) => (

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="12" r="7.5" stroke="#6B4EA0"/>
</svg>
)

export default icon;
