import React, { useState, useEffect, useRef } from "react";
import Input from "../Common/Input";

import { useHistory } from 'react-router-dom'
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import Iconback from '../../Icons/icon-back'
import TrustIcon from '../../Icons/icon-trust-pay'
import { BillingGatewayRegion, BillingGatewayService, Environment, Locale, PaymentUI, PaymentUIReference } from 'fast-payments-BGBT/microui';

export const ErrorTypes = {
  INVALID_CARD_NUMBER: "Credit Card Number is invalid. Please verify and try again.",
  INVALID_CARD_NAME: "Invalid cardholder name. Please verify and try again.",
  INVALID_CVV: "CVV should be 3 digits long.",
};

const PaymentDetails = (props) => {
  const history = useHistory()
  const msgRef = useRef(null)

  const { state, actions } = props
  const { CreateQuoteResponse, SelectedDevice, maid, token } = state

  const [paymentError, setPaymentError] = useState(false);
  const [isValidExpiry, setIsValidExpiry] = useState(true);

  let paymentUI = React.useRef(null);
  const billingGatewayService = new BillingGatewayService({
    environment: process.env.BG_TRANSACTION_ENV === 'PROD' ? Environment.PROD : Environment.QA ,
    region: BillingGatewayRegion.APAC,
    applicationId: "harvey_norman",
    billingProgramId: maid,
  });

  let billingInformation = {
    address: {
      address1: state?.StreetNo,
      address2: state?.StreetName,
      city: state?.Suburb,
      stateOrProvince: state?.State,
      postalCode: state?.PostCode,
    },
  }

  useEffect(() => {
    if (msgRef.current) {
      msgRef.current.scrollTo(0, msgRef.current.scrollHeight + 100);
    }
  }, [true]);

  const submitPayment = async () => {
    paymentUI.current.continue();
  };

  const handlePaymentSuccess = async (response) => {
    actions.setPaymentMethod(response.paymentMethod)
    actions.setSSPemailId(response.paymentMethod)
    actions.setLoader();

    const updateMethodRequest = {
      tradeId: CreateQuoteResponse?.tradeInfoId,
      method: "ONLINE",
      token: token?.token
    };

    API[ActionTypes.SET_UPDATE_METHOD]({ ...updateMethodRequest })
      .then(() => {
        setPaymentError(false)
        history.replace("/confirmpayment")
        actions?.unSetLoader();
      })
      .catch((error) => {
        setPaymentError(true)
        actions?.setPcitoken(undefined)
        actions.setLoader()
        API[ActionTypes.GET_PCITOKEN]({ tradeInfoId: CreateQuoteResponse?.tradeInfoId, amount: CreateQuoteResponse?.quotePrice })
          .then((tokenResponse) => {
            actions?.setPcitoken(tokenResponse?.data?.getSecurityTokenResponse)
            actions.unSetLoader()
          })
          .catch((error) => {
            actions.unSetLoader()
          });
      });
  }

  const handlePaymentFailure = (response) => {
    console.log(response, 'Transaction Failed')
    if (response?.status === 'error') {
      actions?.setPcitoken(undefined)
      actions.setLoader()
      API[ActionTypes.GET_PCITOKEN]({ tradeInfoId: CreateQuoteResponse?.tradeInfoId, amount: CreateQuoteResponse?.quotePrice })
        .then((tokenResponse) => {
          actions?.setPcitoken(tokenResponse?.data?.getSecurityTokenResponse)
          actions.unSetLoader()
        })
        .catch((error) => {
          actions.unSetLoader()
        });
    }
  }

  const navigateBack = () => {
    history.replace('/securitychargefaq')
  }

  return (
    <div className="">
      <div className="flex flex-row items-center pt-6 px-p20 max-w-3xl w-full mx-auto">
        <div className=''>
          <Iconback onClick={() => navigateBack()} />
        </div>
        <div className="text-bold  text-3xl plg:text-40 mx-auto leading-54 text-center">
          <b>Security Charge</b>
        </div>
      </div>
      <div id="paymentDetails" className={`max-w-xl mx-auto  w-full flex flex-col `}>
        <div className="bg-white px-p40 py-p8 plg:py-p20 mx-m20 my-m42">
          <p className="text-center text-2xl md:text-xl">
            Payment details
          </p>
          <p className="font-bold text-center pb-0 pt-p37 text-base">
            {SelectedDevice?.[0]?.AssetCatalogName}
          </p>
          <p className="text-center py-1 text-sm">
            Security Charge
          </p>
          <p
            className="font-medium text-center pb-p37 text-HN-Purple-Dark text-2xl"
          >
            ${CreateQuoteResponse?.quotePrice || 0}
          </p>

          <div className="flex pt-8 flex-col md:flex-row">
            {token?.token &&
              <PaymentUI
                applicationSession={token?.token}
                billingGatewayService={billingGatewayService}
                onPaymentSuccess={response => handlePaymentSuccess(response)}
                onPaymentFailure={error => handlePaymentFailure(error)}
                onLoading={() => console.log('Form loading...')}
                onLoaded={() => console.log('Form loaded and ready to present...')}
                ref={paymentUI}
                hideContinueButton={true}
                billingInformation={billingInformation}
                locale={Locale.enAU}
              />
            }
          </div>

          {paymentError && <p className="text-center text-red-600 mb-4">Authentication failed.</p>}
          {!isValidExpiry && <p className="text-center text-red-600">Your credit card is due to expire, please enter another payment method.</p>}
          <div ref={msgRef} className="py-p20 flex items-center justify-center">
            <TrustIcon />
          </div>
        </div>
        <button
          onClick={() => {
            submitPayment();
          }}
          className='primary-btn bg-HN-Purple-Dark text-white mb-m20 max-w-xs w-full mx-auto'
        >Proceed to payment</button>
      </div>
    </div>
  );
};

export default PaymentDetails;
