const validateOnlySpace = (value) => {
  if (!value.replace(/\s/g, '').length) {
    return false;
  }
  else {
    return true
  }
}

const validateOnlyhyphen = (value) => {
  return value.trim() == '-' ? false : true;
}

const validateMobileNumberFormat = (mdn) => {
  let prefix = mdn.substring(0, 4);
  let prefix1 = mdn.substring(0, 5);
  let subprefix = mdn.substring(0, 1);
  let substring = subprefix === '0' ? mdn.substring(1, mdn.length) : (prefix1 === '+852-' ? mdn.substring(5, mdn.length) : mdn.substring(4, mdn.length))
  if (prefix === '+61-' || prefix === '+91-' || prefix === '+65-' || prefix === '+63-' || subprefix === '0' || prefix1 === '+852-') {
    if (substring.match(/^[0-9]+$/i) != null) {
      return true
    }
    else {
      return false
    }
  }
  else {
    return false
  }
}
const validateMobileNumberLength = (mobileNumber) => {
  let prefix = mobileNumber.substring(0, 4);
  let prefix1 = mobileNumber.substring(0, 5);
  let subprefix = mobileNumber.substring(0, 1);
  if ((prefix === '+61-' || prefix === '+91-' || prefix === '+65-' || prefix === '+63-') &&
    (mobileNumber.length < 12 || mobileNumber.length > 15)) {//including prefix
    return false
  }
  if ((prefix1 === '+852-') &&
    (mobileNumber.length < 13 || mobileNumber.length > 16)) {//including prefix
    return false
  }
  else if (subprefix === '0' && mobileNumber.length != 10) {
    return false
  }
  else {
    return true
  }
}

const schemas = {
  deviceDetails: {
    device: {
      required: false
    }
  },
  customerInfoDetails: {
    email: {
      required: true,
      validate: {
        email: (value) => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) != null,
      },
      minLength: 0,
      maxLength: 256
    },
    firstname: {
      required: true,
      validate: {
        onlyChar: (value) => value.match(/^[a-zA-Z ]*$/i) != null,
        //alpha: (value) => value.match(/^([a-zA-Z-]+\s)*[a-zA-Z-]+$/i) != null,
        onlySpace: (value) => validateOnlySpace(value),
        validatehyphen: (value) => validateOnlyhyphen(value),
      },
      minLength: 0,
      maxLength: 50,
    },
    lastname: {
      required: true,
      validate: {
        onlyChar: (value) => value.match(/^[a-zA-Z ]*$/i) != null,
        //alpha: (value) => value.match(/^([a-zA-Z-]+\s)*[a-zA-Z-]+$/i) != null,
        onlySpace: (value) => validateOnlySpace(value),
        validatehyphen: (value) => validateOnlyhyphen(value),
      },
      minLength: 0,
      maxLength: 50,
    },
    mobileno: {
      required: true,
      validate: {
        //alpha: (value) => value.match(/(^[0-9])/) != null,
        invalidMobilenum: (value) => validateMobileNumberFormat(value),
        numberlength: (value) => validateMobileNumberLength(value),
      },
    },
    fullAddress: {
      required: true
    }
  },
  macImeiDetails: {
    serialno: {
      required: true,
      validate: {
        alpha: (value) => value.match(/^[a-zA-Z0-9]+$/i) != null
      },
      minLength: 8,
      maxLength: 15,
    }
  },
  phoneImeiDetails: {
    serialno: {
      required: true,
      validate: {
        numeric: (value) => value.match(/^[0-9]+$/i) != null,
      },
      minLength: 15,
      maxLength: 15,
    }
  }
}

const validator = (formName, fieldName) => {
  if (schemas[formName] && schemas[formName][fieldName]) {
    return schemas[formName][fieldName]
  }
}

export default validator
