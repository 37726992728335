import React from 'react'
import { useHistory } from 'react-router-dom'
import { getResource } from '../../utils/helper'

const DeviceAssessed = (props) => {
  const { resources, state, actions } = props
  const { CreateQuoteResponse, Eligibilty, DeviceType } = state
  const history = useHistory()
  const isLvd =
    DeviceType === 'LVD Laptop' ||
    DeviceType === 'LVD Tablet' ||
    DeviceType === 'LVD Phone' ||
    DeviceType === 'LVD Watch'
      ? true
      : false

  return (
    <div className="w-700 mx-auto md:w-auto ">
      <div className="flex pt-6 justify-between items-center md:px-p20 md:justify-start">
        <div className="text-HN-Green-Dark font-bold md:text-f22 text-40 mx-auto flex justify-center">
          {getResource(resources, 'TEXT_CONGRATULATIONS')}
        </div>
        <div className="md:hidden block"></div>
      </div>
      <div className="md:w-full md:px-p20 pt-5 text-center text-32">
        {getResource(resources, 'SUCCESSFULLY_ASSESSED')}
      </div>
      <div className="md:w-320 w-520 mx-auto bg-white mt-6 rounded-full md:mx-m15">
        <div className="flex flex-row justify-center items-center">
          <p className="flex flex-col md:text-sm text-2xl">{getResource(resources, 'TRADE_IN_DEVICE')}</p>
          <p className="flex flex-col md:text-24 leading-54 py-3 text-40 font-bold text-purple-700 px-2">
            {'$' + (CreateQuoteResponse && CreateQuoteResponse?.quotePrice)}
          </p>
        </div>
      </div>
      {isLvd ? (
        <div className="flex md:flex-col flex-row justify-center ml-3 mx-auto pt-4 items-center md:mx-0">
          <div className="text-base text-center pt-2 ml-5 md:ml-0">
            {
              'You may be eligible for bonus credit via promotional offers in store, subject to Harvey Norman, Domayne or Joyce Mayne.'
            }
          </div>
        </div>
      ) : (
        ''
      )}
{/* 
      <div className="flex md:flex-col flex-row justify-center ml-3 mx-auto pt-4 items-center md:mx-0">
        <div className="md:text-base text-f17 text-center pt-2 ml-5 md:ml-0 font-bold">
          {!isLvd ? getResource(resources, `${Eligibilty}_DEVICE_CONDITION`) : 'Device is accepted for trade-in'}
        </div>
      </div> */}
      {/* <div className="mt-5 w-375 md:w-auto mx-auto">
        <DeviceEvalution
          actions={actions}
          resources={resources}
          state={state}
          setToBottom={false}
          title="Asurion Trade-in Assessment"
          noDeviceName={true}
          autoExpand={true}
          notFixedPosition={true}
        />
      </div> */}
      {!isLvd ? (
        <div className="text-base text-center mt-2 pb-2 py-4 mx-2">To proceed with the trade-in transaction and receiving a Trade ID, you will need to provide some additional information to us and input your payment card details. Select 'Next' to continue.</div>
      ) : (
        <div className="mt-24">
          <p className="text-center md:text-base text-xl text-bold ">
          To proceed with the trade-in transaction and receiving a Trade ID, you will need to provide some additional information to us and input your payment card details. Select 'Next' to continue.
          </p>
        </div>
      )}

      <div
        className="mt-6 text-white md:mx-m15 md:w-auto w-345 mx-auto bg-HN-Purple-Dark rounded-md mb-m20 text-center p-2 "
        onClick={() => history.replace('/terms')}
      >
        <button className=" md:w-full mx-auto bg-HN-Purple-Dark rounded-md ">
          {getResource(resources, 'COMMON_BTN_NEXT')}
        </button>
      </div>
    </div>
  )
}
export default DeviceAssessed
