/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */

import React from 'react'

const icon = ({ className, isSelected }) => (
    <svg width="79" height="52" viewBox="0 0 79 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M76.9896 50H2V1.59363" stroke="#6B4E9F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)

export default icon

