import React from "react";
import PropType from "prop-types";

// import { getImage } from "./image.mapper";

const Image = ({
  type,
  className,
  imgClass,
  width,
  height,
  src,
  onError,
  marginClass,
  ...rest
}) => {
  const imageSrc = src ? src : "";
  return (
    <div className={className} {...rest}>
      <img
        className={`${marginClass} ${imgClass}`}
        src={imageSrc}
        alt={type}
        width={width}
        height={height}
        onError={onError}
      />
    </div>
  );
};

Image.propTypes = {
  type: PropType.string,
  className: PropType.string,
  imgClass: PropType.string,
  width: PropType.string,
  marginClass: PropType.string,
  src: PropType.any,
  onError: PropType.func,
};

Image.defaultProps = {
  type: "",
  marginClass: "mx-auto",
  className: "w-full",
  imgClass: "w-full",
  width: "w-full",
  src: undefined,
  onError: undefined,
};

export default Image;
