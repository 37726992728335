import types from './ActionTypes'

const useActions = (state, dispatch) => ({
  setLoader: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
  },
  unSetLoader: (data) => {
    dispatch({ type: types.UNSET_LOADING_INDICATOR })
  },
  // getResources: () => {
  //   dispatch({ type: types.GET_RESOURCE_REQUEST })
  // },
  getMasterData: () => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_MASTER_DATA_REQUEST })
  },
  clearState: () => {
    dispatch({ type: types.CLEAR_STATE });
  },
  setProcessor: (data) => {
    dispatch({ type: types.SET_PROCESSOR, payload: data })
  },
  setDeviceInformationObject: (data) => {
    dispatch({ type: types.SET_DEVICE_INFORMATION_OBJECT, payload: data })
  },
  setCategory: (data) => {
    dispatch({ type: types.SET_CATEGORY, payload: data })
  },
  setStorage: (data) => {
    dispatch({ type: types.SET_STORAGE, payload: data })
  },
  setPhoneStorage: (data) => {
    dispatch({ type: types.SET_PHONE_STORAGE, payload: data })
  },
  setStorageArray: (data) => {
    dispatch({ type: types.SET_STORAGE_ARRAY, payload: data })
  },
  setDeviceEvalutionObject: (data) => {
    dispatch({ type: types.SET_DEVICE_EVALUATION_OBJECT, payload: data })
  },
  setDeviceEvalutionObjectSurface: (data) => {
    dispatch({ type: types.SET_DEVICE_EVALUATION_OBJECT_SURFACE, payload: data })
  },
  setDeviceEvalutionObjectWearables: (data) => {
    dispatch({ type: types.SET_DEVICE_EVALUATION_OBJECT_WEARABLES, payload: data })
  },
  setSubCategory: (data) => {
    dispatch({ type: types.SET_SUB_CATEGORY, data })
  },
  setSelectedDevice: (data) => {
    dispatch({ type: types.SET_SELECTED_DEVICE, data })
  },
  setSelectedPhoneDevice: (data) => {
    dispatch({ type: types.SET_SELECTED_PHONE_DEVICE, data })
  },
  setFirstName: (data) => {
    dispatch({ type: types.SET_FIRST_NAME, data })
  },
  setLastName: (data) => {
    dispatch({ type: types.SET_LAST_NAME, data })
  },
  setEmailAddress: (data) => {
    dispatch({ type: types.SET_EMAIL_ADDRESS, data })
  },
  setMobileNo: (data) => {
    dispatch({ type: types.SET_MOBILE_NO, data })
  },
  setAddress: (data) => {
    dispatch({ type: types.SET_ADDRESS, data })
  },
  sendOTPMail: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.SEND_OTP_REQUEST, data })
  },
  validateOTP: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.VALIDATE_OTP_REQUEST, data })
  },
  clearOtpValidation: () => {
    dispatch({ type: types.CLEAR_OTP_RESPONSE })
  },
  setEligibilty: (data) => {
    dispatch({ type: types.SET_ELIGIBILTY, data })
  },
  isDeviceOn: (data) => {
    dispatch({ type: types.IS_DEVICE_ON, data })
  },
  setSerialNo: (data) => {
    dispatch({ type: types.SET_SERIAL_NO, data })
  },
  termsAgreed: (data) => {
    dispatch({ type: types.TERMS_AGREED, data })
  },
  setFormComplete: (data) => {
    dispatch({ type: types.SET_FORM_COMPLETE, data })
  },
  setQuestionAnswerJson: (data) => {
    dispatch({ type: types.SET_QUESTIONANSWER_JSON, data })
  },
  setSurfaceQuestionAnswerJson: (data) => {
    dispatch({ type: types.SET_SURFACEQUESTIONANSWER_JSON, data })
  },
  setLvdQuestionAnswerJson: (data) => {
    dispatch({ type: types.SET_LVDQUESTIONANSWER_JSON, data })
  },
  setQuestionAnswerJsonWearables: (data) => {
    dispatch({ type: types.SET_QUESTIONANSWER_JSON1, data })
  },
  setSubFormComplete: (data) => {
    dispatch({ type: types.SET_SUBFORM_COMPLETE, data })
  },
  CreateQuoteTestsArray: (data) => {
    dispatch({ type: types.SET_CREATEQUOTE_TESTS_ARRAY, data })
  },
  createQuote: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CREATE_QUOTE_REQUEST, data: data })
  },
  setDeviceName: (data) => {
    dispatch({ type: types.DEVICE_NAME, data })
  },
  clearData: (data) => {
    dispatch({ type: types.CLEAR_DATA, data })
  },
  sendQRCodeEmail: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.SEND_EMAIL_REQUEST, data })
  },
  getPresignUrl: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_PRESIGN_URL_FOR_UPLOAD_REQUEST, data })
  },
  setPcitoken: (data) => {
    dispatch({ type: types.SET_PCITOKEN, data: data })
  },
  setCollectPayment: (data) => {
    dispatch({ type: types.SET_COLLECT_PAYMENT, data: data })
  },
  SearchAddressLine: (data) => {
    dispatch({ type: types.SET_SEARCH_ADDRESS_LINE, data: data })
  },
  setPredectiveAddress: (data) => {
    dispatch({ type: types.SET_PREDECTIVE_ADDRESS_SUCCESS, payload: data })
  },
  getQASDoGetAddress: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_DO_GET_ADDRESS, data })
  },
  updateImei: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.UPDATE_IMEI_REQUEST, data })
  },
  getPresignUrl2: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_PRESIGN_URL_2, data })
  },
  getQrCodePresignUrl: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_QR_CODE_PRESIGN_URL, data })
  },
  setManufacturer: (data) => {
    dispatch({ type: types.SET_MANUFACTURER, payload: data })
  },
  setPhoneManufacturer: (data) => {
    dispatch({ type: types.SET_PHONE_MANUFACTURER, payload: data })
  },
  setFAQ: (data) => {
    dispatch({ type: types.SET_FAQ, data })
  },
  SetPredictiveAddressValue: (data) => {
    dispatch({ type: types.SET_SELECTED_ADDRESS_VALUE, data })
  },
  setImageName: (data) => {
    dispatch({ type: types.SET_IMAGE_NAME, data })
  },
  setQuoteDevice: (data) => {
    dispatch({ type: types.SET_QUOTE_DEVICE, data })
  },
  setQuotePhone: (data) => {
    dispatch({ type: types.SET_QUOTE_PHONE, data })
  },
  goBackClicked: (data) => {
    dispatch({ type: types.GO_BACK_CLICKED, data })
  },
  setSeries: (data) => {
    dispatch({ type: types.SET_SERIES, data })
  },
  setPhoneSeries: (data) => {
    dispatch({ type: types.SET_PHONE_SERIES, data })
  },
  unsetPhoneSeries: (data) => {
    dispatch({ type: types.UNSET_PHONE_SERIES, data })
  },
  setDeviceType: (data) => {
    dispatch({ type: types.DEVICE_TYPE, data })
  },
  createInquiry: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CREATE_INQUIRY_REQUEST, data })
  },
  setSubscriptionNumber: (data, searchinputName) => {
    dispatch({ type: types.SET_SUBSCRIPTION_NUMBER, payload: { data, searchinputName } })
  },
  setHowCanIHelpValue: (data) => {
    dispatch({ type: types.SET_HOW_CAN_I_HELP, data })
  },
  setEmailAddress: (data) => {
    dispatch({ type: types.SET_EMAIL_ADDRESS, data })
  },
  setName: (data) => {
    dispatch({ type: types.SET_NAME, data })
  },
  setContactNumber: (data) => {
    dispatch({ type: types.SET_CONTACT_NUMBER, data })
  },
  setNote: (data) => {
    dispatch({ type: types.SET_NOTE, data })
  },
  setChatWindow: (data) => {
    dispatch({ type: types.SET_CHAT_WINDOW, data })
  },
  setChatIsLoggedIn: (data) => {
    dispatch({ type: types.SET_CHAT_IS_LOGGED_IN, data })
  },
  setsearchInput: (data) => {
    dispatch({ type: types.SET_SEARCH_INPUT, data })
  },
  setSSPemailId: (data) => {
    dispatch({ type: types.SET_SSP_EMAIL_ID, data })
  },
  setSSPTradeId: (data) => {
    dispatch({ type: types.SET_SSP_TRADE_ID, data })
  },
  setSspPage: (data) => {
    dispatch({ type: types.SET_SSP_PAGE, data })
  },
  setSSPOtp: (data) => {
    dispatch({ type: types.SET_SSP_OTP, data })
  },
  setTradeDetails: (data, token) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.SET_TRADE_DETAILS, data, token })
  },
  setTradeStatus: (data, token) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.SET_TRADE_STATUS, data, token })
  },
  sendOtp: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.SEND_OTP_REQUEST, data })
  },
  verifydOtp: (data, token) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.VERIFY_OTP_REQUEST, data, token })
  },
  setGenerateLabel: (data, token) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.SET_GENERATE_LABEL_REQUEST, data, token })
  },
  setShippingLabel: (data, token) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.SET_SHIPPING_LABEL_REQUEST, data, token })
  },
  setTradeImage: (data, token) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_IMAGE_REQUEST, data, token })
  },
  createHyperWalletUser: (data, token) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CREATE_HYPER_WALLET_USER, data, token })
  },
  savePayOutDetails: (data, token) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CREATE_TRANSFER_METHOD, data, token })
  },
  clearPayOutDetails: () => {
    dispatch({ type: types.CLEAR_PAYOUT_DETAILS })
  },

  sendSSOTPMail: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.SEND_SS_OTP_REQUEST, data })
  },

  verifySSOtp: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.VERIFY_SS_OTP_REQUEST, data })
  },

  getAllSSTrades: (data, token) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.Get_ALL_SS_TRADES, data, token })
  },

  getSSTradesDetails: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_SS_TRADE_DETAILS, data })
  },

  cancelSSTrade: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CANCEL_SS_TRADE_DETAILS, data })
  },

  returnSSLabelTrade: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.RETURN_SS_LABEL, data })
  },
  setDataSet: (data, item) => {
    dispatch({ type: types.SET_DATA_SET, data: { data, item } })
  },

  setPaymentMethod: (data) => {
    dispatch({ type: types.SET_PAYMENT_METHOD, data })
  },

})
export { useActions }
