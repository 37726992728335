import React from "react";
import  RadioIcon  from "../../Icons/radio-empty-icon";
import RadioCheckedIcon from "../../Icons/radio-checked-icon";

const Checkbox = ({ label, isChecked, disabled, onChange, className, btnClass }) => {
  return (
    <div
      className={`cursor-pointer flex ${className}`}
      onClick={() => (disabled ? {} : onChange(!isChecked))}
    >
        <div className="flex justify-center items-center">
        {isChecked ? <RadioCheckedIcon className={`${btnClass}`} /> : <RadioIcon className={`${btnClass}`} />}
        </div>
      <span className={`flex-shrink ml-2 ${disabled ? "" : ""}`}>{label}</span>
    </div>
  );
};

Checkbox.defaultProps = {
  isChecked: false,
};

export default Checkbox;
